import { observer } from 'mobx-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { storesContext } from '../../../stores/storesContext';
import { errorToast, successToast } from '../../helpers/toasts/ToastUtils';
import Files from './Files';
import UnmatchedPolygons from './UnmatchedPolygons';
import UploadStatus from './UploadStatus';
import useTransactionPolling from './useTransactionPolling';
import {
  createBlockUploadTransaction,
  getUnmatchedBlockClusters,
  updateBlockUploadTransaction,
  uploadToS3
} from './util';

const FileUpload = () => {
  const userStore = useContext(storesContext);
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [unmatchedClusters, setUnmatchedClusters] = useState([]);
  const [loadingUnmatched, setLoadingUnmatched] = useState(false);

  const { status, resetStatus, startPolling } = useTransactionPolling();

  const bearerToken = userStore.bearerToken;
  const selectedDatabase = userStore.selectedDatabase;
  const tempAwsCredentials = userStore.tempAwsCredentials;

  // Determines whether the upload/polygon processing process is running.
  const isProcessing =
    uploading || status === 'PENDING' || status === 'PROCESSING';
  // Determines whether the upload/polygon processing process has finished.
  const isFinished =
    !uploading && (status === 'COMPLETE' || status === 'ERROR');
  // No files chosen.
  const noFiles = files.length === 0;

  const updateInputFiles = (files) => {
    const dt = new DataTransfer();
    for (let file of files) {
      const newFile = new File([file], file.name, { type: file.type });
      dt.items.add(newFile);
    }
    fileInputRef.current.files = dt.files;
  };

  const validateFiles = async (newFiles) => {
    // Check if any files are not JSON files.
    const hasNonJsonFile = newFiles.some(
      (nf) => nf.type !== 'application/json'
    );
    if (hasNonJsonFile) {
      errorToast(
        'Cannot upload files. Only JSON files are allowed.',
        'upload-error'
      );
      return false;
    }
    // Check if file has already been added.
    const haveFile = newFiles.some((nf) =>
      files.find((f) => f.name === nf.name)
    );
    if (haveFile) {
      return false;
    }
    // Check the JSON content has the format we are expecting.
    const currentFiles = fileInputRef.current.files;
    for (let file of currentFiles) {
      const fileText = await file.text();
      const jsonData = JSON.parse(fileText);
      if (
        !('block-polygons' in jsonData) ||
        !('features' in jsonData['block-polygons'])
      ) {
        errorToast(
          `Cannot upload files. No polygons found in file ${file.name}.`,
          'upload-error'
        );
        return false;
      }
    }
    return true;
  };

  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files);
    // Check if the file has already been chosen.
    const isValid = await validateFiles(newFiles);
    if (!isValid) {
      // Revert input files back to current react 'files' state.
      updateInputFiles(files);
      return;
    }
    // Add new files to the existing files array.
    setFiles((prev) => {
      const allFiles = [...prev, ...newFiles];
      updateInputFiles(allFiles);
      // Only reset status if new files were added.
      if (newFiles.length > 0) resetStatus();
      return allFiles;
    });
  };

  const handleFileRemove = (filename) => {
    resetStatus();
    // Remove files from file input element.
    const dt = new DataTransfer();
    const currentFiles = fileInputRef.current.files;
    for (let file of currentFiles) {
      dt.items.add(file);
    }
    for (let i = 0; i < dt.items.length; i++) {
      if (dt.items[i].getAsFile().name === filename) {
        dt.items.remove(i);
      }
    }
    fileInputRef.current.files = dt.files;
    // Remove files from state if files were removed from input successfully.
    if (dt.items.length === files.length - 1) {
      setFiles((prev) => prev.filter((f) => f.name !== filename));
    }
  };

  const handleFileUpload = async () => {
    // Check if there are no chosen files.
    if (files.length === 0) {
      errorToast('Cannot upload files. No files chosen.', 'upload-error');
      return;
    }
    // Process current files in input.
    setUploading(true);
    const currentFiles = fileInputRef.current.files;
    await processFiles(currentFiles);
    setUploading(false);
  };

  const processFiles = async (files) => {
    // Pull polygon features from the files and create a GeoJSON feature collection.
    const featureCollection = {
      type: 'FeatureCollection',
      features: []
    };
    for (let file of files) {
      const fileText = await file.text();
      const jsonData = JSON.parse(fileText);
      // The JSON file has been validated so the 'block-polygons' property will exist.
      const features = jsonData['block-polygons']['features'];
      const newFeatures = featureCollection.features.concat(features);
      featureCollection.features = newFeatures;
    }
    let transactionId;
    try {
      // Create block upload transaction in Properties API.
      transactionId = await createBlockUploadTransaction(
        bearerToken,
        selectedDatabase
      );
      // Upload the feature collection to S3.
      const success = await uploadToS3(
        JSON.stringify(featureCollection),
        'polygons.geojson',
        transactionId,
        selectedDatabase,
        tempAwsCredentials,
        userStore.refreshCallback
      );
      // If the S3 upload fails then set the transaction status to ERROR.
      if (!success) {
        await updateBlockUploadTransaction(
          transactionId,
          bearerToken,
          selectedDatabase
        );
        throw new Error('S3 upload failed');
      }
      // Start polling the transaction status.
      const finishedStatus = await startPolling(transactionId);
      if (finishedStatus === 'COMPLETE') {
        setLoadingUnmatched(true);
        const unmatched = await getUnmatchedBlockClusters(
          bearerToken,
          selectedDatabase
        );
        setUnmatchedClusters(unmatched);
        setLoadingUnmatched(false);
        successToast('File uploaded successfully!', 'upload-success');
      } else {
        errorToast('Failed to upload files.', 'upload-error');
      }
    } catch (error) {
      setLoadingUnmatched(false);
      console.log(error.message, error.stack);
      console.error('Error creating block upload transaction:', error);
      errorToast('Failed to upload files.', 'upload-error');
      if (transactionId)
        await updateBlockUploadTransaction(
          transactionId,
          bearerToken,
          selectedDatabase,
          4, // ERROR,
          'Could not create block upload transaction.',
          JSON.stringify({ message: error.message, stack: error.stack })
        );
      return;
    }
  };

  useEffect(() => {
    const fetchUnmatched = async () => {
      setLoadingUnmatched(true);
      const unmatched = await getUnmatchedBlockClusters(
        bearerToken,
        selectedDatabase
      );
      setLoadingUnmatched(false);
      setUnmatchedClusters(unmatched);
    };
    if (unmatchedClusters.length > 0 || !selectedDatabase) return;
    try {
      fetchUnmatched();
    } catch (error) {
      console.error('Failed to get unmatched polygons:', error);
      errorToast(
        'Failed to fetch unmatched block clusters.',
        'unmatched-error'
      );
    }
  }, [bearerToken, selectedDatabase, unmatchedClusters.length]);

  return (
    <>
      <Row>
        <Col>
          <div className='input-group' style={{ zIndex: 1 }}>
            <div className='custom-file'>
              <input
                ref={fileInputRef}
                type='file'
                multiple
                className='custom-file-input'
                id='input-group-file'
                aria-describedby='input-group-file-addon'
                disabled={isProcessing}
                onChange={handleFileChange}
              />
              <label className='custom-file-label' htmlFor='input-group-file'>
                Choose file
              </label>
            </div>
            <div className='input-group-append'>
              <Button
                id='input-group-file-addon'
                variant='primary'
                onClick={handleFileUpload}
                disabled={isProcessing || isFinished || noFiles}
                className={isProcessing ? 'px-4' : ''}
              >
                {isProcessing ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  'Upload'
                )}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className='mt-2 px-3'>
        <Files
          files={files}
          isProcessing={isProcessing}
          handleFileRemove={handleFileRemove}
        />
      </Row>
      {status ? (
        <Row>
          <Col>
            <UploadStatus status={status} isProcessing={isProcessing} />
          </Col>
        </Row>
      ) : null}
      <Row className='mt-4'>
        <Col>
          {loadingUnmatched ? (
            <div className='text-center'>
              <Spinner className='mt-4' animation='border' variant='primary' />
            </div>
          ) : unmatchedClusters.length > 0 ? (
            <UnmatchedPolygons unmatchedPolygons={unmatchedClusters} />
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default observer(FileUpload);

