import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';

// Define a default UI for filtering
export default function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  style
}) {
  const count = preGlobalFilteredRows ? preGlobalFilteredRows.length : 0;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Form.Control
      type='text'
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Search ${count} records...`}
      style={style}
    />
  );
}
