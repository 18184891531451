import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../helpers/PageTitle';
import './irrigation.scss';
import IrrigationGraphs from './IrrigationGraphs';
import ProgrammingGuide from './ProgrammingGuide';

export function IrrigationPage(props) {
  const { handlePrint, printing } = props.print;

  return (
    <div id='page-container'>
      <Row>
        <Col></Col>
        <Col sm={4} md={6}>
          <PageTitle title={'Monitoring'} />
        </Col>
        <Col className='text-center'>
          <div className='hide-on-print'>
            <Button
              className='float-right center'
              variant='info'
              onClick={() => handlePrint()}
            >
              Print to PDF
            </Button>
          </div>
        </Col>
      </Row>
      <IrrigationGraphs printing={printing} />
    </div>
  );
}

export function ProgrammingGuidePage(props) {
  return (
    <div id='page-container'>
      <Row>
        <Col>
          <PageTitle title={'Irrigation: Property Programming Guide'} />
        </Col>
      </Row>
      <Button variant='outline-primary' as={Link} to='/irrigation'>
        Back
      </Button>
      <ProgrammingGuide />
    </div>
  );
}

