import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import GlobalSeasonSelect from '../helpers/GlobalSeasonSelect';
import PageTitle from '../helpers/PageTitle';
import UserDetails from './UserDetails';

function Home() {
  const userStore = useContext(storesContext);
  let history = useHistory();

  return (
    <div id='page-container'>
      <Row>
        <Col>
          <PageTitle title={'Account'} />
        </Col>
      </Row>

      <Row className='my-3'>
        <Col md={7}>
          <UserDetails />
          <Row className='my-3'>
            <Col className='text-center'>
              <Button
                className='mx-4'
                variant='primary'
                as={Link}
                to='/change-password'
              >
                Change Password
              </Button>
              <Button
                className='mx-4'
                variant='secondary'
                onClick={() => {
                  userStore.logout();
                  history.push('');
                }}
              >
                Logout
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={5}>
          <Card>
            <Card.Header className='py-3'>Season Settings</Card.Header>
            <Card.Body>
              <p>
                This is an advanced feature for changing the selected season,
                please consult Fruition before using this.
              </p>
              <Row>
                <Col>Selected Season:</Col>
                <Col>
                  <GlobalSeasonSelect showConfirm />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default observer(Home);

