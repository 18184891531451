import { GlobalPropertiesSelect } from '@hortplus/properties-react';
import isMobile from 'ismobilejs';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import SeasonalStrategyChart from '../charts/SeasonalStrategyChart';
import WeeklySoilMoistureChart from '../charts/WeeklySoilMoistureChart';
import IrrigationLegend from './IrrigationLegend';
import { withIrrigationMonitoringSites } from './IrrigationProviders';
import SiteButton from './SiteButton';
import { CalculateSiteSoilMoisture } from './helpers';
import './irrigation_print.scss';
import SummaryMap from './maps/SummaryMap';
import LastWeekRain from './tables/LastWeekRain';
import LastWeekTable from './tables/LastWeekTable';
import RecommendationTable from './tables/RecommendationTable';
import SeasonToDateTable from './tables/SeasonToDateTable';

/**
 * This is the main irrigation component, it displays all of the graphs and tables
 * for the supplied summary, readings, and strategy data.
 *
 * This is intended to be used as a child of the IrrigationMonitoringSites component.
 */
function IrrigationGraphs(props) {
  const userStore = useContext(storesContext);
  const [selectedSite, setSelectedSite] = useState(null);
  const isPrinting = props.printing;

  useEffect(() => {
    const siteId = localStorage.getItem('irrigation_monitoring_site_id');
    if (siteId !== null) {
      const site =
        userStore.propertiesStore.selectedProperty.irrigation_monitoring_sites.find(
          (s) => s.id === parseInt(siteId)
        );
      if (site) setSelectedSite(site);
    }
  }, [userStore.propertiesStore.selectedPropertyId]);

  const selectSite = (site) => {
    localStorage.setItem('irrigation_monitoring_site_id', site.id);
    setSelectedSite(site);
  };

  /**
   * Returns the summary for a site.
   *
   * The summary has summary has the data we need to calculate the current
   * status of the site.
   *
   * @param {object} site
   * @returns {(object|null)}
   */
  const siteSummary = (site) =>
    props.siteSummaries.find(
      (siteSummary) => siteSummary.site_id === Number(site.site_number)
    );

  /**
   * Returns the soil moisture percentage value for a site.
   *
   * The calculation finds the percentage of summary.latest_reading_date_rz1
   * within the range of the refill point to the full point.
   *
   * @param {object} site
   * @returns {(number|null)}
   */
  const siteSoilMoisture = (site) => {
    const summary = siteSummary(site);
    return CalculateSiteSoilMoisture(summary);
  };

  /**
   * Returns all probe readings for the selected site.
   *
   * @returns {(Array|null)}
   */
  const selectedSiteProbeReadings = useMemo(() => {
    if (selectedSite)
      return props.siteReadings[selectedSite.id]
        ? props.siteReadings[selectedSite.id].filter(
            (reading) => reading.type === 'Probe'
          )
        : null;
    else return null;
  }, [props.siteReadings, selectedSite]);

  /**
   * Returns the latest probe reading for the selected site.
   *
   * @returns {(object|null)}
   */
  const latestWeekReading = useMemo(() => {
    return selectedSiteProbeReadings && selectedSiteProbeReadings.length > 0
      ? selectedSiteProbeReadings[0]
      : null;
  }, [selectedSiteProbeReadings]);

  return (
    <Fragment>
      {isMobile(window.navigator).any ? (
        <Row className='justify-content-center'>
          <Col sm={10} md={8} lg={6}>
            <GlobalPropertiesSelect
              selectedDatabase={userStore.selectedDatabase}
              bearerToken={userStore.bearerToken}
              refreshBearerToken={userStore.refreshCallback}
              propertiesStore={userStore.propertiesStore}
            />
          </Col>
        </Row>
      ) : null}

      <IrrigationLegend className='mt-2 text-center' />

      {/* Irrigation Monitoring Sites Buttons */}
      <div className='d-flex justify-content-center flex-wrap mt-1'>
        {userStore.propertiesStore.selectedProperty.irrigation_monitoring_sites.map(
          (site) => {
            const soilMoisture = siteSoilMoisture(site);
            const summary = siteSummary(site);
            const yValue = summary?.latest_reading?.rz1;
            const fullPoint = summary?.full_point;
            const isBlueAlert =
              summary?.alert_level === 3 && yValue > fullPoint;
            // color for the buttons based on where the site is in the strategy.
            return (
              <SiteButton
                key={site.id}
                site={site}
                summary={summary}
                selectedSite={selectedSite}
                selectSite={selectSite}
                soilMoisture={soilMoisture}
                isBlueAlert={isBlueAlert}
              />
            );
          }
        )}
      </div>

      {selectedSite &&
      Object.keys(props.siteReadings).some(
        (s) => Number(s) === Number(selectedSite.id)
      ) ? (
        <Fragment>
          <Row className='my-2'>
            <Col
              xl={6}
              lg={6}
              md={12}
              xs={isPrinting ? 6 : 12}
              className='my-2'
            >
              <Card>
                <Card.Header>
                  <b>Recommendation</b>
                </Card.Header>
                <Card.Body>
                  {latestWeekReading ? latestWeekReading.comment : null}
                </Card.Body>
              </Card>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              xs={isPrinting ? 6 : 12}
              className='my-2'
            >
              <Card>
                <Card.Header>
                  <b>Summary Since Last Reading</b>
                </Card.Header>
                <Card.Body>
                  Last Reading:{' '}
                  <b>
                    {latestWeekReading
                      ? moment(latestWeekReading['date'], 'DD-MM-YYYY').format(
                          'dddd, MMMM D, YYYY'
                        )
                      : 'N/A'}
                  </b>
                  <br />
                  Crop use 7 days (Forecast):{' '}
                  <b>
                    {latestWeekReading && latestWeekReading.weekly_edwu !== null
                      ? `${latestWeekReading.weekly_edwu}mm`
                      : 'N/A'}
                  </b>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col
              xl={6}
              lg={6}
              md={12}
              xs={isPrinting ? 6 : 12}
              className='my-2'
            >
              <Card>
                <Card.Header>
                  <b>Current State</b>
                </Card.Header>
                <Card.Body>
                  {selectedSiteProbeReadings &&
                  selectedSiteProbeReadings.length > 0 ? (
                    <Row>
                      <Col>
                        <WeeklySoilMoistureChart
                          readingData={props.siteReadings[selectedSite.id]}
                        />
                        <small className='d-block text-center'>
                          Root Zone: 0 - {latestWeekReading.rz1_bottom} cm
                        </small>
                      </Col>
                    </Row>
                  ) : (
                    'N/A'
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              xs={isPrinting ? 6 : 12}
              className='my-2'
            >
              <Card>
                <Card.Header>
                  <b>Schedule</b>
                </Card.Header>
                <Card.Body>
                  <RecommendationTable
                    readingData={props.siteReadings[selectedSite.id]}
                    site={selectedSite}
                  />
                  <Row>
                    <Col className='text-center'>
                      <Button
                        variant='outline-info'
                        as={Link}
                        to='/programming-guide'
                      >
                        Property Programming Guide
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className='my-2'>
              <Card>
                <Card.Header>
                  <b>Last Week</b>
                </Card.Header>
                <Card.Body>
                  {selectedSiteProbeReadings &&
                  selectedSiteProbeReadings.length > 0 ? (
                    <LastWeekTable
                      readingData={props.siteReadings[selectedSite.id]}
                      site={selectedSite}
                    />
                  ) : (
                    'N/A'
                  )}
                  <LastWeekRain
                    readingData={props.siteReadings[selectedSite.id]}
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col className='my-2'>
              <Card>
                <Card.Header>
                  <b>Season To Date</b>
                </Card.Header>
                <Card.Body>
                  {selectedSiteProbeReadings &&
                  selectedSiteProbeReadings.length > 0 ? (
                    <SeasonToDateTable
                      readingData={props.siteReadings[selectedSite.id]}
                      className='mb-0'
                    />
                  ) : (
                    'N/A'
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className='my-2 page-break'>
            <Col>
              <Card>
                <Card.Header>
                  <b>Seasonal Summary &amp; Strategy</b>
                </Card.Header>
                <Card.Body>
                  {selectedSiteProbeReadings &&
                  selectedSiteProbeReadings.length > 0 ? (
                    <SeasonalStrategyChart
                      readingData={props.siteReadings[selectedSite.id]}
                      strategyData={props.siteStrategies[selectedSite.id]}
                      siteSummary={siteSummary(selectedSite)}
                    />
                  ) : (
                    'N/A'
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className='my-2'>
            <Col className='my-2'>
              <Card>
                <Card.Header>
                  <b>Map</b>
                </Card.Header>
                <Card.Body>
                  <IrrigationLegend className='mb-4 text-center' />

                  <SummaryMap
                    propertySummaries={[
                      {
                        loading: false,
                        property: userStore.propertiesStore.selectedProperty,
                        results: {
                          sites: props.siteSummaries
                        }
                      }
                    ]}
                    selectSite={selectSite}
                    selectedSite={selectedSite}
                    height={500}
                    width='100%'
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

IrrigationGraphs.propTypes = {
  siteSummaries: PropTypes.array.isRequired,
  siteReadings: PropTypes.object.isRequired,
  siteStrategies: PropTypes.object.isRequired,
  printing: PropTypes.bool
};

// Doesn't need to be wrapped in observer because withIrrigationMonitoringSites is
export default withIrrigationMonitoringSites(IrrigationGraphs);

