import {
  AdminOverview,
  AdminSeasonOverviewReport,
  HotTrap,
  MissingMonitoring,
  Traplog,
  TraplogDataProvider,
  TrappingMonitoringReport,
  TrappingRuns
} from '@hortplus/traplog-react';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import useQuery from '../helpers/hooks/useQuery';
import PageTitle from '../helpers/PageTitle';
import './traplog-styles.scss';

export const TraplogPage = observer(function () {
  const userStore = useContext(storesContext);

  return (
    <div id='page-container'>
      <Row className='mb-4'>
        <Col>
          <PageTitle title={'Trapping Overview'} />
        </Col>
      </Row>
      <TraplogDataProvider
        selectedDatabase={userStore.selectedDatabase}
        bearerToken={userStore.bearerToken}
        refreshBearerToken={userStore.refreshCallback}
        propertiesStore={userStore.propertiesStore}
      >
        <Traplog
          selectedDatabase={userStore.selectedDatabase}
          bearerToken={userStore.bearerToken}
          refreshBearerToken={userStore.refresh}
          propertiesStore={userStore.propertiesStore}
          customOptions={{
            insects: ['CM', 'LBAM', 'OFM']
          }}
        />
      </TraplogDataProvider>
    </div>
  );
});

export const AdminOverviewPage = observer(function () {
  const userStore = useContext(storesContext);

  return (
    <div id='page-container'>
      <Row className='mb-4'>
        <Col>
          <PageTitle title={'Trapping Data Entry'} />
        </Col>
      </Row>
      <TraplogDataProvider
        selectedDatabase={userStore.selectedDatabase}
        bearerToken={userStore.bearerToken}
        refreshBearerToken={userStore.refreshCallback}
        propertiesStore={userStore.propertiesStore}
      >
        <AdminOverview
          selectedDatabase={userStore.selectedDatabase}
          bearerToken={userStore.bearerToken}
          refreshBearerToken={userStore.refresh}
          propertiesStore={userStore.propertiesStore}
        />
      </TraplogDataProvider>
    </div>
  );
});

export const HotTrapMapPage = observer(function () {
  const userStore = useContext(storesContext);

  return (
    <div id='page-container'>
      <Row className='mb-3'>
        <Col>
          <PageTitle title={'Hot Trap Map'} />
        </Col>
      </Row>
      <TraplogDataProvider
        selectedDatabase={userStore.selectedDatabase}
        bearerToken={userStore.bearerToken}
        refreshBearerToken={userStore.refreshCallback}
        propertiesStore={userStore.propertiesStore}
      >
        <HotTrap
          selectedDatabase={userStore.selectedDatabase}
          bearerToken={userStore.bearerToken}
          refreshBearerToken={userStore.refresh}
          propertiesStore={userStore.propertiesStore}
        />
      </TraplogDataProvider>
    </div>
  );
});

export const TrappingRunsPage = observer(function () {
  const history = useHistory();
  const { id } = useParams();
  const userStore = useContext(storesContext);

  const changeSelectedTrappingRunId = (newId) => {
    if (Number.isInteger(newId))
      history.push('/pest-disease/trapping-runs/' + newId);
    else history.push('/pest-disease/trapping-runs');
  };

  return (
    <div id='page-container'>
      <Row>
        <Col>
          <PageTitle title={'Trapping Runs'} />
        </Col>
      </Row>
      <TrappingRuns
        bearerToken={userStore.bearerToken}
        refreshBearerToken={userStore.refreshCallback}
        selectedDatabase={userStore.selectedDatabase}
        selectedTrappingRunId={parseInt(id)}
        changeSelectedTrappingRunId={changeSelectedTrappingRunId}
        history={history}
        permissionsCard={true}
        authService={'fruition'}
      />
    </div>
  );
});

export const TrappingMonitoringReportPage = observer(function () {
  const userStore = useContext(storesContext);

  return (
    <div id='page-container'>
      <Row>
        <Col>
          <PageTitle title={'Weekly Trapping Report'} />
        </Col>
      </Row>
      <TrappingMonitoringReport
        bearerToken={userStore.bearerToken}
        refreshBearerToken={userStore.refreshCallback}
        selectedDatabase={userStore.selectedDatabase}
        propertiesStore={userStore.propertiesStore}
      />
    </div>
  );
});

export const MissingMonitoringPage = observer(function () {
  const userStore = useContext(storesContext);

  return (
    <div id='page-container'>
      <Row className='mb-4'>
        <Col>
          <PageTitle title={'Missing Trapping Report'} />
        </Col>
      </Row>
      <MissingMonitoring
        bearerToken={userStore.bearerToken}
        refreshBearerToken={userStore.refreshCallback}
        selectedDatabase={userStore.selectedDatabase}
      />
    </div>
  );
});

export const SeasonOverviewReportPage = observer(function () {
  const userStore = useContext(storesContext);
  const query = useQuery();
  const propertyId = query.get('property_id');

  return (
    <div id='page-container'>
      <Row className='mb-4'>
        <Col>
          <PageTitle title={'Season Overview'} />
        </Col>
      </Row>
      <AdminSeasonOverviewReport
        bearerToken={userStore.bearerToken}
        refreshBearerToken={userStore.refreshCallback}
        selectedDatabase={userStore.selectedDatabase}
        propertiesStore={userStore.propertiesStore}
        propertyId={propertyId !== null ? parseInt(propertyId) : null}
      />
    </div>
  );
});

