/**
 * Wrapper function around Google Analytics 4 gtag() function.
 *
 * This is used for setting the gtag on a per tenant basis, for individual
 * tracking. This should be called twice - once with the individual tenant's
 * gtag and once with the aggregate tag.
 *
 * @param tag GA4 gtag string.
 */
export function gtagWrapper(tag) {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENVIRONMENT === 'development'
  )
    return; // Don't send analytics in dev environment.
  window.gtag('config', tag);
}
