export const scanTypes = [
  {
    value: 'CR',
    label: 'Crop'
  },
  {
    value: 'CN',
    label: 'Canopy'
  }
];

export const stages = [
  {
    value: 'DB',
    label: 'Dormant buds'
  },
  {
    value: 'FB',
    label: 'Flower buds'
  },
  {
    value: 'FC',
    label: 'Flower clusters'
  },
  {
    value: 'FL',
    label: 'Fruitlets'
  },
  {
    value: 'FT',
    label: 'Fruit'
  },
  {
    value: 'IF',
    label: 'Inflorescence'
  },
  {
    value: 'BE',
    label: 'Berries'
  },
  {
    value: 'BL',
    label: 'Bloom'
  },
  {
    value: 'SH',
    label: 'Shoot'
  }
];

export const reportTypes = [
  {
    value: 'TFX',
    label: 'Total Count'
  },
  {
    value: 'UFX',
    label: 'Upper Count'
  },
  {
    value: 'LFX',
    label: 'Lower Count'
  },
  {
    value: 'TFE',
    label: 'Total Count - Crop Estimate'
  },
  {
    value: 'UFE',
    label: 'Upper Count - Crop Estimate'
  },
  {
    value: 'LFE',
    label: 'Lower Count - Crop Estimate'
  },
  {
    value: 'CHX',
    label: 'Canopy Height'
  },
  {
    value: 'CAX',
    label: 'Canopy Area'
  },
  {
    value: 'CDX',
    label: 'Canopy Density'
  },
  {
    value: 'PLF',
    label: 'Prescription - Liquid Fertiliser'
  },
  {
    value: 'PDF',
    label: 'Prescription - Dry Fertiliser'
  },
  {
    value: 'PVM',
    label: 'Prescription - Vigour Management'
  },
  {
    value: 'PCV',
    label: 'Prescription - Canopy Volume'
  },
  {
    value: 'PCT',
    label: 'Prescription - Chemical Thinning'
  },
  {
    value: 'LAX',
    label: 'Leaf Area'
  },
  {
    value: 'FSX',
    label: 'Fruit Size'
  }
];

export const pdfTypes = [
  {
    value: 'AGR',
    label: 'Agronomic'
  },
  {
    value: 'GEO',
    label: 'Geo pdf'
  }
];

