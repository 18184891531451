import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import URI from 'urijs';
import { storesContext } from '../../stores/storesContext';
import LoadingOverlay from '../LoadingOverlay';
import { CalculateSiteSummaryLevel } from './helpers';

// This is a hack to stop an error where there is no default export.
export default {};

const getSelectedSeason = async (startYear) => {
  return fetch(`${process.env.REACT_APP_TERRAPROBE_API_URL}api/season`)
    .then((res) => {
      if (res.ok) return res.json();
      else throw new Error(res.statusText);
    })
    .then((data) => {
      return data.find((season) => {
        const dt = moment(season.season_date, 'DD-MM-YYYY');
        return dt.year() == startYear;
      });
    })
    .catch((err) => {
      if (process.env.NODE_ENV === 'development') console.error(err);
    });
};

export const withIrrigationSummaries = (Component) =>
  observer((props) => {
    const { properties, ...passThroughProps } = props;

    const userStore = useContext(storesContext);

    const [propertySummaries, setPropertySummaries] = useState([]);

    // Used to compare if any meaningful changes were made to properties
    // We used to get these reloaded twice on the dashboard when initially
    // logging in. See issue #14 on fruition-portal for more info.
    const [prevProperties, setPrevProperties] = useState(null);

    const loadAllData = async () => {
      // Attempt to find the selected season
      const terraprobeSeason = await getSelectedSeason(
        userStore.selectedDatabaseStartYear
      );

      if (!terraprobeSeason) {
        setPropertySummaries([]);
        return;
      }

      // Check that a meaningful change was made to the proeprties
      const different = properties.find((property) => {
        const prevProperty = Array.isArray(prevProperties)
          ? prevProperties.find((p) => p.id === property.id)
          : null;

        if (!prevProperty) return true;

        // We want to compare the entire property, except the permissions
        // which are what caused the unecessary reload.
        let a = { ...prevProperty };
        let b = { ...property };
        delete a.user_permissions;
        delete a.user_properties;
        delete b.user_permissions;
        delete b.user_properties;

        // Simply compare using the JSON string
        if (JSON.stringify(a) !== JSON.stringify(b)) return true;

        return false;
      });

      setPrevProperties(properties);
      if (!different) return;

      // Clear property summaries to avoid any duplicates
      setPropertySummaries([]);

      // Load a summary for each property
      properties.forEach((property) => {
        if (property.irrigation_monitoring_sites.length < 1) {
          setPropertySummaries((prevPropertySummaries) => [
            ...prevPropertySummaries,
            {
              property: property,
              loading: false,
              results: null,
              maxLevel: 1
            }
          ]);
        } else {
          setPropertySummaries((prevPropertySummaries) => [
            ...prevPropertySummaries,
            {
              property: property,
              loading: true,
              results: null,
              maxLevel: 1
            }
          ]);

          const uri = new URI(
            `${process.env.REACT_APP_TERRAPROBE_API_URL}graphs/api/v3/fruition_summary/${terraprobeSeason.id}/sites_summary/`
          );
          uri.setQuery(
            'sites[]',
            property.irrigation_monitoring_sites.map(
              (irrigationMonitoringSite) => irrigationMonitoringSite.site_number
            )
          );

          fetch(uri.toString(), {
            method: 'GET'
          })
            .then((res) => {
              if (res.ok) return res.json();
              else throw Error(res.statusText);
            })
            .then((data) => {
              setPropertySummaries((prevPropertySummaries) => {
                const newPropertySummaries = [...prevPropertySummaries];
                const i = newPropertySummaries.findIndex(
                  (propertySummary) =>
                    propertySummary.property.id === property.id
                );
                if (i > -1) {
                  newPropertySummaries[i].loading = false;
                  newPropertySummaries[i].results = data;

                  let maxLevel = 1;
                  data.sites.forEach((result) => {
                    const level = CalculateSiteSummaryLevel(result);
                    if (maxLevel === null) maxLevel = level;
                    else if (level > maxLevel) maxLevel = level;
                  });
                  newPropertySummaries[i].maxLevel = maxLevel;
                }
                return newPropertySummaries;
              });
            })
            .catch((err) => {
              if (process.env.NODE_ENV === 'development') console.error(err);
              setPropertySummaries((prevPropertySummaries) => {
                const newPropertySummaries = [...prevPropertySummaries];
                const i = newPropertySummaries.findIndex(
                  (propertySummary) =>
                    propertySummary.property.id === property.id
                );
                if (i > -1) {
                  newPropertySummaries[i].loading = false;
                  newPropertySummaries[i].results = null;
                  newPropertySummaries[i].maxLevel = 1;
                }
                return newPropertySummaries;
              });
            });
        }
      });
    };

    useEffect(() => {
      loadAllData();
    }, [properties, userStore.bearerToken, userStore.selectedDatabase]);

    const orderedPropertySummaries = useMemo(() => {
      // Split into levels
      const levels = {};
      propertySummaries.forEach((propertySummary) => {
        if (
          !Object.keys(levels).some(
            (level) => parseInt(level) === propertySummary.maxLevel
          )
        ) {
          levels[propertySummary.maxLevel] = [propertySummary];
        } else {
          levels[propertySummary.maxLevel].push(propertySummary);
        }
      });

      // Sort levels
      const levelKeys = Object.keys(levels).map((level) => parseInt(level));
      levelKeys.sort((a, b) => {
        let _a = a;
        let _b = b;
        // If alertlevel 4, then set to lowest alert level.
        // This level is 0 as the actual lowest is 1.
        // The purpose of this is to display 'blue' last as it's the lowest alert level.
        if (a === 4) _a = 0;
        if (b === 4) _b = 0;
        return _b - _a;
      });

      // Return each level sorted by alphabetical order
      return levelKeys.flatMap((key) =>
        levels[key].sort((a, b) => {
          const stringA = a.property.name.toLowerCase();
          const stringB = b.property.name.toLowerCase();
          if (stringA < stringB) return -1;
          else if (stringA > stringB) return 1;
          else return 0;
        })
      );
    }, [propertySummaries]);

    return (
      <Component
        propertySummaries={orderedPropertySummaries}
        {...passThroughProps}
      />
    );
  });

/**
 * Loads all terraprobe data for the selected property and renders the
 * supplied component with the loaded data as props.
 */
export const withIrrigationMonitoringSites = (Component) =>
  observer((props) => {
    const { ...passThroughProps } = props;
    const userStore = useContext(storesContext);

    // The error boundary is not triggered by asynchronous errors, so if an asynchronous function
    // catches an error this is set. An error will be thrown before rendering if this is not null.
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(true);
    const [siteSummaries, setSiteSummaries] = useState([]);
    const [siteSeasons, setSiteSeasons] = useState({});
    const [siteReadings, setSiteReadings] = useState({});
    const [siteStrategies, setSiteStrategies] = useState({});

    /**
     * Loads the following data for each site
     *  - summary (There is no documentation for this route)
     *  - seasons (https://staging.terraprobe.mahal.co.nz/docs/#graphs-api-vsw_date-read)
     *  - readings (https://staging.terraprobe.mahal.co.nz/docs/#graphs-api-vsw_reading-read)
     *  - strategy (https://staging.terraprobe.mahal.co.nz/docs/#graphs-api-vsw_reading-read)
     */
    const loadAllData = async () => {
      setLoading(true);

      // Initialize new state
      let newSiteSummaries = [];
      const newSiteSeasons = {};
      const newSiteReadings = {};
      const newSiteStrategies = {};

      // Attempt to find the selected season
      const terraprobeSeason = await getSelectedSeason(
        userStore.selectedDatabaseStartYear
      );

      // If the selected season doesn't exist in terraprobe then clear the results
      if (!terraprobeSeason) {
        setSiteSummaries(newSiteSummaries);
        setSiteSeasons(newSiteSeasons);
        setSiteReadings(newSiteReadings);
        setSiteStrategies(newSiteStrategies);

        setLoading(false);
        return;
      }

      // Site Dates
      await Promise.all([
        ...userStore.propertiesStore.selectedProperty.irrigation_monitoring_sites.map(
          (site) => {
            return fetch(
              `${process.env.REACT_APP_TERRAPROBE_API_URL}graphs/api/v2/vsw_date/${terraprobeSeason.id}/${site.site_number}/?format=json`,
              {
                method: 'GET'
              }
            )
              .then((res) => {
                if (res.ok) return res.json();
                else throw Error(res.statusText);
              })
              .then((data) => {
                if (data.length > 0) newSiteSeasons[site.id] = data[0];
              })
              .catch((err) => {
                setError(err);
              });
          }
        )
      ]);

      // Site summaries
      const uri = new URI(
        `${process.env.REACT_APP_TERRAPROBE_API_URL}graphs/api/v3/fruition_summary/${terraprobeSeason.id}/sites_summary/`
      );
      uri.setQuery(
        'sites[]',
        userStore.propertiesStore.selectedProperty.irrigation_monitoring_sites.map(
          (irrigationMonitoringSite) => irrigationMonitoringSite.site_number
        )
      );

      await fetch(uri.toString(), {
        method: 'GET'
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error(res.statusText);
        })
        .then((data) => {
          newSiteSummaries = data.sites;
        })
        .catch((err) => {
          setError(err);
        });

      // Site readings
      await Promise.all([
        ...Object.keys(newSiteSeasons)
          .map((siteId) => {
            const site =
              userStore.propertiesStore.selectedProperty.irrigation_monitoring_sites.find(
                (s) => s.id == siteId
              );

            if (site) {
              return fetch(
                `${process.env.REACT_APP_TERRAPROBE_API_URL}graphs/api/vsw_reading/${site.site_number}/${newSiteSeasons[siteId].period_from}/${newSiteSeasons[siteId].period_to}/True/?format=json`
              )
                .then((res) => {
                  if (res.ok) return res.json();
                  else throw new Error(res.statusText);
                })
                .then((data) => {
                  newSiteReadings[siteId] = data;
                })
                .catch((err) => {
                  setError(err);
                });
            } else {
              return null;
            }
          })
          .filter((res) => res !== null)
      ]);

      // Site strategies
      await Promise.all([
        ...Object.keys(newSiteSeasons)
          .map((siteId) => {
            const site =
              userStore.propertiesStore.selectedProperty.irrigation_monitoring_sites.find(
                (s) => s.id == siteId
              );
            if (site) {
              return fetch(
                `${process.env.REACT_APP_TERRAPROBE_API_URL}graphs/api/vsw_strategy/${site.site_number}/${newSiteSeasons[siteId].period_from}/${newSiteSeasons[siteId].period_to}/?format=json`
              )
                .then((res) => {
                  if (res.ok) return res.json();
                  else throw Error(res.statusText);
                })
                .then((data) => {
                  newSiteStrategies[site.id] = data;
                })
                .catch((err) => {
                  setError(err);
                });
            } else {
              return null;
            }
          })
          .filter((res) => res !== null)
      ]);

      // Update state
      setSiteSummaries(newSiteSummaries);
      setSiteSeasons(newSiteSeasons);
      setSiteReadings(newSiteReadings);
      setSiteStrategies(newSiteStrategies);

      setLoading(false);
    };

    useEffect(() => {
      if (
        !userStore.propertiesStore.loading &&
        userStore.propertiesStore.selectedPropertyId !== null
      ) {
        loadAllData();
      }
    }, [
      userStore.bearerToken,
      userStore.selectedDatabase,
      userStore.propertiesStore.loading,
      userStore.propertiesStore.selectedPropertyId
    ]);

    if (error) throw error;

    return userStore.propertiesStore.loading ? (
      <LoadingOverlay hideOpacity />
    ) : !userStore.propertiesStore.selectedProperty ? (
      <Alert variant='warning'>Please select a property</Alert>
    ) : loading ? (
      <LoadingOverlay hideOpacity />
    ) : !siteSummaries || !siteSeasons || !siteReadings || !siteStrategies ? (
      <Alert variant='danger'>
        Something went wrong loading the irrigation data.
      </Alert>
    ) : (
      <Component
        siteSummaries={siteSummaries}
        siteSeasons={siteSeasons}
        siteReadings={siteReadings}
        siteStrategies={siteStrategies}
        {...passThroughProps}
      />
    );
  });

