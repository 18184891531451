import { Alert, Col, Row } from 'react-bootstrap';
import PageTitle from '../../helpers/PageTitle';
import FileUpload from './FileUpload';

const BlockScanningUploadPage = () => {
  return (
    <div className='mb-4'>
      <Row>
        <Col>
          <PageTitle title={'Block Scanning Upload'} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className='text-center'>
            Upload the latest blocks and view on the Block Scanning Reports map
            tool
          </h6>
        </Col>
      </Row>
      <Row className='my-2 no-gutters' xs={12} lg={8}>
        <Col xs={0} lg={1} xl={2} />
        <Col xs={12} lg={10} xl={8}>
          <Alert variant='info'>
            <Alert.Heading>How to use</Alert.Heading>
            <p>
              To get started, click 'Browse' to choose the files you wish to
              upload. These files must contain all polygons. Please choose the
              files with the latest polygons. Click 'Upload' to begin the
              process. This can take several minutes. Please do not refresh your
              browser once the process has started.
            </p>
            <hr />
            <h5>File Type</h5>
            <p className='mb-0'>
              Only JSON files are accepted. These files must contain a property
              named <i>block-polygons</i> that is a GeoJSON FeatureCollection
              that contains a <i>features</i> array of GeoJSON Polygon features.
              Please contact support if you need any assistance.
            </p>
          </Alert>
        </Col>
        <Col xs={0} lg={2} xl={2} />
      </Row>
      <Row className='no-gutters mt-2' xs={12} lg={8}>
        <Col xs={0} lg={1} xl={2} />
        <Col xs={12} lg={10} xl={8}>
          <FileUpload />
        </Col>
        <Col xs={0} lg={2} xl={2} />
      </Row>
    </div>
  );
};

export default BlockScanningUploadPage;

