import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

export const monoStyle = 'cklubq40e06ej17ojtb4pg2o1';
export const satelliteStyle = 'cknpikbkn0jut17nwxfi4mbh8';

const PreviewButton = (props) => {
  const newStyle =
    props.mapStyle === satelliteStyle ? monoStyle : satelliteStyle;
  return (
    <div
      className='m-3'
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0
      }}
    >
      <ButtonGroup>
        <Button
          onClick={() => props.setMapStyle(monoStyle)}
          variant={props.mapStyle === monoStyle ? 'secondary' : 'light'}
        >
          Map View
        </Button>
        <Button
          onClick={() => props.setMapStyle(satelliteStyle)}
          variant={props.mapStyle === satelliteStyle ? 'secondary' : 'light'}
        >
          Satellite View
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default PreviewButton;
