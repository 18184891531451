import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { storesContext } from '../../stores/storesContext';
import PageTitle from '../helpers/PageTitle';
import { errorToast, successToast } from '../helpers/toasts/ToastUtils';
import { fetchTrapCSV } from './utils';

function AdminDashboard() {
  const userStore = useContext(storesContext);

  return (
    <div id='page-container'>
      <Row>
        <Col>
          <PageTitle title={'Dashboard'} />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Card className='my-3'>
            <Card.Header>Data Exporting</Card.Header>
            <Card.Body>
              <Card.Title>Trapping</Card.Title>
              <Card.Text>
                Export all trapping data from the selected season into a CSV
                file.
              </Card.Text>
              <Button
                variant='light'
                onClick={async () => {
                  const result = await fetchTrapCSV(userStore);
                  if (result) {
                    successToast('Export successful.');
                  } else {
                    errorToast('Export failed.');
                  }
                }}
              >
                Export All Data
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default observer(AdminDashboard);

