import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import PageTitle from '../helpers/PageTitle';
import LoadingOverlay from '../LoadingOverlay';

function ChangePassword() {
  const userStore = useContext(storesContext);
  let history = useHistory();
  const [message, setMessage] = useState(null);

  return (
    <div id='page-container'>
      <Row>
        <Col>
          <PageTitle title={'Change Password'} />
        </Col>
      </Row>

      {message ? (
        <Alert
          variant={message.variant}
          onClose={() => {
            setMessage(null);
          }}
          dismissible
        >
          {message.message}
        </Alert>
      ) : null}

      <Row className='mt-2'>
        <Col xl={6} lg={6} md={12} className='my-2 mx-auto'>
          <Formik
            initialValues={{
              password: '',
              newpassword: '',
              newpassword_confirmation: ''
            }}
            validate={(values) => {
              const errors = {};

              if (!values.password) {
                errors.password = 'Required';
              }

              if (!values.newpassword) {
                errors.newpassword = 'Required';
              } else if (values.newpassword === values.password) {
                errors.newpassword = 'Please enter a new password';
              } else if (
                values.newpassword !== values.newpassword_confirmation
              ) {
                errors.newpassword_confirmation =
                  'Please confirm your new password';
              }

              return errors;
            }}
            onSubmit={(values, actions) => {
              fetch(
                process.env.REACT_APP_AUTH_API_URL + 'api/change-password',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + userStore.bearerToken,
                    service: 'fruition'
                  },
                  body: JSON.stringify(values)
                }
              )
                .then((res) => {
                  if (res.ok) history.push('/account');
                  else if (res.status === 401) userStore.refresh();
                  else if (res.status === 422) return res.json();
                  else throw Error(res.statusText);
                })
                .then((data) => {
                  if (data.errors && data.errors.password) {
                    setMessage({
                      variant: 'danger',
                      message: data.errors.password[0]
                    });
                  }
                })
                .catch((err) => {
                  if (process.env.NODE_ENV === 'development')
                    console.error(err);
                  setMessage({
                    variant: 'danger',
                    message:
                      'Sorry something went wrong, please try again or contact support.'
                  });
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(props) => (
              <Fragment>
                <Form noValidate onSubmit={props.handleSubmit}>
                  <Form.Group controlId='password'>
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control
                      type='password'
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.password}
                      placeholder='Enter your current password'
                      isInvalid={props.errors.password}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {props.errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='newpassword'>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type='password'
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.newpassword}
                      placeholder='Enter your new password'
                      isInvalid={props.errors.newpassword}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {props.errors.newpassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId='newpassword_confirmation'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type='password'
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.newpassword_confirmation}
                      placeholder='Confirm your new password'
                      isInvalid={props.errors.newpassword_confirmation}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {props.errors.newpassword_confirmation}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className='d-flex justify-content-center'>
                    <Button
                      className='mr-2'
                      variant='outline-secondary'
                      as={Link}
                      to='/account'
                    >
                      Cancel
                    </Button>
                    <Button variant='primary' type='submit'>
                      Submit
                    </Button>
                  </div>
                </Form>
                {props.isSubmitting ? <LoadingOverlay /> : null}
              </Fragment>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default observer(ChangePassword);

