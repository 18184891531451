import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { storesContext } from '../../stores/storesContext';
import { Row, Col } from 'react-bootstrap';
import RecommendationTable from './tables/RecommendationTable';
import { withIrrigationMonitoringSites } from './IrrigationProviders';

function ProgrammingGuide(props) {
  const userStore = useContext(storesContext);

  return (
    <Fragment>
      {userStore.propertiesStore.selectedProperty.irrigation_monitoring_sites.map(
        (site) => {
          if (
            props.siteReadings[site.id] &&
            props.siteReadings[site.id].filter(
              (reading) => reading.type === 'Probe'
            ).length > 0
          )
            return (
              <Row key={site.id} className='my-3'>
                <Col>
                  <h4>{site.name}</h4>
                  <RecommendationTable
                    readingData={props.siteReadings[site.id]}
                    site={site}
                  />
                </Col>
              </Row>
            );
          else
            return (
              <Row key={site.id}>
                <Col>{site.name} - No Data</Col>
              </Row>
            );
        }
      )}
    </Fragment>
  );
}

ProgrammingGuide.propTypes = {
  siteReadings: PropTypes.object.isRequired
};

// Doesn't need to be wrapped in observer because withIrrigationMonitoringSites is
export default withIrrigationMonitoringSites(ProgrammingGuide);
