import {
  faDownload,
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import {
  Button,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import { storesContext } from '../../stores/storesContext';
import GlobalTableFilter from '../GlobalTableFilter';
import TablePagination from '../helpers/TablePagination';
import AdvancedFilterModal from './AdvancedFilterModal';

const ReportsTable = ({ data, formik, blocks, loadingBlocks }) => {
  const userStore = useContext(storesContext);
  const propertiesStore = userStore.propertiesStore;

  const downloadReport = (report) => {
    fetch(
      `${process.env.REACT_APP_PROPERTIES_API_URL}api/fruition/crop-scanning/reports/${report.key}`,
      {
        headers: {
          Authorization: `Bearer ${userStore.bearerToken}`,
          Accept: 'application/pdf'
        }
      }
    )
      .then((res) => {
        if (res.ok) return res.blob();
      })
      .then((blob) => {
        if (blob) {
          saveAs(
            blob,
            `${moment(report.dt).format('YYYYMMDD')}-${
              propertiesStore.selectedProperty.identifier
            }-${propertiesStore.selectedProperty.name}-${report.blockCode}-${
              report.scanType
            }-${report.stage}-${report.reportType}-${report.pdfType}`
          );
        }
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'dt',
        Cell: ({ value }) => {
          return moment(value).format('YYYY-MM-DD');
        }
      },
      {
        Header: 'Block Code',
        accessor: 'blockCode'
      },
      {
        Header: 'Scan Type',
        accessor: 'scanType'
      },
      {
        Header: 'Stage',
        accessor: 'stage'
      },
      {
        Header: 'Report Type',
        accessor: 'reportType'
      },
      {
        Header: 'PDF Type',
        accessor: 'pdfType'
      },
      {
        id: 'download',
        sortable: false,
        Header: () => (
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip>Download all reports on this page.</Tooltip>}
          >
            <Button
              onClick={() => {
                page.forEach((row) => downloadReport(row.original));
              }}
              variant='link'
            >
              <FontAwesomeIcon icon={faDownload} size='lg' />
            </Button>
          </OverlayTrigger>
        ),
        Cell: ({ row }) => (
          <Button
            onClick={() => {
              downloadReport(row.original);
            }}
            variant='link'
            className='text-dark'
          >
            <FontAwesomeIcon icon={faDownload} size='sm' />
          </Button>
        )
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className='mb-2'>
        <Col className='pr-0' md>
          <GlobalTableFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            style={{
              marginBottom: '0.5rem',
              maxWidth: 500
            }}
          />
        </Col>
        <Col className='text-left ml-3 pl-0'>
          <AdvancedFilterModal
            formik={formik}
            blocks={blocks}
            loadingBlocks={loadingBlocks}
          />
          <Button
            variant='light'
            className='ml-2'
            onClick={() => {
              formik.resetForm();
              formik.submitForm();
            }}
          >
            Clear Filters
          </Button>
        </Col>
      </Row>
      <Table responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.canSort ? (
                    <span className='px-2'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={
                  row.original.pdfType === 'Geo pdf' ? 'text-info' : ''
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <TablePagination
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default observer(ReportsTable);

