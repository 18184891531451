import bbox from '@turf/bbox';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Button, ButtonGroup, Popover } from 'react-bootstrap';
import ReactMapGL, {
  AttributionControl,
  Layer,
  Source,
  WebMercatorViewport
} from 'react-map-gl';
import { useHistory } from 'react-router';
import { storesContext } from '../../../stores/storesContext';
import {
  AlertLevelLabel,
  formatSiteType,
  irrimaxURL,
  openIrrimaxWindow
} from '../helpers';

const monoStyle = 'cklubq40e06ej17ojtb4pg2o1';
const satelliteStyle = 'cknpikbkn0jut17nwxfi4mbh8';

const PreviewButton = (props) => (
  <div
    className='m-3'
    style={{
      position: 'absolute',
      bottom: 0,
      right: 0
    }}
  >
    <ButtonGroup>
      <Button
        onClick={() => props.setMapStyle(monoStyle)}
        variant={props.mapStyle === monoStyle ? 'secondary' : 'light'}
      >
        Map View
      </Button>
      <Button
        onClick={() => props.setMapStyle(satelliteStyle)}
        variant={props.mapStyle === satelliteStyle ? 'secondary' : 'light'}
      >
        Satellite View
      </Button>
    </ButtonGroup>
  </div>
);

const alertLevel0Filter = ['==', ['get', 'alertLevel'], 0];
const alertLevel1Filter = ['==', ['get', 'alertLevel'], 1];
const alertLevel2Filter = ['==', ['get', 'alertLevel'], 2];
const alertLevel3Filter = ['==', ['get', 'alertLevel'], 3];
const alertLevel4Filter = ['==', ['get', 'alertLevel'], 4];

// This does not use the withIrrigationSummaries HOC to
// stop duplicated requests on the dashboard.
const SummaryMap = (props) => {
  const userStore = useContext(storesContext);
  const history = useHistory();
  const [viewport, setViewport] = useState({
    latitude: -40.9753799,
    longitude: 176.8903929,
    zoom: 4,
    height: props.height,
    width: props.width
  });
  const mapRef = useRef(null);
  const [mapStyle, setMapStyle] = useState(monoStyle);
  const [initialBoundsSet, setInitialBoundsSet] = useState(false);
  const [hoverInfo, setHoverInfo] = useState();

  const [prevFeatureCount, setPrevFeatureCount] = useState();

  // Geojson object for the property boundry.
  const boundry = useMemo(() => {
    const geojson = {
      type: 'FeatureCollection',
      features: []
    };
    props.propertySummaries.forEach((propertySummary) => {
      if (propertySummary.property && propertySummary.property.boundry) {
        geojson.features.push({
          type: 'Feature',
          geometry: {
            ...propertySummary.property.boundry
          },
          properties: {}
        });
      }
    });
    return geojson;
  }, [props.propertySummaries]);

  // Geojson object for the property monitoring sites.
  const points = useMemo(() => {
    const geojson = {
      type: 'FeatureCollection',
      features: []
    };

    props.propertySummaries.forEach((propertySummary) => {
      if (!propertySummary.loading && propertySummary.results)
        propertySummary.property.irrigation_monitoring_sites.forEach(
          (irrigation_monitoring_site) => {
            const result = propertySummary.results.sites.find(
              (s) =>
                s.site_id === Number(irrigation_monitoring_site.site_number)
            );
            // Handle coordinates in different order. This is a Terraprobe API issue which
            // will be fixed in the future.
            const _longitude = irrigation_monitoring_site.longitude;
            const _latitude = irrigation_monitoring_site.latitude;
            // Set longitude to the original longitude value if the original latitude value is correct.
            // Otherwise set to the original latitude value.
            const longitude =
              _latitude >= -90 && _latitude <= 90 ? _longitude : _latitude;
            // If the new longitude value if different to the original longitude value then set the
            // latitude to the original longitude value. Otherwise, set to the original latitude value.
            const latitude = _longitude !== longitude ? _longitude : _latitude;

            if (latitude && longitude) {
              const site = propertySummary.results.sites.find(
                (site) =>
                  site.site_id ===
                  Number(irrigation_monitoring_site.site_number)
              );
              let alertLevel = result ? result?.alert_level : 0;
              let status = result
                ? AlertLevelLabel(result.alert_level)
                : 'No Data';
              if (
                alertLevel === 3 &&
                site?.latest_reading.rz1 > site?.full_point
              ) {
                alertLevel = 4;
                status = 'Above Full Point';
              }
              // Handle different cases for different Site providers.
              const siteType = irrigation_monitoring_site.type;
              const displaySiteType = formatSiteType(siteType);
              if (siteType === 'irrimax') {
                geojson.features.push({
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [longitude, latitude]
                  },
                  properties: {
                    type: 'irrimax',
                    displayType: displaySiteType,
                    propertyId: irrigation_monitoring_site.property_id,
                    propertyName: propertySummary.property.name,
                    propertyIdentifier: irrigation_monitoring_site.site_number,
                    siteId: irrigation_monitoring_site.id,
                    siteName: irrigation_monitoring_site.site_name,
                    siteJson: irrigation_monitoring_site,
                    fullPoint: '',
                    latestReading: ''
                  }
                });
              } else {
                if (!site) return;
                geojson.features.push({
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [longitude, latitude]
                  },
                  properties: {
                    type: 'terraprobe',
                    displayType: displaySiteType,
                    alertLevel,
                    status,
                    propertyId: propertySummary.property.id,
                    propertyName: propertySummary.property.name,
                    propertyIdentifier: propertySummary.property.identifier,
                    siteId: irrigation_monitoring_site.id,
                    siteName: irrigation_monitoring_site.site_name,
                    siteJson: irrigation_monitoring_site,
                    fullPoint: site.full_point,
                    latestReading: site.latest_reading.rz1
                  }
                });
              }
            }
          }
        );
    });

    return geojson;
  }, [props.propertySummaries]);

  useEffect(() => {
    if (mapRef.current && mapStyle) {
      const map = mapRef.current.getMap();
      // Can't load in S3 object because of a CORS error.
      map.loadImage(
        'https://fruition-metwatch-assets.s3-ap-southeast-2.amazonaws.com/sentek-logo-32.png',
        (error, image) => {
          if (error) throw error;
          map.addImage('custom-marker', image);
        }
      );
    }
  }, [mapRef, mapStyle]);

  const layers = [
    {
      id: 'irrimax_site',
      type: 'symbol',
      layout: {
        'icon-image': 'custom-marker'
      },
      filter: ['==', 'type', 'irrimax']
    },
    {
      id: 'no_data',
      type: 'circle',
      paint: {
        'circle-radius': 10,
        'circle-color': '#b4b8bd',
        'circle-stroke-color': '#6c757d',
        'circle-stroke-width': 3
      },
      filter: ['==', 'alertLevel', 0]
    },
    {
      id: 'selected_no_data',
      type: 'circle',
      paint: {
        'circle-radius': 13,
        'circle-color': '#6c757d'
      },
      filter: [
        'all',
        ['==', 'alertLevel', 0],
        ['==', 'siteId', props.selectedSite ? props.selectedSite.id : 0]
      ]
    },
    {
      id: 'no_action_required',
      type: 'circle',
      paint: {
        'circle-radius': 10,
        'circle-color': '#9ccea5',
        'circle-stroke-color': '#28a745',
        'circle-stroke-width': 3
      },
      filter: props.selectedSite
        ? [
            'all',
            ['==', 'alertLevel', 1],
            ['!=', 'siteId', props.selectedSite.id]
          ]
        : ['==', 'alertLevel', 1]
    },
    {
      id: 'selected_no_action_required',
      type: 'circle',
      paint: {
        'circle-radius': 13,
        'circle-color': '#28a745'
      },
      filter: [
        'all',
        ['==', 'alertLevel', 1],
        ['==', 'siteId', props.selectedSite ? props.selectedSite.id : 0]
      ]
    },
    {
      id: 'near_threshold',
      type: 'circle',
      paint: {
        'circle-radius': 10,
        'circle-color': '#fcdf8e',
        'circle-stroke-color': '#ffc107',
        'circle-stroke-width': 3
      },
      filter: ['==', 'alertLevel', 2]
    },
    {
      id: 'selected_near_threshold',
      type: 'circle',
      paint: {
        'circle-radius': 13,
        'circle-color': '#ffc107'
      },
      filter: [
        'all',
        ['==', 'alertLevel', 2],
        ['==', 'siteId', props.selectedSite ? props.selectedSite.id : 0]
      ]
    },
    {
      id: 'threshold_met',
      type: 'circle',
      paint: {
        'circle-radius': 10,
        'circle-color': '#e49ca2',
        'circle-stroke-color': '#dc3545',
        'circle-stroke-width': 3
      },
      filter: ['==', 'alertLevel', 3]
    },
    {
      id: 'threshold_met_above',
      type: 'circle',
      paint: {
        'circle-radius': 10,
        'circle-color': '#b5d8f7',
        'circle-stroke-color': '#0275d8',
        'circle-stroke-width': 3
      },
      filter: ['==', 'alertLevel', 4]
    },
    {
      id: 'selected_threshold_met',
      type: 'circle',
      paint: {
        'circle-radius': 13,
        'circle-color': '#dc3545'
      },
      filter: [
        'all',
        ['==', 'alertLevel', 3],
        ['<', 'latestReading', 'fullPoint'],
        ['==', 'siteId', props.selectedSite ? props.selectedSite.id : 0]
      ]
    },
    {
      id: 'selected_threshold_met_above',
      type: 'circle',
      paint: {
        'circle-radius': 13,
        'circle-color': '#0275d8'
      },
      filter: [
        'all',
        ['==', 'alertLevel', 4],
        ['==', 'siteId', props.selectedSite ? props.selectedSite.id : 0]
      ]
    },
    {
      id: 'clusters',
      type: 'circle',
      source: 'clusters',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#ffffff',
        'circle-stroke-width': 3,
        'circle-stroke-color': [
          'case',
          ['>', ['get', 'alertLevel3'], 0],
          '#dc3545',
          ['>', ['get', 'alertLevel2'], 0],
          '#ffc107',
          ['>', ['get', 'alertLevel1'], 0],
          '#28a745',
          ['>', ['get', 'alertLevel4'], 0],
          '#0275d8',
          ['>', ['get', 'alertLevel0'], 0],
          '#6c757d',
          '#6c757d'
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
      }
    },
    {
      id: 'cluster_count',
      type: 'symbol',
      source: 'clusters',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    }
  ];

  const fitBounds = useCallback(() => {
    const featureCollection = {
      type: 'FeatureCollection',
      features: [...boundry.features, ...points.features]
    };
    if (featureCollection.features.length > 0) {
      let minLng;
      let minLat;
      let maxLng;
      let maxLat;

      featureCollection.features.forEach((feature) => {
        const [newMinLng, newMinLat, newMaxLng, newMaxLat] = bbox(feature);
        if (minLng === undefined || newMinLng < minLng) minLng = newMinLng;
        if (maxLng === undefined || newMaxLng > maxLng) maxLng = newMaxLng;
        if (minLat === undefined || newMinLat < minLat) minLat = newMinLat;
        if (maxLat === undefined || newMaxLat > maxLat) maxLat = newMaxLat;
      });
      // construct a viewport instance from the current state
      const vp = new WebMercatorViewport(viewport);
      let { longitude, latitude, zoom } = vp.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        {
          padding: 20
        }
      );

      if (zoom > 16) zoom = 16;

      setViewport({
        ...viewport,
        longitude,
        latitude,
        zoom
      });
      setInitialBoundsSet(true);
    }
  }, [boundry.features, points.features, viewport]);

  // Update the bounds of the map once the 100% width has been converted to an integer
  // Bounds for the map is redrawn when new data features are loaded.
  useEffect(() => {
    if (
      (!initialBoundsSet ||
        (prevFeatureCount && points.features.length > prevFeatureCount)) &&
      points.features.length > 0 &&
      typeof viewport.width === 'number'
    ) {
      fitBounds();
    }
    setPrevFeatureCount(points.features.length);
  }, [viewport.width, points, prevFeatureCount, initialBoundsSet, fitBounds]);

  return (
    <ReactMapGL
      {...viewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      width={props.width}
      height={props.height}
      onViewportChange={(nextViewport) => setViewport(nextViewport)}
      ref={mapRef}
      mapStyle={`mapbox://styles/hortplus/${mapStyle}`}
      attributionControl={false}
      preserveDrawingBuffer={true}
      interactiveLayerIds={[
        'irrimax_site',
        'no_data',
        'no_action_required',
        'near_threshold',
        'threshold_met',
        'threshold_met_above',
        'clusters'
      ]}
      onHover={(e) => {
        const hoveredFeature = e.features && e.features[0];

        if (hoveredFeature) {
          if (hoveredFeature.properties.cluster) {
            const x = e.srcEvent.offsetX;
            const y = e.srcEvent.offsetY;
            const mapboxSource = mapRef.current
              .getMap()
              .getSource('irrigationMonitoringSites');

            mapboxSource.getClusterLeaves(
              hoveredFeature.properties.cluster_id,
              hoveredFeature.properties.point_count,
              0,
              (error, features) => {
                const properties = {};
                features.forEach((feature) => {
                  properties[feature.properties.propertyIdentifier] = {
                    name: feature.properties.propertyName,
                    identifier: feature.properties.propertyIdentifier
                  };
                });

                setHoverInfo({
                  type: 'cluster',
                  feature: hoveredFeature,
                  x: x,
                  y: y,
                  properties: Object.values(properties)
                });
              }
            );
          } else {
            setHoverInfo({
              type: 'site',
              feature: hoveredFeature,
              x: e.srcEvent.offsetX,
              y: e.srcEvent.offsetY
            });
          }
        } else {
          setHoverInfo(null);
        }
      }}
      onClick={(e) => {
        const clickedFeature = e.features && e.features[0];
        if (!clickedFeature) return;

        if (!clickedFeature.properties.cluster) {
          if (clickedFeature.properties.type === 'irrimax') {
            openIrrimaxWindow(
              irrimaxURL(JSON.parse(clickedFeature.properties.siteJson))
            );
            return;
          }
          history.push('/soil-water/monitoring');
          userStore.propertiesStore.selectProperty(
            clickedFeature.properties.propertyId,
            userStore.selectedDatabase,
            userStore.bearerToken,
            userStore.refreshCallback
          );
          if (props.selectSite)
            props.selectSite(JSON.parse(clickedFeature.properties.siteJson));
        } else {
          const mapboxSource = mapRef.current
            .getMap()
            .getSource('irrigationMonitoringSites');

          mapboxSource.getClusterExpansionZoom(
            clickedFeature.properties.cluster_id,
            (err, zoom) => {
              setViewport({
                ...viewport,
                longitude: clickedFeature.geometry.coordinates[0],
                latitude: clickedFeature.geometry.coordinates[1],
                zoom,
                transitionDuration: 500
              });
            }
          );
        }
      }}
    >
      <AttributionControl compact={true} />
      <Button variant='light' onClick={fitBounds} className='m-3 float-right'>
        Reset
      </Button>
      <Source id='boundry' type='geojson' data={boundry}>
        <Layer
          id='polygon'
          type='fill'
          paint={{
            'fill-color': '#6C757D',
            'fill-opacity': 0.5
          }}
        />
      </Source>
      <Source
        id='irrigationMonitoringSites'
        type='geojson'
        data={points}
        cluster={true}
        clusterMaxZoom={12}
        clusterRadius={50}
        clusterProperties={{
          alertLevel0: ['+', ['case', alertLevel0Filter, 1, 0]],
          alertLevel1: ['+', ['case', alertLevel1Filter, 1, 0]],
          alertLevel2: ['+', ['case', alertLevel2Filter, 1, 0]],
          alertLevel3: ['+', ['case', alertLevel3Filter, 1, 0]],
          alertLevel4: ['+', ['case', alertLevel4Filter, 1, 0]]
        }}
      >
        {layers.map((layer) => (
          <Layer key={layer.id} {...layer} />
        ))}
      </Source>
      {hoverInfo ? (
        hoverInfo.type === 'site' ? (
          <Popover
            id={hoverInfo.feature.properties.id}
            placement='auto'
            style={{
              left:
                viewport.width - hoverInfo.x < 200
                  ? hoverInfo.x - 200
                  : hoverInfo.x + 5,
              top:
                viewport.height - hoverInfo.y < 100
                  ? hoverInfo.y - 90
                  : hoverInfo.y,
              position: 'absolute',
              width: '200px',
              zIndex: 9,
              pointerEvents: 'none'
            }}
          >
            <Popover.Title>
              <b>{hoverInfo.feature.properties.propertyIdentifier}</b>{' '}
              {hoverInfo.feature.properties.propertyName}
            </Popover.Title>
            <Popover.Content>
              Type: <b>{hoverInfo.feature.properties.displayType}</b>
              <br />
              Site: <b>{hoverInfo.feature.properties.siteName}</b>
              <br />
              {hoverInfo.feature.properties.type !== 'irrimax' && (
                <>
                  Status: <b>{hoverInfo.feature.properties.status}</b>
                </>
              )}
            </Popover.Content>
          </Popover>
        ) : hoverInfo.type === 'cluster' ? (
          <Popover
            id={hoverInfo.feature.properties.id}
            placement='auto'
            style={{
              left:
                viewport.width - hoverInfo.x < 200
                  ? hoverInfo.x - 200
                  : hoverInfo.x + 5,
              top:
                viewport.height - hoverInfo.y < 100
                  ? hoverInfo.y - 90
                  : hoverInfo.y,
              position: 'absolute',
              width: '200px',
              zIndex: 9,
              pointerEvents: 'none'
            }}
          >
            <Popover.Title>
              {hoverInfo.properties.length} Properties
            </Popover.Title>
            <Popover.Content>
              {hoverInfo.properties.map((property) => (
                <div key={property.identifier}>
                  <b>{property.identifier}</b>: {property.name}
                  <br />
                </div>
              ))}
            </Popover.Content>
          </Popover>
        ) : null
      ) : null}

      <PreviewButton
        setMapStyle={setMapStyle}
        mapStyle={mapStyle}
        viewport={viewport}
      />
    </ReactMapGL>
  );
};

SummaryMap.propTypes = {
  // Misc
  height: PropTypes.any.isRequired,
  width: PropTypes.any.isRequired,
  propertySummaries: PropTypes.array.isRequired,
  selectedSite: PropTypes.any,
  selectSite: PropTypes.func
};

export default observer(SummaryMap);

