import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mobx-react/batchingForReactDom'; // https://github.com/mobxjs/mobx-react-lite/#observer-batching
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './custom-bootstrap.scss';
import './global-map-styles.scss';
import * as serviceWorker from './serviceWorker';

// Momentjs
import * as moment from "moment";
import * as mTZ from "moment-timezone";

// Highcharts
import Highcharts from "highcharts";
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/pattern-fill")(Highcharts);

// Highcharts timezone fix
// https://github.com/highcharts/highcharts/issues/8661
window.moment = moment;
mTZ();

// Fixes mapbox issue in production build https://github.com/mapbox/mapbox-gl-js/issues/10173
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
