import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import isMobile from 'ismobilejs';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import LoadingOverlay from '../LoadingOverlay';
import PageTitle from '../helpers/PageTitle';
import { validateEmailAddress } from '../helpers/Validation';

function LoginForm(props) {
  return (
    <Form noValidate onSubmit={props.handleSubmit}>
      <Form.Group controlId='email'>
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          type='email'
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.email}
          placeholder='Enter your email address'
          autoComplete='username'
          isInvalid={!!props.errors.email}
        />
        <Form.Control.Feedback type='invalid'>
          {props.errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='password'>
        <Form.Label>Password</Form.Label>
        <Form.Control
          type='password'
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.password}
          placeholder='Password'
          autoComplete='current-password'
          isInvalid={!!props.errors.password}
        />
        <Form.Control.Feedback type='invalid'>
          {props.errors.password}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='remember'>
        <Form.Check
          type='checkbox'
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          checked={props.values.remember}
          label='Remember me'
        />
      </Form.Group>

      <div className='d-flex justify-content-center'>
        <Button
          className='mr-2'
          variant='outline-secondary'
          as={Link}
          to='/forgot-password'
        >
          Forgot Password
        </Button>
        <Button variant='primary' type='submit'>
          Login
        </Button>
      </div>
      <div className='text-center mt-3'>
        <a href='https://www.fruitionhort.com/' target='new'>
          Interested in Fruition Technical Services?{' '}
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </div>
    </Form>
  );
}

function Login() {
  const userStore = useContext(storesContext);
  const [loginFailed, setLoginFailed] = useState(false);

  return (
    <div className='h-100 d-flex flex-column justify-content-between'>
      <div className='mb-3'>
        <Row>
          <Col>
            <PageTitle title={'Login'} />
          </Col>
        </Row>
        <Row className='justify-content-center mt-3'>
          <Col md={6}>
            {loginFailed ? (
              <Alert
                variant='danger'
                onClose={() => setLoginFailed(false)}
                dismissible
              >
                Failed to login, check that your email address and password is
                correct
              </Alert>
            ) : null}
            <Formik
              initialValues={{ email: '', password: '', remember: true }}
              validate={(values) => {
                const errors = {};

                if (!values.email) {
                  errors.email = 'Required';
                } else if (!validateEmailAddress(values.email)) {
                  errors.email = 'Invalid email address';
                }

                if (!values.password) errors.password = 'Required';

                return errors;
              }}
              onSubmit={(values, actions) => {
                fetch(
                  process.env.REACT_APP_AUTH_API_URL + 'api/token/jwt/generate',
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Accept: 'application/json',
                      service: 'fruition'
                    },
                    body: JSON.stringify({
                      email: values.email,
                      password: values.password
                    })
                  }
                )
                  .then((res) => {
                    if (res.ok) return res.json();
                    else throw Error(res.statusText);
                  })
                  .then((data) => {
                    if (data) {
                      userStore.login(
                        data.jwt_token,
                        data.refresh_token,
                        values.remember
                      );
                    }
                  })
                  .catch((err) => {
                    if (process.env.NODE_ENV === 'development')
                      console.error(err);
                    actions.setSubmitting(false);
                    setLoginFailed(true);
                  });
              }}
            >
              {(props) =>
                isMobile(window.navigator).any ? (
                  <div>
                    <LoginForm {...props} />
                    {props.isSubmitting ? <LoadingOverlay /> : null}
                  </div>
                ) : (
                  <Card>
                    <Card.Body>
                      <LoginForm {...props} />
                      {props.isSubmitting ? <LoadingOverlay /> : null}
                    </Card.Body>
                  </Card>
                )
              }
            </Formik>
          </Col>
        </Row>
      </div>
      <img
        className='w-100'
        alt='login-banner'
        src={`https://fruition-metwatch-assets.s3-ap-southeast-2.amazonaws.com/fruition-login${
          isMobile(window.navigator).any ? '-mobile' : ''
        }.jpg`}
      />
    </div>
  );
}

export default observer(Login);

