import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import URI from 'urijs';

export const createBlockUploadTransaction = async (
  bearerToken,
  selectedDatabase
) => {
  const url = new URI(
    `${process.env.REACT_APP_PROPERTIES_API_URL}api/admin/fruition/blocks/transaction`
  );
  const response = await fetch(url.valueOf(), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      SeasonalDatabase: selectedDatabase
    }
  });
  if (!response.ok) {
    throw response.statusText;
  }
  const data = await response.json();
  // Throw error if there is no 'id' property in the response.
  if (!('id' in data)) {
    throw new Error('No id in response');
  }
  // Return the transaction ID.
  return data.id;
};

export const updateBlockUploadTransaction = async (
  transactionId,
  bearerToken,
  selectedDatabase,
  status_id = 4,
  message,
  response
) => {
  const url = new URI(
    `${process.env.REACT_APP_PROPERTIES_API_URL}api/admin/fruition/blocks/transaction/${transactionId}`
  );
  const res = await fetch(url.valueOf(), {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      SeasonalDatabase: selectedDatabase
    },
    body: JSON.stringify({ status_id, message, response })
  });
  if (!res.ok) {
    throw res.statusText;
  }
  const data = await res.json();
  return data;
};

export const getUnmatchedBlockClusters = async (
  bearerToken,
  selectedDatabase
) => {
  const url = new URI(
    `${process.env.REACT_APP_PROPERTIES_API_URL}api/admin/fruition/blocks/unmatched`
  );
  const response = await fetch(url.valueOf(), {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      SeasonalDatabase: selectedDatabase
    }
  });
  if (!response.ok) {
    throw response.statusText;
  }
  const data = await response.json();
  return data;
};

export const uploadToS3 = async (
  data,
  filename,
  transactionId,
  selectedDatabase,
  tempAwsCredentials,
  refresh
) => {
  const season = selectedDatabase ? selectedDatabase.substr(9) : null;
  const s3Client = new S3Client({
    region: 'ap-southeast-2',
    credentials: {
      accessKeyId: tempAwsCredentials['key'],
      secretAccessKey: tempAwsCredentials['secret'],
      sessionToken: tempAwsCredentials['token']
    }
  });
  const params = {
    Bucket: process.env.REACT_APP_BLOCK_SCANNING_UPLOAD_BUCKET,
    Key: `unprocessed/${filename}`,
    Body: data,
    ContentType: 'application/json',
    Metadata: {
      transaction_id: transactionId.toString(),
      season,
      run_type:
        process.env.REACT_APP_ENVIRONMENT === 'development'
          ? 'event_test'
          : 'event'
    }
  };
  try {
    const command = new PutObjectCommand(params);
    await s3Client.send(command);
    return true;
  } catch (error) {
    if (error.name === 'ExpiredToken') {
      console.error('Access denied:', error);
      refresh();
      return false;
    }
    console.error('Error uploading file:', error);
    return false;
  }
};

