import { Formik } from 'formik';
import React, { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingOverlay from '../LoadingOverlay';
import PageTitle from '../helpers/PageTitle';
import { validateEmailAddress } from '../helpers/Validation';

function ForgotPassword() {
  const [message, setMessage] = useState(null);

  return (
    <Container>
      <Row>
        <Col>
          <PageTitle title={'Forgot Password'} />
        </Col>
      </Row>
      <Row className='justify-content-center mt-3'>
        <Col md={6}>
          <Formik
            initialValues={{ email: '' }}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                errors.email = 'Required';
              } else if (!validateEmailAddress(values.email)) {
                errors.email = 'Invalid email address';
              }

              return errors;
            }}
            onSubmit={(values, actions) => {
              fetch(
                process.env.REACT_APP_AUTH_API_URL +
                  'api/forgot-password/generate',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    service: 'fruition'
                  },
                  body: JSON.stringify(values)
                }
              )
                .then((res) => {
                  if (res.ok) {
                    setMessage({
                      variant: 'success',
                      message:
                        'Please check your email for a password reset link'
                    });
                  } else if (res.status === 404) {
                    setMessage({
                      variant: 'warning',
                      message:
                        'The supplied email address does not have an account'
                    });
                  } else throw Error(res.statusText);
                })
                .catch((err) => {
                  if (process.env.NODE_ENV === 'development')
                    console.error(err);

                  setMessage({
                    variant: 'danger',
                    message: 'Sorry something went wrong'
                  });
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(props) => (
              <Card>
                <Card.Body>
                  {message ? (
                    <Alert
                      variant={message.variant}
                      onClose={() => {
                        setMessage(null);
                      }}
                      dismissible
                    >
                      {message.message}
                    </Alert>
                  ) : null}
                  <Form noValidate onSubmit={props.handleSubmit}>
                    <Form.Group controlId='email'>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type='email'
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                        placeholder='Enter your email address'
                        autoComplete='username'
                        isInvalid={props.errors.email}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {props.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div className='d-flex justify-content-center'>
                      <Button
                        className='mr-2'
                        variant='outline-secondary'
                        as={Link}
                        to=''
                      >
                        Cancel
                      </Button>
                      <Button variant='primary' type='submit'>
                        Submit
                      </Button>
                    </div>
                  </Form>
                  {props.isSubmitting ? <LoadingOverlay /> : null}
                </Card.Body>
              </Card>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;

