import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

function SeasonToDateTable(props) {
  const irrigation = props.readingData.reduce((accumulator, reading) => {
    if (reading.type === 'Probe') {
      return accumulator + reading.irrigation_mms;
    } else {
      return accumulator;
    }
  }, 0);

  const rain = props.readingData.reduce((accumulator, reading) => {
    if (reading.type === 'Probe') {
      return accumulator + reading.rain;
    } else {
      return accumulator;
    }
  }, 0);

  return (
    <Table bordered responsive className={`${props.className}`}>
      <tbody>
        <tr>
          <td>Irrigation</td>
          <td>
            {irrigation !== null
              ? parseFloat(irrigation.toFixed(1)) + ' mm'
              : 'N/A'}
          </td>
          <td>Rain</td>
          <td>{rain !== null ? parseFloat(rain.toFixed(1)) + ' mm' : 'N/A'}</td>
        </tr>
      </tbody>
    </Table>
  );
}

SeasonToDateTable.propTypes = {
  readingData: PropTypes.array.isRequired
};

export default SeasonToDateTable;
