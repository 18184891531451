import { React, useState, useEffect } from 'react';
import { Alert, Button, Card, Spinner } from 'react-bootstrap';

/**
 * Custom hook for loading Green Atlas bundle URL info.
 * @param {*} selectedPropertyId
 * @param {*} selectedDatabase
 * @param {*} bearerToken
 * @returns
 */
export const useGreenAtlasUrls = (
  selectedPropertyIdentifier,
  selectedDatabase,
  bearerToken
) => {
  const [loadingGaUrls, setLoadingGaUrls] = useState(false);
  const [gaUrls, setGaUrls] = useState([]);

  useEffect(() => {
    const fetchGreenAtlasUrls = async () => {
      // Exit if no property selected.
      if (!selectedPropertyIdentifier) {
        setGaUrls([]);
        setLoadingGaUrls(false);
      }
      setLoadingGaUrls(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PROPERTIES_API_URL}api/green-atlas/bundles/${selectedPropertyIdentifier}/urls`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              SeasonalDatabase: selectedDatabase,
              Authorization: `Bearer ${bearerToken}`
            }
          }
        );

        if (!response.ok) {
          throw [response.status, response.statusText];
        }

        const json = await response.json();
        setGaUrls(json['urls']);
      } catch (err) {
        if (err[0] === 404) {
          console.error('No GA bundles registered for property.');
        } else {
          console.error('Error loading GA bundle URLs');
        }
        setGaUrls([]);
      } finally {
        setLoadingGaUrls(false);
      }
    };

    if (selectedPropertyIdentifier && selectedDatabase && bearerToken) {
      fetchGreenAtlasUrls();
    }
  }, [selectedPropertyIdentifier, selectedDatabase, bearerToken]);

  return { gaUrls, loadingGaUrls };
};

/**
 * Component to render Green Atlas URL buttons.
 * @param {*} param0
 * @returns
 */
export const GreenAtlasUrlButtons = ({ urls, isLoading }) => {
  if (isLoading) {
    return (
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ height: '50px' }}
      >
        <Spinner animation='border' role='status' />
      </div>
    );
  }

  // Don't render anything if there are no bundles associated with the property.
  if (urls.length === 0) {
    return null;
  }

  // Render the buttons. Number them if there are multiple, otherwise just label
  // the button "Green Atlas Viewer"
  return (
    <Card className='mt-3'>
      <Card.Header as='h5' className='bg-success text-white'>
        Green Atlas Viewer
      </Card.Header>
      <Card.Body>
        <Alert variant='success'>
          The Green Atlas Viewer is available for this property. Click{' '}
          {urls.length > 1
            ? 'one of the "Green Atlas Viewer #" buttons'
            : 'the "Green Atlas Viewer" button'}{' '}
          below to load the interactive report viewer.
        </Alert>
        <div className='d-flex flex-wrap gap-2'>
          {urls.map((url, index) => (
            <Button
              className='mr-1'
              key={index}
              variant='outline-success'
              href={url}
              target='_blank'
              rel='noopener noreferrer'
            >
              {urls.length > 1
                ? `Green Atlas Viewer ${index + 1}`
                : 'Green Atlas Viewer'}
            </Button>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

