import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { storesContext } from '../../stores/storesContext';
import { Formik } from 'formik';
import { Card, Row, Col, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import UserDetailsForm, { UserDetailsFormValidator } from './UserDetailsForm';

const UserDetails = () => {
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(null);
  const userStore = useContext(storesContext);

  return editing ? (
    <Formik
      initialValues={{
        name: userStore.user.name,
        email: userStore.user.email,
        send_reports: userStore.user.send_reports
      }}
      validate={UserDetailsFormValidator}
      onSubmit={(values, actions) => {
        fetch(`${process.env.REACT_APP_AUTH_API_URL}api/fruition/user/update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userStore.bearerToken}`,
            service: 'fruition'
          },
          body: JSON.stringify(values)
        })
          .then((res) => {
            if (res.ok) return res.json();
            else if (res.status === 401) userStore.refresh();
            else if (res.status === 422)
              res.json().then((data) => {
                if (data.errors)
                  Object.keys(data.errors).forEach((key) => {
                    actions.setFieldError(key, data.errors[key][0]);
                  });

                actions.setSubmitting(false);
              });
            else throw Error(res.statusText);
          })
          .then((data) => {
            if (data) {
              userStore.updateUser(data);
              setEditing(false);
            }
          })
          .catch((err) => {
            if (process.env.NODE_ENV === 'development') console.error(err);
            setError('Sorry something went wrong');
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => (
        <Card>
          <Card.Header className='d-flex justify-content-between align-items-center'>
            Details
            <div>
              {/* Submit button */}
              <Button
                variant='link'
                className='text-success'
                type='button'
                onClick={props.handleSubmit}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>

              {/* Cancel button */}
              <Button
                variant='link'
                className='text-danger'
                type='button'
                onClick={() => setEditing(false)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            {error ? (
              <Alert
                variant='danger'
                dismissible
                onClose={() => setError(null)}
              >
                {error}
              </Alert>
            ) : null}
            <UserDetailsForm canEditEmail={false} {...props} />
          </Card.Body>
        </Card>
      )}
    </Formik>
  ) : (
    <Card>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        Details
        <Button variant='link' type='button' onClick={() => setEditing(true)}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>Name:</Col>
          <Col>{userStore.user.name}</Col>
        </Row>
        <Row>
          <Col>Email:</Col>
          <Col>{userStore.user.email}</Col>
        </Row>
        <Row>
          <Col>Send Reports:</Col>
          <Col>
            {userStore.user.send_reports ? (
              <FontAwesomeIcon className='text-success' icon={faCheck} />
            ) : (
              <FontAwesomeIcon className='text-danger' icon={faTimes} />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default observer(UserDetails);
