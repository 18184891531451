import bbox from '@turf/bbox';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Layer, Map, Source } from 'react-map-gl-7';
import PreviewButton, { monoStyle } from '../../helpers/PreviewButton';

const PolygonMap = ({ polygon }) => {
  const initialViewPort = {
    latitude: -41,
    longitude: 174.5,
    zoom: 4,
    height: 400,
    width: '100%'
  };
  const [viewport, setViewport] = useState(initialViewPort);
  const [initialBoundsSet, setInitialBoundsSet] = useState(false);
  const [mapStyle, setMapStyle] = useState(monoStyle);
  const mapRef = useRef();

  const layers = [
    {
      id: 'layerStyleFill',
      type: 'fill',
      paint: {
        'fill-color': 'green',
        'fill-opacity': 0.3
      }
    },
    {
      id: 'layerStyleLine',
      type: 'line',
      paint: {
        'line-color': 'green',
        'line-width': 2
      }
    }
  ];

  const data = useMemo(() => {
    const geojson = {
      type: 'FeatureCollection',
      features: [polygon]
    };
    return geojson;
  }, [polygon]);

  const fitBounds = useCallback(
    (map) => {
      const features = data.features;
      if (features && features.length > 0) {
        let minLng;
        let minLat;
        let maxLng;
        let maxLat;
        data.features.forEach((feature) => {
          const [newMinLng, newMinLat, newMaxLng, newMaxLat] = bbox(feature);
          if (minLng === undefined || newMinLng < minLng) minLng = newMinLng;
          if (maxLng === undefined || newMaxLng > maxLng) maxLng = newMaxLng;
          if (minLat === undefined || newMinLat < minLat) minLat = newMinLat;
          if (maxLat === undefined || newMaxLat > maxLat) maxLat = newMaxLat;
        });
        map.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat]
          ],
          { padding: 65, duration: 500 }
        );
      } else {
        const distance = viewport.zoom + 1;
        const bounds = [
          [viewport.longitude - distance, viewport.latitude - distance],
          [viewport.longitude + distance, viewport.latitude + distance]
        ];
        map.fitBounds(bounds, { padding: 50, duration: 500 });
      }
    },
    [data.features, viewport.latitude, viewport.longitude, viewport.zoom]
  );

  useEffect(() => {
    if (mapRef.current && data) {
      fitBounds(mapRef.current);
      setInitialBoundsSet(true);
    }
  }, [fitBounds, data]);

  const mapRefWrapper = useCallback(
    (node) => {
      if (node) {
        if (data && !initialBoundsSet) {
          fitBounds(node);
          setInitialBoundsSet(true);
        }
      }
      mapRef.current = node;
    },
    [data, fitBounds, initialBoundsSet]
  );

  return (
    <Map
      ref={mapRefWrapper}
      reuseMaps
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      mapStyle={`mapbox://styles/hortplus/${mapStyle}`}
      initialViewState={viewport}
      onViewportChange={(nextViewport) => setViewport(nextViewport)}
      attributionControl={false}
    >
      <div
        className='m-3'
        style={{
          position: 'absolute',
          top: 0,
          right: 0
        }}
      >
        <Button
          variant='light'
          onClick={() => fitBounds(mapRef.current)}
          className='float-right'
        >
          Reset
        </Button>
      </div>
      <Source id='blocks' type='geojson' data={data}>
        {layers.map((layer) => (
          <Layer key={layer.id} {...layer} />
        ))}
      </Source>
      <PreviewButton
        setMapStyle={setMapStyle}
        mapStyle={mapStyle}
        viewport={viewport}
      />
    </Map>
  );
};

const ViewPolygonModal = ({ polygon, propertyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant='outline-secondary' size='sm' onClick={handleShow}>
        View Polygon
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{propertyName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col style={{ height: '400px' }}>
              <PolygonMap polygon={polygon} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewPolygonModal;

