import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import apm from '../../rum';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import '../layout/layout.scss';
import Navbar from '../navbar/Navbar';

export default class ErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    apm.captureError(error);
    // You can also log error messages to an error reporting service here
  }
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <>
          <Header hideMobileMenu />
          <Navbar useRegularLinks />
          <Container id='page-content' className='p-3' style={{ minHeight: '100vh' }}>
            <Row>
              <Col className='text-center'>
                <h2>Something went wrong.</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className='text-center'>
                  If you continue to have issues please{' '}
                  <a href='https://www.hortplus.com/support'>contact support</a>{' '}
                  and we will get back to you as soon as possible.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                {process.env.NODE_ENV === 'development' ? (
                  <details style={{ whiteSpace: 'pre-wrap' }}>
                    {this.state.error && this.state.error.toString()}
                    <br />
                    {this.state.errorInfo.componentStack}
                  </details>
                ) : null}
              </Col>
            </Row>
          </Container>
          <Footer />
        </>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

