import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import URI from 'urijs';
import { storesContext } from '../../stores/storesContext';
import PageTitle from '../helpers/PageTitle';
import LoadingOverlay from '../LoadingOverlay';
import { pdfTypes, reportTypes, scanTypes, stages } from './CropScanningUtils';
import GreenAtlasAdvertisement from './GreenAtlasAdvertisement';
import { GreenAtlasUrlButtons, useGreenAtlasUrls } from './GreenAtlasUrls';
import ReportsTable from './ReportsTable';

const CropScanningReports = () => {
  const userStore = useContext(storesContext);

  const formik = useFormik({
    initialValues: {
      block_code: '',
      scan_type: null,
      stage: '',
      report_type: '',
      pdf_type: ''
    },
    onSubmit: () => {
      if (userStore.propertiesStore.selectedPropertyId) loadReports();
    }
  });

  const [loadingBlocks, setLoadingBlocks] = useState(false);
  const [blocks, setBlocks] = useState([]);

  const [loadingReports, setLoadingReports] = useState(false);
  const [reports, setReports] = useState([]);
  const [showAdvertisement, setShowAdvertisement] = useState(null);

  const { gaUrls, loadingGaUrls } = useGreenAtlasUrls(
    userStore.propertiesStore.selectedProperty?.identifier,
    userStore.selectedDatabase,
    userStore.bearerToken
  );

  const loadReports = () => {
    setLoadingReports(true);
    const url = new URI(
      `${process.env.REACT_APP_PROPERTIES_API_URL}api/fruition/crop-scanning/reports`
    );
    url.addQuery('property_id', userStore.propertiesStore.selectedPropertyId);
    url.addQuery(
      'season',
      userStore.selectedDatabase ? userStore.selectedDatabase.substr(9) : null
    );
    if (formik.values.block_code)
      url.addQuery('block_code', formik.values.block_code.value);
    if (formik.values.scan_type)
      url.addQuery('scan_type', formik.values.scan_type.value);
    if (formik.values.stage) url.addQuery('stage', formik.values.stage.value);
    if (formik.values.report_type)
      url.addQuery('report_type', formik.values.report_type.value);
    if (formik.values.pdf_type)
      url.addQuery('pdf_type', formik.values.pdf_type.value);

    fetch(url.valueOf(), {
      headers: {
        Authorization: `Bearer ${userStore.bearerToken}`,
        Accept: 'application/json'
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw res.statusText;
      })
      .then((data) => {
        setReports(data['Objects']);
      })
      .catch((err) => {
        setReports([]);
      })
      .finally(() => {
        setLoadingReports(false);
      });
  };

  /**
   * Work out whether to show the advertisement by loading reports with no filters,
   * if there are no reports then show the advertisement.
   * Load the block
   */
  useEffect(() => {
    setReports([]);
    setBlocks([]);
    formik.resetForm();

    if (!userStore.propertiesStore.selectedPropertyId) return;

    // Load reports with no filters
    setLoadingBlocks(true);
    const url = new URI(
      `${process.env.REACT_APP_PROPERTIES_API_URL}api/fruition/crop-scanning/reports`
    );

    url.addQuery(
      'season',
      userStore.selectedDatabase ? userStore.selectedDatabase.substr(9) : null
    );
    url.addQuery('property_id', userStore.propertiesStore.selectedPropertyId);

    fetch(url.valueOf(), {
      headers: {
        Authorization: `Bearer ${userStore.bearerToken}`,
        Accept: 'application/json'
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw res.statusText;
      })
      .then((data) => {
        // Show the advertisement if there are no reports
        setShowAdvertisement(data['Objects'].length === 0);
        // Find all blocks for this property
        const newBlocks = [];
        data['Objects'].forEach((report) => {
          const parts = report['Key'].split('_');
          if (parts.length > 2 && !newBlocks.includes(parts[2]))
            newBlocks.push(parts[2]);
        });
        setBlocks(newBlocks);
      })
      .catch(() => {
        setBlocks([]);
        setShowAdvertisement(true);
      })
      .finally(() => {
        setLoadingBlocks(false);
      });

    // Load Reports
    formik.submitForm();
  }, [
    userStore.selectedDatabase,
    userStore.propertiesStore.selectedPropertyId
  ]);

  return (
    <div id='page-container'>
      <Row>
        <Col>
          <PageTitle title={'Green Atlas Reports'} />
        </Col>
      </Row>

      {userStore.propertiesStore.loading ? (
        <LoadingOverlay />
      ) : (
        <>
          <GreenAtlasUrlButtons urls={gaUrls} isLoading={loadingGaUrls} />
          <Row className='my-3'>
            <Col className='text-center'>
              {loadingReports ? (
                <Spinner variant='primary' animation='border' />
              ) : !showAdvertisement ? (
                <ReportsTable
                  data={reports.map((report) => {
                    const parts = report['Key'].split('_');

                    const dt = moment(parts[7], 'YYYYMMDD');
                    const scanType = scanTypes.find(
                      (s) => s.value === parts[3]
                    );
                    const stage = stages.find((s) => s.value === parts[4]);
                    const reportType = reportTypes.find(
                      (r) => r.value === parts[5]
                    );
                    const pdfType = pdfTypes.find((p) => p.value === parts[6]);

                    return {
                      key: report['Key'],
                      dt: dt.valueOf(),
                      blockCode:
                        parts[2] === '$$$$' ? 'Entire Orchard' : parts[2],
                      scanType: scanType ? scanType.label : null,
                      stage: stage ? stage.label : null,
                      reportType: reportType ? reportType.label : null,
                      pdfType: pdfType ? pdfType.label : null
                    };
                  })}
                  formik={formik}
                  blocks={blocks}
                  loadingBlocks={loadingBlocks}
                />
              ) : (
                <GreenAtlasAdvertisement
                  propertyName={
                    userStore.propertiesStore.selectedProperty
                      ? userStore.propertiesStore.selectedProperty.name
                      : null
                  }
                  season={
                    userStore.selectedDatabase
                      ? userStore.selectedDatabase.substr(9)
                      : null
                  }
                />
              )}
            </Col>
          </Row>
          <Row className='justify-content-center my-3'>
            <Col xs={10} sm={8} md={6} lg={5} xl={4}>
              <img
                alt='Green Atlas'
                src='https://fruition-metwatch-assets.s3-ap-southeast-2.amazonaws.com/green-atlas.png'
                className='w-100'
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default observer(CropScanningReports);

