import { faFile, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';

const Files = ({ files, isProcessing, handleFileRemove }) => {
  /**
   * Formats the file size in bytes to a more readable format.
   * @param {number} bytes - The file size in bytes.
   * @returns {string} The formatted file size.
   */
  const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <>
      {files.length === 0 ? (
        <Col className='mt-2 text-center text-muted'>No files uploaded</Col>
      ) : (
        <Col>
          {files.map((file) => {
            return (
              <Row
                key={file.name}
                className='border border-primary rounded px-1 py-2 mb-1'
                xs={11}
              >
                <Col>
                  <Row>
                    <Col
                      xs={1}
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center'
                      }}
                    >
                      <FontAwesomeIcon icon={faFile} size='2x' />
                    </Col>
                    <Col>
                      <Row>
                        <Col className='font-weight-bold'>{file.name}</Col>
                      </Row>
                      <Row>
                        <Col className='text-muted'>
                          {formatBytes(file.size)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  style={{ display: 'flex', justifyContent: 'right' }}
                  className='p-0'
                  xs={1}
                >
                  <Button
                    style={{ backgroundColor: 'white', borderColor: 'white' }}
                    variant='light'
                    onClick={() => {
                      handleFileRemove(file.name);
                    }}
                    disabled={isProcessing}
                  >
                    <FontAwesomeIcon as={'button'} icon={faTimes} size='lg' />
                  </Button>
                </Col>
              </Row>
            );
          })}
        </Col>
      )}
    </>
  );
};

Files.propTypes = {
  files: PropTypes.array.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  handleFileRemove: PropTypes.func.isRequired
};

export default Files;

