const downloadFile = (data) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Trap-Export.csv');
  document.body.appendChild(link);
  link.click();
};

export const fetchTrapCSV = async (userStore) => {
  const db = userStore.selectedDatabase;
  const bearerToken = userStore.bearerToken;

  const response = await fetch(
    process.env.REACT_APP_TRAPLOG_API_URL + 'api/admin/fruition/export/all',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
        Accept: 'application/json',
        Authorization: 'Bearer ' + bearerToken,
        SeasonalDatabase: db
      }
    }
  );
  // Request successful so download file.
  if (response.ok) {
    const data = await response.text();
    downloadFile(data);
    return true;
  } else {
    return false;
  }
};
