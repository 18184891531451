import { TraplogDashboard } from '@hortplus/traplog-react';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import PageTitle from '../helpers/PageTitle';
import IrrigationDashboard from '../irrigation/IrrigationDashboard';

const ClientDashboard = () => {
  const userStore = useContext(storesContext);
  const history = useHistory();
  const [display, setDisplay] = useState('trapping');

  const goToTrapping = async (propertyId) => {
    await userStore.propertiesStore.selectProperty(
      propertyId,
      userStore.selectedDatabase,
      userStore.bearerToken,
      userStore.refreshCallback
    );
    history.push('/pest-disease/trapping-overview');
  };

  useEffect(() => {
    if (localStorage.getItem('selectedDashboardTab')) {
      setDisplay(localStorage.getItem('selectedDashboardTab'));
    }
  }, []);

  const dashboardDisplay = (display) => {
    switch (display) {
      case 'trapping':
        return (
          <TraplogDashboard
            selectedDatabase={userStore.selectedDatabase}
            bearerToken={userStore.bearerToken}
            refreshBearerToken={userStore.refresh}
            propertiesStore={userStore.propertiesStore}
            goToTrapping={goToTrapping}
          />
        );
      case 'irrigation':
        return (
          <IrrigationDashboard
            properties={userStore.propertiesStore.properties}
            loading={userStore.propertiesStore.loading && userStore.loading}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <Container className='p-0'>
      <Row className='mb-4'>
        <Col>
          <PageTitle title={'Monitoring Map'} />
        </Col>
      </Row>
      <Container className='p-0 d-flex justify-content-center mb-2'>
        <Button
          variant={display === 'trapping' ? 'primary' : 'light'}
          onClick={() => {
            localStorage.setItem('selectedDashboardTab', 'trapping');
            setDisplay(localStorage.getItem('selectedDashboardTab'));
          }}
          className='mr-2'
        >
          Trapping
        </Button>
        <Button
          variant={display === 'irrigation' ? 'primary' : 'light'}
          onClick={() => {
            localStorage.setItem('selectedDashboardTab', 'irrigation');
            setDisplay(localStorage.getItem('selectedDashboardTab'));
          }}
        >
          Irrigation
        </Button>
      </Container>
      {dashboardDisplay(display)}
    </Container>
  );
};

export default observer(ClientDashboard);

