import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { storesContext } from '../../../stores/storesContext';
import LoadingOverlay from '../../LoadingOverlay';
import PageTitle from '../../helpers/PageTitle';
import IrrigationLegend from '../IrrigationLegend';
import { withIrrigationSummaries } from '../IrrigationProviders';
import {
  CalculateSiteSoilMoisture,
  SummaryBootstrapColorClass
} from '../helpers';

const IrrigationSummaryTable = withIrrigationSummaries((props) => {
  const userStore = useContext(storesContext);

  const handleClick = (propertyId, irrigationMonitoringSiteId) => {
    userStore.propertiesStore.selectProperty(
      propertyId,
      userStore.selectedDatabase,
      userStore.bearerToken,
      userStore.refreshCallback
    );
    localStorage.setItem(
      'irrigation_monitoring_site_id',
      irrigationMonitoringSiteId
    );

    const newWindow = window.open(
      '/soil-water/monitoring',
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <Row className='mb-3'>
        <Col className='text-center'>
          <IrrigationLegend />
        </Col>
      </Row>

      {/** Table for each property */}
      {props.propertySummaries.map((propertyName) => {
        if (!propertyName.results || propertyName.results.sites.length === 0)
          return null;
        return (
          <div style={{ overflowX: 'auto' }}>
            <Table bordered key={propertyName.property.id}>
              <thead>
                <tr>
                  <th colSpan='100%'>{propertyName.property.name}</th>
                </tr>
                <tr>
                  <th colSpan={1} style={{ width: '15%' }}>
                    Site Name
                  </th>
                  <th colSpan={1} style={{ width: '10%' }}>
                    Level in profile
                  </th>
                  <th colSpan={1} style={{ width: '15%' }}>
                    Last Monitored
                  </th>
                  <th colSpan={1} style={{ width: '20%' }}>
                    Comments
                  </th>
                  <th colSpan={1} style={{ width: '10%' }}>
                    Irrigation Applied
                  </th>
                  <th colSpan={1} style={{ width: '10%' }}>
                    Irrigation Efficiency
                  </th>
                  <th colSpan={1} style={{ width: '10%' }}>
                    Rain
                  </th>
                  <th colSpan={1} style={{ width: '10%' }}>
                    Rootzone
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.propertySummaries.flatMap((propertySummary) => {
                  if (
                    propertyName.property.name !== propertySummary.property.name
                  ) {
                    return null;
                  }
                  return propertySummary.results
                    ? propertySummary.results.sites.map((siteResults) => {
                        const irrigationMonitoringSite =
                          propertySummary.property.irrigation_monitoring_sites.find(
                            (site) =>
                              Number(site.site_number) === siteResults.site_id
                          );
                        if (irrigationMonitoringSite) {
                          return (
                            <tr key={irrigationMonitoringSite.id}>
                              <td
                                className={`bg-${SummaryBootstrapColorClass(
                                  siteResults
                                )} print-text-${SummaryBootstrapColorClass(
                                  siteResults
                                )}`}
                              >
                                <div className='px-0 d-flex justify-content-between'>
                                  {irrigationMonitoringSite.site_name}
                                  <Button
                                    variant={'link'}
                                    onClick={() =>
                                      handleClick(
                                        propertyName.property.id,
                                        irrigationMonitoringSite.id
                                      )
                                    }
                                    className='text-dark'
                                  >
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                  </Button>
                                </div>
                              </td>
                              <td
                                className={`bg-${SummaryBootstrapColorClass(
                                  siteResults
                                )} print-text-${SummaryBootstrapColorClass(
                                  siteResults
                                )}`}
                              >
                                {Math.round(
                                  CalculateSiteSoilMoisture(siteResults) * 100
                                )}
                                %
                              </td>
                              <td>
                                {moment(
                                  siteResults.latest_reading.date,
                                  'DD-MM-YYYY'
                                ).format('Do MMM YYYY')}
                              </td>
                              <td>{siteResults.latest_reading.comment}</td>
                              <td>
                                {siteResults.latest_reading.irrigation_mms !==
                                null
                                  ? `${parseFloat(
                                      siteResults.latest_reading.irrigation_mms.toFixed(
                                        1
                                      )
                                    )} mm`
                                  : 'N/A'}
                              </td>
                              <td
                                className={`bg-${SummaryBootstrapColorClass(
                                  siteResults
                                )} print-text-${SummaryBootstrapColorClass(
                                  siteResults
                                )}`}
                              >
                                {siteResults.latest_reading
                                  .effective_irrigation !== null &&
                                siteResults.latest_reading.irrigation_mms !==
                                  null &&
                                siteResults.latest_reading.irrigation_mms > 0
                                  ? `${Math.round(
                                      ((siteResults.latest_reading
                                        .effective_irrigation *
                                        1.0) /
                                        siteResults.latest_reading
                                          .irrigation_mms) *
                                        100
                                    )}%`
                                  : 'N/A'}
                              </td>
                              <td>
                                {siteResults.latest_reading.rain !== null
                                  ? `${siteResults.latest_reading.rain} mm`
                                  : 'N/A'}
                              </td>
                              <td>
                                0 - {siteResults.latest_reading.rz1_bottom} cm
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })
                    : null;
                })}
              </tbody>
            </Table>
          </div>
        );
      })}
    </>
  );
});

function IrrigationSummary() {
  const userStore = useContext(storesContext);
  const [loadingPropertyGroups, setLoadingPropertyGroups] = useState(false);
  const [propertyGroups, setPropertyGroups] = useState([]);
  const [selectedPropertyGroupId, setSelectedPropertyGroupId] = useState(null);
  const [loadingSelectedPropertyGroup, setLoadingSelectedPropertyGroup] =
    useState(null);
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState(null);

  // Load property groups
  useEffect(() => {
    // Clear the selected property group so the HOC doesn't try using the prev
    // seasons properties
    setSelectedPropertyGroupId(null);
    setSelectedPropertyGroup(null);

    // Load the new seasons property groups
    setLoadingPropertyGroups(true);
    fetch(`${process.env.REACT_APP_PROPERTIES_API_URL}api/property-groups`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        SeasonalDatabase: userStore.selectedDatabase,
        Authorization: `Bearer ${userStore.bearerToken}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) userStore.refresh();
        else throw Error(res.statusText);
      })
      .then((data) => {
        if (data) {
          setPropertyGroups(data);
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
        setPropertyGroups([]);
      })
      .finally(() => {
        setLoadingPropertyGroups(false);
      });
  }, [userStore, userStore.selectedDatabase]);

  // Load the selected property group with required relations
  useEffect(() => {
    if (selectedPropertyGroupId !== null) {
      setLoadingSelectedPropertyGroup(true);
      fetch(
        `${process.env.REACT_APP_PROPERTIES_API_URL}api/property-groups/${selectedPropertyGroupId}?with[]=properties.irrigationMonitoringSites`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            SeasonalDatabase: userStore.selectedDatabase,
            Authorization: `Bearer ${userStore.bearerToken}`
          }
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else if (res.status === 401) userStore.refresh();
          else throw Error(res.statusText);
        })
        .then((data) => {
          if (data) {
            setSelectedPropertyGroup(data);
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV === 'development') console.error(err);
          setSelectedPropertyGroup(null);
        })
        .finally(() => {
          setLoadingSelectedPropertyGroup(false);
        });
    }
  }, [selectedPropertyGroupId, userStore]);

  return (
    <Fragment>
      <Row>
        <Col>
          <PageTitle title='Seasonal Summary Report' />
        </Col>
      </Row>
      <Row className='justify-content-center mb-3 mt-3'>
        <Col
          xs={3}
          sm={2}
          className='d-flex align-items-center justify-content-end pr-0 font-weight-bold'
        >
          Property Group:
        </Col>
        <Col xs={9} md={8} lg={6}>
          <Select
            options={propertyGroups
              .filter(
                (propertyGroup) =>
                  !propertyGroup.deleted_at &&
                  propertyGroup.group_type === 'manual'
              )
              .map((propertyGroup) => {
                return {
                  label: propertyGroup.name,
                  value: propertyGroup.id
                };
              })}
            onChange={(option) => setSelectedPropertyGroupId(option.value)}
            value={
              selectedPropertyGroup
                ? {
                    value: selectedPropertyGroup.id,
                    label: selectedPropertyGroup.name
                  }
                : ''
            }
            isLoading={loadingPropertyGroups}
          />
        </Col>
        <Col
          xs={0}
          sm={1}
          className='d-flex align-items-center justify-content-end pl-0 font-weight-bold'
          style={{ visibility: 'hidden' }}
        >
          Property Group:
        </Col>
      </Row>
      {selectedPropertyGroup ? (
        <IrrigationSummaryTable properties={selectedPropertyGroup.properties} />
      ) : null}
      {loadingSelectedPropertyGroup ? <LoadingOverlay /> : null}
    </Fragment>
  );
}

export default observer(IrrigationSummary);

