import { PropertiesStore } from '@hortplus/properties-react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import {
  errorToast,
  infoToast,
  successToast,
  warningToast
} from '../components/helpers/toasts/ToastUtils';

export default class UserStore {
  loading = false;
  user = null;
  accessArray = null;
  selectedDatabase = null; // fruition_{year}
  selectedDatabaseLabel = null; // {year}
  bearerToken = null;
  refreshToken = null;
  propertiesStore = null;

  constructor() {
    this.propertiesStore = new PropertiesStore(this);
    makeObservable(this, {
      loading: observable,
      user: observable,
      selectedDatabase: observable,
      bearerToken: observable,
      refreshToken: observable,
      propertiesStore: observable,
      setSelectedDatabase: action,
      login: action,
      logout: action,
      updateUser: action,
      loggedIn: computed,
      isAdmin: computed,
      refreshCallback: computed,
      currentSeasonIsSelected: computed,
      selectedDatabaseStartYear: computed,
      selectedDatabaseStopYear: computed
    });

    if (!this.user) {
      let cookieRefreshToken = Cookies.get('refreshToken');
      let cookieRemember = Cookies.get('remember');

      // On page load refresh the bearer token. This solves the problem of using a
      // token until it expires, causing a refresh which can make the state get
      // reset which is annoying to the user.
      if (
        cookieRefreshToken &&
        (cookieRemember ||
          (window.performance && performance.navigation.type === 1))
      ) {
        this.refreshToken = cookieRefreshToken;
        this.refresh();
      }
    }
  }

  /**
   * Finds which season to select for the user.
   * Attempts to find the selected season in storage if there is none
   * it will attempt to find the most recent accessible season
   * (assumes the databases array is ordered).
   *
   * The season is from June to May.
   */
  initialiseSeason() {
    if (this.accessArray && this.loggedIn) {
      let season =
        this.accessArray.properties.databases[
          this.accessArray.properties.databases.length - 1
        ];

      if (localStorage.getItem('database')) {
        const selectedSeason = this.accessArray.properties.databases.find(
          (db) => db.value === localStorage.getItem('database')
        );
        if (selectedSeason) season = selectedSeason;
      }

      this.selectedDatabaseLabel = season ? season.label : undefined;
      this.selectedDatabase = season ? season.value : undefined;
    }
  }

  /**
   * @param {object} season
   */
  setSelectedDatabase(season) {
    localStorage.setItem('database', season.value);
    this.selectedDatabase = season.value;
    this.selectedDatabaseLabel = season.label;
  }

  /**
   * Attempts to fetch the user model from the authentication API.
   * If successful then all properties and cookies are set, otherwise
   * the user is logged out.
   *
   * @param {string} bearerToken
   * @param {string} refreshToken
   * @param {boolean} remember
   */
  login(bearerToken, refreshToken, remember) {
    this.loading = true;

    fetch(process.env.REACT_APP_AUTH_API_URL + 'api/user', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + bearerToken
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) this.refresh();
        else throw Error(res.statusText);
      })
      .then((data) => {
        if (data) {
          Cookies.set('bearerToken', bearerToken, { expires: 365 });
          Cookies.set('refreshToken', refreshToken, { expires: 365 });
          Cookies.set('remember', remember, { expires: 365 });
          this.bearerToken = bearerToken;
          this.refreshToken = refreshToken;
          this.user = data;
          const jwtPayload = jwt_decode(bearerToken);
          this.accessArray = jwtPayload.access;
          this.loading = false;
          this.initialiseSeason();
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
        this.logout();
      });
  }

  /**
   * Attempt to refresh the bearer token using the refresh token,
   * if unsuccessful then logout.
   */
  refresh() {
    this.loading = true;

    if (this.refreshToken) {
      fetch(process.env.REACT_APP_AUTH_API_URL + 'api/token/jwt/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          service: 'fruition'
        },
        body: JSON.stringify({
          refresh_token: this.refreshToken
        })
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error(res.statusText);
        })
        .then((data) => {
          if (data) {
            // User is already defined
            if (this.user) {
              this.bearerToken = data.jwt_token;
              this.refreshToken = data.refresh_token;
              const jwtPayload = jwt_decode(data.jwt_token);
              this.accessArray = jwtPayload.access;
              this.loading = false;
            }

            // User is not defined
            else {
              this.login(
                data.jwt_token,
                data.refresh_token,
                Cookies.get('remember')
              );
            }
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV === 'development') console.error(err);
          this.logout();
        });
    } else {
      this.logout();
    }
  }

  /**
   * Clear all properties and cookies.
   */
  logout() {
    this.loading = false;
    this.user = undefined;
    this.bearerToken = undefined;
    this.refreshToken = undefined;
    this.accessArray = undefined;
    this.propertiesStore.clearStore();
    Cookies.remove('bearerToken');
    Cookies.remove('refreshToken');
    Cookies.remove('remember');

    this.selectedDatabase = undefined;
    this.selectedDatabaseLabel = undefined;
    localStorage.removeItem('database');
  }

  updateUser(newUser) {
    this.user = newUser;
  }

  /**
   * Can be used by our private NPM packages to trigger react-toastify notifications
   */
  toast(message, toastType = 'info') {
    switch (toastType) {
      default:
      case 'info':
        infoToast(message);
        break;
      case 'success':
        successToast(message);
        break;
      case 'warning':
        warningToast(message);
        break;
      case 'error':
        errorToast(message);
        break;
    }
  }

  get loggedIn() {
    return this.user ? true : false;
  }

  get isAdmin() {
    return this.user ? this.user.role.name === 'Staff' : false;
  }

  get refreshCallback() {
    return this.refresh.bind(this);
  }

  /**
   * @returns {boolean}
   */
  get currentSeasonIsSelected() {
    const now = moment();
    const year = now.month() >= 5 ? now.year() + 1 : now.year();
    return (
      this.selectedDatabase &&
      parseInt(this.selectedDatabase.substr(9)) === year
    );
  }

  /**
   * Selected database format is fruition_{year} where year is the stop year.
   * Fruition's seasons span two years.
   *
   * Extracts the year part from the selectedDatabase and subtracts one.
   *
   * @returns {int}
   */
  get selectedDatabaseStartYear() {
    return this.selectedDatabase
      ? parseInt(this.selectedDatabase.substr(9)) - 1
      : undefined;
  }

  /**
   * Extracts the year part from the selectedDatabase.
   *
   * @returns {int}
   */
  get selectedDatabaseStopYear() {
    return this.selectedDatabase
      ? parseInt(this.selectedDatabase.substr(9))
      : undefined;
  }

  get tempAwsCredentials() {
    return this.accessArray ? this.accessArray.tempAwsCredentials : undefined;
  }
}

