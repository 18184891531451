import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { storesContext } from '../../stores/storesContext';
import AdminDashboard from './AdminDashboard';
import ClientDashboard from './ClientDashboard';

function Dashboard() {
  const userStore = useContext(storesContext);
  return userStore.isAdmin ? <AdminDashboard /> : <ClientDashboard />;
}

export default observer(Dashboard);
