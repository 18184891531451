import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import Select, { components } from 'react-select';
import { storesContext } from '../../stores/storesContext';

const CustomControl = ({ children, ...props }) => {
  return (
    <div title={props.title}>
      <components.Control {...props}>{children}</components.Control>
    </div>
  );
};

const GlobalSeasonSelect = ({ showConfirm }) => {
  const userStore = useContext(storesContext);

  const val = useMemo(
    () => ({
      value: userStore.selectedDatabase,
      label: userStore.selectedDatabaseLabel
    }),
    [userStore.selectedDatabase, userStore.selectedDatabaseLabel]
  );

  const controlTitle = userStore.loading
    ? 'loading...'
    : userStore.selectedDatabaseLabel ?? undefined;

  return (
    <Select
      components={{
        Control: (props) => <CustomControl {...props} title={controlTitle} />
      }}
      loading={userStore.loading}
      options={
        userStore.accessArray ? userStore.accessArray.properties.databases : []
      }
      onChange={(option) => {
        if (
          !showConfirm ||
          window.confirm(
            'Changing season will allow you to view the records from that season, the top banner will go grey indicating you are not viewing the current season on all tools.\n\nAre you sure you want to change the season?'
          )
        )
          userStore.setSelectedDatabase(option);
      }}
      value={val}
      styles={{
        container: (provided) => ({
          ...provided,
          width: '100%',
          zIndex: 2
        }),
        menu: (provided) => ({
          ...provided,
          width: 'max-content',
          minWidth: '100%'
        })
      }}
    />
  );
};

GlobalSeasonSelect.propTypes = {
  showConfirm: PropTypes.bool
};

export default observer(GlobalSeasonSelect);

