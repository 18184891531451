import { GlobalPropertiesSelect } from '@hortplus/properties-react';
import isMobile from 'ismobilejs';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import GlobalSeasonSelect from '../helpers/GlobalSeasonSelect';
import MobileHeader from './MobileHeader';
import './header.scss';

function Header(props) {
  const userStore = useContext(storesContext);

  let title = '';
  if (userStore.user) {
    switch (userStore.user.role.name) {
      default:
      case 'Client':
        title = 'Technical Services';
        break;
      case 'Staff':
        title = 'Staff';
        break;
    }
  }

  const displaySeasonSelect =
    userStore.accessArray && userStore.loggedIn && userStore.isAdmin;

  return isMobile(window.navigator).any ? (
    <MobileHeader hideMobileMenu={props.hideMobileMenu} />
  ) : (
    <Container
      fluid
      className={`header ${
        !userStore.loggedIn || userStore.currentSeasonIsSelected
          ? 'bg-fruition'
          : 'bg-secondary'
      } `}
    >
      <Row className='header_inner'>
        <Col className='header_logo p-0' xs={3}>
          <div className='header_logo_inner'>
            <Link to='/home'>
              <img
                className='w-100'
                src='https://fruition-metwatch-assets.s3-ap-southeast-2.amazonaws.com/fruition-logo.png'
                alt='Fruition Portal'
              />
            </Link>
          </div>
        </Col>
        <Col className='text-center p-0' xs={5}>
          {window.location.pathname === '/account' ? (
            <Row className='h5 text-light my-0'>
              <Col>{title}</Col>
            </Row>
          ) : (
            <div className='h5 text-light my-0'>{title}</div>
          )}
        </Col>
        <Col className='p-0' xs={4}>
          {window.location.pathname !== '/account' ? (
            <Row className='m-0'>
              {props.usePropertiesSelecter ? (
                <Col xs={displaySeasonSelect ? 7 : 12} className='p-0 pr-1'>
                  <GlobalPropertiesSelect
                    selectedDatabase={userStore.selectedDatabase}
                    bearerToken={userStore.bearerToken}
                    refreshBearerToken={userStore.refresh}
                    propertiesStore={userStore.propertiesStore}
                    useRpin
                  />
                </Col>
              ) : null}
              {displaySeasonSelect ? (
                <Col
                  xs={props.usePropertiesSelecter ? 5 : 12}
                  className='p-0 pl-1'
                >
                  <GlobalSeasonSelect />
                </Col>
              ) : null}
            </Row>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

Header.propTypes = {
  usePropertiesSelecter: PropTypes.bool,
  hideMobileMenu: PropTypes.bool
};

export default observer(Header);

