import { useMemo } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import ViewPolygonModal from './ViewPolygonModal';

const UnmatchedPolygons = ({ unmatchedPolygons }) => {
  const polygons = useMemo(
    () =>
      unmatchedPolygons.sort((a, b) => {
        const metadataA = JSON.parse(a.primary_ga_block.metadata);
        const identifierA = metadataA?.details?.client;
        const metadataB = JSON.parse(b.primary_ga_block.metadata);
        const identifierB = metadataB?.details?.client;
        if (identifierA < identifierB) {
          return -1;
        }
        if (identifierA > identifierB) {
          return 1;
        }
        return 0;
      }),
    [unmatchedPolygons]
  );

  const propertiesToAdd = useMemo(
    () =>
      polygons.reduce((prev, curr, index, array) => {
        if (index === 0) return 0;
        const prevMetadata = JSON.parse(
          array[index - 1].primary_ga_block.metadata
        );
        const currMetadata = JSON.parse(curr.primary_ga_block.metadata);
        const prevIdentifier = prevMetadata?.details?.client;
        const currIdentifier = currMetadata?.details?.client;
        if (prevIdentifier !== currIdentifier) {
          return prev + 1;
        } else {
          return prev;
        }
      }, 0),
    [polygons]
  );

  return (
    <>
      <Alert variant='warning'>
        <Row>
          <Col>
            <h5>Unmatched Polygons Found</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Some polygons could not be matched to a property. You can view the
              polygons that could not be matched, and create the property for
              that polygon by clicking the 'Create Property' button.
            </p>
            <p className='mb-0'>
              There are <b>{propertiesToAdd}</b> properties to add.
            </p>
          </Col>
        </Row>
      </Alert>
      <Row className='mx-0 mt-1'>
        <Col>
          {polygons.map((cluster) => {
            const metadata = JSON.parse(cluster.primary_ga_block.metadata);
            const name = metadata?.details?.orchard;
            const identifier = metadata?.details?.client;
            const displayName = `${name} (${identifier})`;
            const geom = cluster.primary_ga_block.geom;
            const polygonGeojson = {
              type: 'Feature',
              geometry: geom,
              properties: metadata
            };
            return (
              <Row
                key={cluster.id}
                className='border border-secondary rounded mb-1 px-1 py-2'
              >
                <Col
                  xs={12}
                  sm={9}
                  className='d-flex align-items-center font-weight-bold px-0 pl-2 py-1'
                >
                  <div className='mr-3'>{displayName}</div>
                  <ViewPolygonModal
                    polygon={polygonGeojson}
                    propertyName={displayName}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={3}
                  className='d-flex align-items-center justify-content-end px-0 pr-2'
                >
                  <Button
                    as='a'
                    variant='primary'
                    size='sm'
                    href={`/management/properties?identifier=${encodeURIComponent(
                      identifier
                    )}&name=${encodeURIComponent(
                      name
                    )}&redirect=/precision-hort/block-scanning/upload`}
                  >
                    Create Property
                  </Button>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default UnmatchedPolygons;

