import {
  Properties,
  PropertyGroups,
  PropertyKeyContacts
} from '@hortplus/properties-react';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import useQuery from '../helpers/hooks/useQuery';
import PageTitle from '../helpers/PageTitle';

export const PropertiesPage = observer(function PropertiesPage() {
  const history = useHistory();
  const { id } = useParams();
  const userStore = useContext(storesContext);
  const query = useQuery();

  const newPropertyIdentifier = query.get('identifier');
  const newPropertyName = query.get('name');
  const redirect = query.get('redirect');

  const linkTo = (url) => {
    history.push(url);
  };

  const changeSelectedProperty = (newId) => {
    if (Number.isInteger(newId)) {
      if (redirect) {
        history.push(`/management/properties/${newId}?redirect=${redirect}`);
      } else {
        history.push(`/management/properties/${newId}`);
      }
    } else {
      if (redirect) {
        history.push(redirect);
      } else {
        history.push('/management/properties');
      }
    }
  };

  return (
    <div id='page-container'>
      <Row>
        <Col>
          <PageTitle title={'Properties'} />
        </Col>
      </Row>
      <Properties
        selectedDatabase={userStore.selectedDatabase}
        bearerToken={userStore.bearerToken}
        refreshBearerToken={userStore.refreshCallback}
        propertiesStore={userStore.propertiesStore}
        selectedPropertyId={parseInt(id)}
        changeSelectedProperty={changeSelectedProperty}
        history={history}
        linkTo={linkTo}
        authService={'fruition'}
        newProperty={{
          data: {
            identifier: newPropertyIdentifier,
            name: newPropertyName
          },
          redirect
        }}
      />
    </div>
  );
});

export const PropertyGroupsPage = observer(function PropertiesPage() {
  const history = useHistory();
  const { id } = useParams();
  const userStore = useContext(storesContext);

  const changeSelectedPropertyGroup = (newId) => {
    if (Number.isInteger(newId))
      history.push('/management/property-groups/' + newId);
    else history.push('/management/property-groups');
  };

  return (
    <div id='page-container'>
      <PageTitle title={'Property Groups'} />
      <PropertyGroups
        bearerToken={userStore.bearerToken}
        refreshBearerToken={userStore.refreshCallback}
        selectedDatabase={userStore.selectedDatabase}
        selectedPropertyGroupId={parseInt(id)}
        changeSelectedPropertyGroup={changeSelectedPropertyGroup}
        history={history}
        authService={'fruition'}
      />
    </div>
  );
});

export const PropertyKeyContactsPage = observer(
  function PropertyKeyContactsPage() {
    const history = useHistory();
    const { id } = useParams();
    const userStore = useContext(storesContext);

    const changeSelectedPropertyKeyContact = (newId) => {
      if (Number.isInteger(newId))
        history.push('/property-key-contacts/' + newId);
      else history.push('/management/properties');
    };

    const updatePropertyKeyContact = (propertyKeyContact) => {
      userStore.propertiesStore.properties.forEach((p) => {
        if (p.property_key_contact_id === propertyKeyContact.id) {
          p.property_key_contact = propertyKeyContact;
        }
      });
    };

    return (
      <div id='page-container'>
        <PropertyKeyContacts
          bearerToken={userStore.bearerToken}
          refreshBearerToken={userStore.refreshCallback}
          selectedDatabase={userStore.selectedDatabase}
          selectedPropertyKeyContactId={parseInt(id)}
          changeSelectedPropertyKeyContact={changeSelectedPropertyKeyContact}
          updatePropertyKeyContact={updatePropertyKeyContact}
        />
      </div>
    );
  }
);

