import {
  faArrowUpRightFromSquare,
  faCog,
  faHome
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Navbar as BootstrapNavbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import './mobile-menu.css';
import './navbar.scss';

function NavLink(props) {
  if (props.useRegularLinks) {
    return (
      <Nav.Link href={props.to} className={props.active ? 'active' : ''}>
        {props.children}
      </Nav.Link>
    );
  } else {
    return (
      <Nav.Link
        as={Link}
        to={props.to}
        className={props.active ? 'active' : ''}
      >
        {props.children}
      </Nav.Link>
    );
  }
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  useRegularLinks: PropTypes.bool,
  active: PropTypes.bool
};

function NavDropDownItem(props) {
  if (props.useRegularLinks) {
    return (
      <NavDropdown.Item href={props.to}>{props.children}</NavDropdown.Item>
    );
  } else {
    return (
      <NavDropdown.Item as={Link} to={props.to}>
        {props.children}
      </NavDropdown.Item>
    );
  }
}

NavDropDownItem.propTypes = {
  to: PropTypes.string.isRequired,
  useRegularLinks: PropTypes.bool
};

function Navbar(props) {
  const userStore = useContext(storesContext);
  const [activeMenuItem, setActiveMenuItem] = useState('');

  const location = useLocation();

  useEffect(() => {
    const items = [
      {
        name: 'management',
        regex: '/users*'
      },
      {
        name: 'management',
        regex: '/properties*'
      },
      {
        name: 'management',
        regex: '/property-groups*'
      },
      {
        name: 'soil-water',
        regex: '/monitoring'
      },
      {
        name: 'soil-water',
        regex: '/seasonal-summary-report'
      },
      {
        name: 'soil-water',
        regex: '/reports'
      },
      {
        name: 'pomology',
        regex: '/calculators'
      },
      {
        name: 'precision-hort',
        regex: '/reports'
      },
      {
        name: 'precision-hort',
        regex: '/block-scanning/upload'
      },
      {
        name: 'precision-hort',
        regex: '/block-scanning-reports'
      },
      {
        name: 'pest-disease',
        regex: '/trapping-overview'
      },
      {
        name: 'pest-disease',
        regex: '/hot-trap-map'
      },
      {
        name: 'pest-disease',
        regex: '/seasonal-trapping-report'
      },
      {
        name: 'pest-disease',
        regex: '/field-reports'
      },
      {
        name: 'pest-disease',
        regex: '/trapping-data-entry'
      },
      {
        name: 'pest-disease',
        regex: '/trapping-runs'
      },
      {
        name: 'pest-disease',
        regex: '/weekly-trapping-report'
      },
      {
        name: 'pest-disease',
        regex: '/missing-trapping-report'
      },
      {
        name: 'account',
        regex: '/account*'
      },
      {
        name: 'account',
        regex: '/change-password'
      },
      {
        name: 'dashboard',
        regex: '/*'
      }
    ];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const regExp = new RegExp(item.regex);
      if (regExp.test(location.pathname)) {
        setActiveMenuItem(item.name);
        break;
      }
    }
  }, [location]);

  // Admin navbar
  return userStore.loggedIn ? (
    <BootstrapNavbar bg='light' expand='md' className='p-0'>
      <BootstrapNavbar.Toggle aria-controls='basic-navbar-nav' />
      <BootstrapNavbar.Collapse id='basic-navbar-nav'>
        <Nav className='m-auto'>
          <NavLink
            to='/'
            active={activeMenuItem === 'dashboard'}
            useRegularLinks={props.useRegularLinks}
          >
            <FontAwesomeIcon icon={faHome} /> Dashboard
          </NavLink>

          {/* Management Menu */}
          {userStore.isAdmin && (
            <NavDropdown
              title={<Fragment>Management</Fragment>}
              className={activeMenuItem === 'management' ? 'active' : ''}
            >
              <NavDropDownItem
                to='/management/users'
                active={activeMenuItem === 'users'}
                useRegularLinks={props.useRegularLinks}
              >
                Users
              </NavDropDownItem>
              <NavDropDownItem
                to='/management/properties'
                useRegularLinks={props.useRegularLinks}
              >
                Properties
              </NavDropDownItem>
              <NavDropDownItem
                to='/management/property-groups'
                useRegularLinks={props.useRegularLinks}
              >
                Property Groups
              </NavDropDownItem>
            </NavDropdown>
          )}

          {/* Soil & Water Menu */}
          <NavDropdown
            title={<Fragment>Soil & Water</Fragment>}
            className={activeMenuItem === 'soil-water' ? 'active' : ''}
          >
            <NavDropdown.Item
              href='https://www.fruitionhort.com/soil-water'
              target='new'
              active={false}
            >
              Information{' '}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='sm' />
            </NavDropdown.Item>
            <NavDropDownItem
              to='/soil-water/monitoring'
              useRegularLinks={props.useRegularLinks}
            >
              Monitoring
            </NavDropDownItem>
            <NavDropDownItem
              to='/soil-water/seasonal-summary-report'
              useRegularLinks={props.useRegularLinks}
            >
              Seasonal Summary Report
            </NavDropDownItem>
            {/* <NavDropDownItem
                to='/soil-water/reports'
                useRegularLinks={props.useRegularLinks}
              >
                Reports
              </NavDropDownItem> */}

            {userStore.isAdmin && (
              <>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href={`${process.env.REACT_APP_TERRAPROBE_API_URL}`}
                  target='new'
                  active={false}
                >
                  Terraprobe{' '}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='sm' />
                </NavDropdown.Item>
              </>
            )}
          </NavDropdown>

          {/* Pomology Menu */}
          <NavDropdown
            title={<Fragment>Pomology</Fragment>}
            className={activeMenuItem === 'pomology' ? 'active' : ''}
          >
            <NavDropdown.Item
              href='https://www.fruitionhort.com/pomology'
              target='new'
              active={false}
            >
              Information{' '}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='sm' />
            </NavDropdown.Item>
            {/* <NavDropDownItem
              to='/pomology/calculators'
              useRegularLinks={props.useRegularLinks}
            >
              Calculators
            </NavDropDownItem> */}
          </NavDropdown>

          {/* Precision Hort Menu */}
          <NavDropdown
            title={<Fragment>Precision Hort</Fragment>}
            className={activeMenuItem === 'precision-hort' ? 'active' : ''}
          >
            <NavDropdown.Item
              href='https://www.fruitionhort.com/precision-hort'
              target='new'
              active={false}
            >
              Information{' '}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='sm' />
            </NavDropdown.Item>
            <NavDropDownItem
              to='/precision-hort/block-scanning-reports'
              active={activeMenuItem === 'precision-hort'}
              useRegularLinks={props.useRegularLinks}
            >
              Block Scanning Reports
            </NavDropDownItem>
            {/* <NavDropDownItem
              to='/precision-hort/calculators'
              useRegularLinks={props.useRegularLinks}
            >
              Calculators
            </NavDropDownItem> */}
            {userStore.isAdmin && (
              <>
                <NavDropdown.Divider />
                <NavDropDownItem
                  to='/precision-hort/block-scanning/upload'
                  active={activeMenuItem === 'precision-hort'}
                  useRegularLinks={props.useRegularLinks}
                >
                  Block Scanning Upload
                </NavDropDownItem>
                <NavDropDownItem
                  to='/precision-hort/ga-viewer-upload'
                  active={activeMenuItem === 'precision-hort'}
                  useRegularLinks={props.useRegularLinks}
                >
                  Green Atlas Bundle Upload
                </NavDropDownItem>
              </>
            )}
          </NavDropdown>

          {/* Pest & Disease Menu */}
          <NavDropdown
            title={<Fragment>Pest & Disease</Fragment>}
            className={activeMenuItem === 'pest-disease' ? 'active' : ''}
          >
            <NavDropdown.Item
              href='https://www.fruitionhort.com/pest-disease'
              target='new'
              active={false}
            >
              Information{' '}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='sm' />
            </NavDropdown.Item>
            <NavDropDownItem
              to='/pest-disease/trapping-overview'
              useRegularLinks={props.useRegularLinks}
            >
              Trapping Overview
            </NavDropDownItem>
            <NavDropDownItem
              to='/pest-disease/hot-trap-map'
              useRegularLinks={props.useRegularLinks}
            >
              Hot Trap Map
            </NavDropDownItem>
            <NavDropDownItem
              to='/pest-disease/seasonal-trapping-report'
              useRegularLinks={props.useRegularLinks}
            >
              Season Overview
            </NavDropDownItem>
            {/* <NavDropDownItem
              to='/pest-disease/field-reports'
              useRegularLinks={props.useRegularLinks}
            >
              Field Reports
            </NavDropDownItem> */}
            {userStore.isAdmin && (
              <>
                <NavDropdown.Divider />
                <NavDropDownItem
                  to='/pest-disease/trapping-data-entry'
                  useRegularLinks={props.useRegularLinks}
                >
                  Trapping Data Entry
                </NavDropDownItem>
                <NavDropDownItem
                  to='/pest-disease/trapping-runs'
                  useRegularLinks={props.useRegularLinks}
                >
                  Trapping Runs
                </NavDropDownItem>
                <NavDropDownItem
                  to='/pest-disease/weekly-trapping-report'
                  useRegularLinks={props.useRegularLinks}
                >
                  Weekly Trapping Report
                </NavDropDownItem>
                <NavDropDownItem
                  to='/pest-disease/missing-trapping-report'
                  useRegularLinks={props.useRegularLinks}
                >
                  Missing Trapping Report
                </NavDropDownItem>
              </>
            )}
          </NavDropdown>

          {/* Account */}
          <NavLink
            to='/account'
            active={activeMenuItem === 'account'}
            useRegularLinks={props.useRegularLinks}
          >
            <FontAwesomeIcon icon={faCog} /> Account
          </NavLink>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  ) : null;
}

Navbar.propTypes = {
  useRegularLinks: PropTypes.bool
};

export default observer(Navbar);

