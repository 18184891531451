import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import { Route as ReactRoute, useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { storesContext } from '../../stores/storesContext';
import LoadingOverlay from '../LoadingOverlay';
import Footer from '../footer/Footer';
import Layout from '../layout/Layout';
import Login from '../login/Login';

function Route(props) {
  const { component, requiresAuth, requiresAdmin, ...rest } = props;
  const userStore = useContext(storesContext);
  const history = useHistory();
  const [printing, setPrinting] = useState(false);

  const loading = userStore.loading;
  const loggedIn = userStore.loggedIn;
  const isAdmin = userStore.isAdmin;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () =>
      new Promise((resolve) => {
        setPrinting(true);
        resolve();
      }),
    onAfterPrint: () => setPrinting(false)
  });
  const Component = React.cloneElement(component, {
    print: { handlePrint, printing }
  });

  return (
    <ReactRoute
      {...rest}
      render={() => {
        if (requiresAuth && !loggedIn) {
          if (loading)
            return (
              <Layout>
                <LoadingOverlay opacity={0.5} />
              </Layout>
            );
          else
            return (
              <>
                <Layout>
                  <Login />
                </Layout>
                <Footer />
              </>
            );
        } else if (requiresAdmin && !isAdmin) {
          history.push('');
        } else {
          return (
            <>
              <Layout {...rest} ref={componentRef}>
                {userStore.loading ? <LoadingOverlay opacity={0.5} /> : null}
                {Component}
              </Layout>
              <Footer />
            </>
          );
        }
      }}
    />
  );
}

Route.propTypes = {
  path: PropTypes.string,
  component: PropTypes.element.isRequired,
  requiresAuth: PropTypes.bool,
  requiresAdmin: PropTypes.bool,
  usePropertiesSelecter: PropTypes.bool
};

export default observer(Route);

