import React from 'react';
import { Alert } from 'react-bootstrap';

const GreenAtlasAdvertisement = (props) => {
  const getPropertyNameText = () => {
    return props.propertyName ? <b>{props.propertyName}</b> : 'this property';
  };

  return (
    <Alert variant='info'>
      <p className='mb-0'>
        Green Atlas scanning provides a unique view of actual crop and canopy
        variability for your entire block. This powerful data insight informs
        precise management decisions.
      </p>
      <br />
      <p className='mt-2 mb-0'>
        No Green Atlas scanning has been completed on {getPropertyNameText()}{' '}
        for the selected season. <br /> To find out more about this cool
        technology{' '}
        <a href='https://fruition-metwatch-assets.s3.ap-southeast-2.amazonaws.com/brochure+/fruition-greenatlas-brochure.pdf'>
          click here
        </a>
        .
      </p>
    </Alert>
  );
};

export default GreenAtlasAdvertisement;

