import React, { Fragment, useState, useMemo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { storesContext } from '../../stores/storesContext';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination
} from 'react-table';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faSort,
  faSortUp,
  faSortDown,
  faTrash,
  faEye,
  faCheck,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import GlobalTableFilter from '../GlobalTableFilter';
import TablePagination from '../helpers/TablePagination';

function UsersTable(props) {
  const userStore = useContext(storesContext);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);

  const data = useMemo(
    () =>
      props.data.filter((user) => (showDeletedUsers ? true : !user.deleted_at)),
    [props.data, showDeletedUsers]
  );

  const deleteUser = (userId) => {
    props.setLoading(true);
    fetch(`${process.env.REACT_APP_AUTH_API_URL}api/admin/users/${userId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'applicatiom/json',
        service: 'fruition',
        Authorization: `Bearer ${userStore.bearerToken}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) userStore.refresh();
        else throw Error(res.statusText);
      })
      .then((data) => {
        if (data) {
          props.updateUser(data);
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
      })
      .finally(() => {
        props.setLoading(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Role',
        accessor: 'role.name'
      },
      {
        Header: 'Send Reports',
        accessor: 'send_reports',
        Cell: ({ value }) =>
          value ? (
            <FontAwesomeIcon className='text-success' icon={faCheck} />
          ) : (
            <FontAwesomeIcon className='text-danger' icon={faTimes} />
          )
      },
      {
        id: 'edit',
        sortable: false,
        filterable: false,
        Header: (tableInstance) => (
          <div className='text-right'>
            <Button
              type='button'
              variant='link'
              className={
                tableInstance.state.showDeletedUsers
                  ? 'text-primary'
                  : 'text-dark'
              }
              onClick={() => {
                setShowDeletedUsers(!tableInstance.state.showDeletedUsers);
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </div>
        ),
        Cell: ({ row }) => (
          <div className='text-right'>
            {/* Select User */}
            <Button
              type='button'
              variant='link'
              className='text-primary'
              onClick={() => props.changeSelectedUser(row.original.id)}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Button>

            {/* Delete User */}
            {row.original.deleted_at === null ? (
              <Button
                type='button'
                variant='link'
                className='text-danger'
                onClick={() => deleteUser(row.original.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            ) : null}
          </div>
        )
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            showDeletedUsers: showDeletedUsers
          }),
          [state, showDeletedUsers]
        );
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    props.setCurrentPage(pageIndex);
  }, [pageIndex]);
  
  useEffect(() => {
    gotoPage(props.currentPage);
  }, []);

  return (
    <Fragment>
      <GlobalTableFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        style={{
          marginBottom: '0.5rem',
          maxWidth: 500
        }}
      />
      <Table responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.canSort ? (
                    <span className='px-2'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <TablePagination
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
      />
    </Fragment>
  );
}

UsersTable.propTypes = {
  data: PropTypes.array.isRequired,
  setLoading: PropTypes.func.isRequired,
  changeSelectedUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default observer(UsersTable);
