import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import React, { useContext, useRef, useState } from 'react';
import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap';
import { storesContext } from '../../../stores/storesContext';
import PageTitle from '../../helpers/PageTitle';

export const GreenAtlasUploadPage = () => {
  return (
    <div className='mb-4'>
      <Row>
        <Col>
          <PageTitle title={'Green Atlas Bundle Upload'} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className='text-center'>
            Upload Green Atlas Viewer bundles for the selected property.
          </h6>
        </Col>
      </Row>
      <Row className='my-2 no-gutters' xs={12} lg={8}>
        <Col xs={0} lg={1} xl={2} />
        <Col xs={12} lg={10} xl={8}>
          <Alert variant='info'>
            <Alert.Heading>How to use</Alert.Heading>
            <p>
              To get started, click 'Browse' to select your Green Atlas ZIP
              file, and click 'Upload'.
            </p>
            <p>
              Files will be uploaded for the selected property and season. If
              multiple files are uploaded, they will all be associated with the
              current property.
            </p>
            <hr />
            <h5>File Type</h5>
            <p className='mb-0'>
              Only Green Atlas Viewer ZIP file exports will be processed.
            </p>
          </Alert>
        </Col>
        <Col xs={0} lg={2} xl={2} />
      </Row>
      <Row className='no-gutters mt-2' xs={12} lg={8}>
        <Col xs={0} lg={2} xl={2} />
        <Col xs={12} lg={10} xl={8}>
          <FileUpload />
        </Col>
        <Col xs={0} lg={2} xl={2} />
      </Row>
    </div>
  );
};

const FileUpload = () => {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const userStore = useContext(storesContext);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prev) => [...prev, ...newFiles]);
  };

  const handleFileRemove = (filename) => {
    setFiles((prev) => prev.filter((f) => f.name !== filename));
  };

  const uploadToS3 = async (file) => {
    // Get file upload params.
    const season = userStore.selectedDatabase
      ? userStore.selectedDatabase.substr(9)
      : null;
    const property_identifier =
      userStore.propertiesStore.selectedProperty?.identifier;
    const tempAwsCredentials = userStore.tempAwsCredentials;
    const s3Client = new S3Client({
      region: 'ap-southeast-2',
      credentials: {
        accessKeyId: tempAwsCredentials['key'],
        secretAccessKey: tempAwsCredentials['secret'],
        sessionToken: tempAwsCredentials['token']
      },
      forcePathStyle: true
    });

    const params = {
      Bucket: process.env.REACT_APP_GA_BUNDLE_S3_BUCKET,
      Key: `unprocessed/${file.name}`,
      Body: file,
      ContentType: file.type,
      Metadata: {
        property_identifier: property_identifier,
        season: season
      }
    };

    try {
      const command = new PutObjectCommand(params);
      await s3Client.send(command);
      console.log(`Successfully uploaded ${file.name} to S3`);
    } catch (error) {
      console.error(`Error uploading ${file.name} to S3:`, error);
      throw error;
    }
  };

  const handleFileUpload = async () => {
    if (files.length === 0) {
      alert('Please select files to upload');
      return;
    }

    setUploading(true);

    try {
      for (const file of files) {
        await uploadToS3(file);
      }
      alert('Files uploaded successfully!');
      setFiles([]);
    } catch (error) {
      alert('Error uploading files. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles((prev) => [...prev, ...droppedFiles]);
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className='p-4 border-2 border-dashed border-gray-300 rounded-lg'
    >
      <Row className='mb-3'>
        <Col>
          <div className='input-group'>
            <input
              ref={fileInputRef}
              type='file'
              multiple
              className='form-control'
              id='fileInput'
              onChange={handleFileChange}
              disabled={uploading}
            />
            <Button
              variant='primary'
              onClick={handleFileUpload}
              disabled={uploading || files.length === 0}
            >
              {uploading ? (
                <Spinner animation='border' size='sm' />
              ) : (
                'Upload to S3'
              )}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul className='list-group'>
            {files.map((file, index) => (
              <li
                key={index}
                className='list-group-item d-flex justify-content-between align-items-center'
              >
                {file.name}
                <Button
                  variant='outline-danger'
                  size='sm'
                  onClick={() => handleFileRemove(file.name)}
                  disabled={uploading}
                >
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      {files.length > 0 && (
        <Row className='mt-3'>
          <Col>
            <p>
              Drag and drop files here or click the file input to add more
              files.
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default FileUpload;

