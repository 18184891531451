import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { storesContext } from '../../stores/storesContext';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Card, Row, Col, Button, CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import LoadingOverlay from '../LoadingOverlay';
import { UserProperties } from '@hortplus/properties-react';
import UserDetailsForm, { UserDetailsFormValidator } from '../account/UserDetailsForm';

function User(props) {
  const userStore = useContext(storesContext);
  const [editing, setEditing] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(false);

  const restore = () => {
    setLoadingDetails(true);
    fetch(
      `${process.env.REACT_APP_AUTH_API_URL}api/admin/fruition/users/${props.user.id}/restore`,
      {
        method: 'PUT',
        headers: {
          Accept: 'applicatiom/json',
          Authorization: `Bearer ${userStore.bearerToken}`
        }
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) userStore.refresh();
        else throw Error(res.statusText);
      })
      .then((data) => {
        if (data) {
          props.update(data);
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
      })
      .finally(() => {
        setLoadingDetails(false);
      });
  };

  /**
   * @param {int} roleId
   */
  const updateRole = (roleId) => {
    setLoadingPermissions(true);
    fetch(
      `${process.env.REACT_APP_AUTH_API_URL}api/admin/fruition/user/${props.user.id}/role`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userStore.bearerToken}`
        },
        body: JSON.stringify({
          role_id: roleId
        })
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) userStore.refresh();
        else throw Error(res.statusText);
      })
      .then((data) => {
        if (data) {
          props.update(data);
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
      })
      .finally(() => {
        setLoadingPermissions(false);
      });
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            variant='secondary'
            className='mb-2'
            type='button'
            onClick={props.close}
          >
            Back
          </Button>
        </Col>
      </Row>

      <CardDeck>
        {editing ? (
          <Formik
            initialValues={{
              name: props.user.name,
              email: props.user.email,
              send_reports: props.user.send_reports
            }}
            validate={UserDetailsFormValidator}
            onSubmit={(values, actions) => {
              fetch(
                `${process.env.REACT_APP_AUTH_API_URL}api/admin/users/${props.user.id}`,
                {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${userStore.bearerToken}`,
                    service: 'fruition'
                  },
                  body: JSON.stringify(values)
                }
              )
                .then((res) => {
                  if (res.ok) return res.json();
                  else if (res.status === 401) userStore.refresh();
                  else throw Error(res.statusText);
                })
                .then((data) => {
                  if (data) {
                    props.update(data);
                    setEditing(false);
                  }
                })
                .catch((err) => {
                  if (process.env.NODE_ENV === 'development')
                    console.error(err);
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          >
            {(props) => (
              <Card>
                <Card.Header className='d-flex justify-content-between align-items-center'>
                  User Details
                  <div>
                    {/* Submit button */}
                    <Button
                      variant='link'
                      className='text-success'
                      type='button'
                      onClick={props.handleSubmit}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>

                    {/* Cancel button */}
                    <Button
                      variant='link'
                      className='text-danger'
                      type='button'
                      onClick={() => setEditing(false)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <UserDetailsForm canEditEmail={true} {...props} />
                </Card.Body>
              </Card>
            )}
          </Formik>
        ) : (
          <Card>
            <Card.Header className='d-flex justify-content-between align-items-center'>
              User Details
              {props.user.deleted_at ? (
                <Button
                  variant='outline-danger'
                  type='button'
                  onClick={() => restore()}
                >
                  Restore
                </Button>
              ) : (
                <Button
                  variant='link'
                  type='button'
                  onClick={() => setEditing(true)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>Name:</Col>
                <Col>{props.user.name}</Col>
              </Row>
              <Row>
                <Col>Email:</Col>
                <Col>{props.user.email}</Col>
              </Row>
              <Row>
                <Col>Send Reports:</Col>
                <Col>
                  {props.user.send_reports ? (
                    <FontAwesomeIcon className='text-success' icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon className='text-danger' icon={faTimes} />
                  )}
                </Col>
              </Row>
            </Card.Body>
            {loadingDetails ? <LoadingOverlay /> : null}
          </Card>
        )}

        <Card>
          <Card.Header style={{ height: '63px' }} className='d-flex align-items-center'>Permissions</Card.Header>
          <Card.Body>
            <Row>
              <Col>Role:</Col>
              <Col>
                <Select
                  value={{
                    value: props.user.role.id,
                    label: props.user.role.name
                  }}
                  options={props.roles.map((role) => {
                    return {
                      value: role.id,
                      label: role.name
                    };
                  })}
                  onChange={(role) => {
                    updateRole(role.value);
                  }}
                />
              </Col>
            </Row>
          </Card.Body>
          {loadingPermissions ? <LoadingOverlay /> : null}
        </Card>
      </CardDeck>
      <Row className='mt-2'>
        <Col>
          <UserProperties
            selectedDatabase={userStore.selectedDatabase}
            bearerToken={userStore.bearerToken}
            refreshBearerToken={userStore.refresh}
            user_id={props.user.id}
            user_type='fruition'
          />
        </Col>
      </Row>
    </>
  );
}

User.propTypes = {
  user: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  update: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

export default observer(User);
