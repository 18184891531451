import bbox from '@turf/bbox';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Map, { Layer, Source } from 'react-map-gl-7';
import PreviewButton, { monoStyle } from '../helpers/maps/PreviewButton';

const BlocksMap = ({ features, selectedBlock, onClick }) => {
  const initialViewPort = {
    latitude: -41,
    longitude: 174.5,
    zoom: 4,
    height: 400,
    width: '100%'
  };
  const [viewport, setViewport] = useState(initialViewPort);
  const [initialBoundsSet, setInitialBoundsSet] = useState(false);
  const [mapStyle, setMapStyle] = useState(monoStyle);
  const mapRef = useRef();
  const layers = [
    {
      id: 'layerStyleHasReportsFill',
      type: 'fill',
      paint: {
        'fill-color': 'green',
        'fill-opacity': 0.3
      },
      filter: ['==', 'has_reports', true]
    },
    {
      id: 'layerStyleNoReportsFill',
      type: 'fill',
      paint: {
        'fill-color': 'gray',
        'fill-opacity': 0.2
      },
      filter: ['==', 'has_reports', false]
    },
    {
      id: 'layerStyleHasReportsLine',
      type: 'line',
      paint: {
        'line-color': 'green',
        'line-width': 1.2
      },
      filter: ['==', 'has_reports', true]
    },
    {
      id: 'layerStyleNoReportsLine',
      type: 'line',
      paint: {
        'line-color': 'gray',
        'line-width': 1.2
      },
      filter: ['==', 'has_reports', false]
    },
    {
      id: 'layerStyleSelectedHasReportsFill',
      type: 'fill',
      paint: {
        'fill-color': 'green',
        'fill-opacity': 0.3
      },
      filter: [
        'all',
        ['==', 'block_name', selectedBlock],
        ['==', 'has_reports', true]
      ]
    },
    {
      id: 'layerStyleSelectedNoReportsFill',
      type: 'fill',
      paint: {
        'fill-color': 'gray',
        'fill-opacity': 0.2
      },
      filter: [
        'all',
        ['==', 'block_name', selectedBlock],
        ['==', 'has_reports', false]
      ]
    },
    {
      id: 'layerStyleSelectedHasReportsLine',
      type: 'line',
      paint: {
        'line-color': 'green',
        'line-width': 1.3
      },
      filter: [
        'all',
        ['==', 'block_name', selectedBlock],
        ['==', 'has_reports', true]
      ]
    },
    {
      id: 'layerStyleSelectedNoReportsLine',
      type: 'line',
      paint: {
        'line-color': 'gray',
        'line-width': 1.3
      },
      filter: [
        'all',
        ['==', 'block_name', selectedBlock],
        ['==', 'has_reports', false]
      ]
    }
  ];

  const data = useMemo(() => {
    const geojson = {
      type: 'FeatureCollection',
      features
    };
    return geojson;
  }, [features]);

  const fitBounds = useCallback(
    (map) => {
      const features = data.features;
      if (features && features.length > 0) {
        let minLng;
        let minLat;
        let maxLng;
        let maxLat;
        data.features.forEach((feature) => {
          const [newMinLng, newMinLat, newMaxLng, newMaxLat] = bbox(feature);
          if (minLng === undefined || newMinLng < minLng) minLng = newMinLng;
          if (maxLng === undefined || newMaxLng > maxLng) maxLng = newMaxLng;
          if (minLat === undefined || newMinLat < minLat) minLat = newMinLat;
          if (maxLat === undefined || newMaxLat > maxLat) maxLat = newMaxLat;
        });
        map.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat]
          ],
          { padding: 50, duration: 500 }
        );
      } else {
        const distance = viewport.zoom + 1;
        const bounds = [
          [viewport.longitude - distance, viewport.latitude - distance],
          [viewport.longitude + distance, viewport.latitude + distance]
        ];
        map.fitBounds(bounds, { padding: 50, duration: 500 });
      }
    },
    [data.features, viewport.latitude, viewport.longitude, viewport.zoom]
  );

  useEffect(() => {
    if (mapRef.current && data) {
      fitBounds(mapRef.current);
      setInitialBoundsSet(true);
    }
  }, [fitBounds, data, initialBoundsSet]);

  const mapRefWrapper = useCallback(
    (node) => {
      if (node) {
        if (data && !initialBoundsSet) {
          fitBounds(node);
          setInitialBoundsSet(true);
        }
      }
      mapRef.current = node;
    },
    [data, fitBounds, initialBoundsSet]
  );

  return (
    <Map
      ref={mapRefWrapper}
      reuseMaps
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      mapStyle={`mapbox://styles/hortplus/${mapStyle}`}
      initialViewState={viewport}
      onViewportChange={(nextViewport) => setViewport(nextViewport)}
      attributionControl={false}
      interactiveLayerIds={[
        'layerStyleHasReportsFill',
        'layerStyleNoReportsFill',
        'layerStyleHasReportsLine',
        'layerStyleNoReportsLine',
        'layerStyleSelectedHasReportsFill',
        'layerStyleSelectedHasReportsLine',
        'layerStyleSelectedNoReportsFill',
        'layerStyleSelectedNoReportsLine'
      ]}
      onClick={(e) => {
        const hoveredFeature = e.features && e.features[0];
        onClick(hoveredFeature);
      }}
    >
      <div
        className='m-3'
        style={{
          position: 'absolute',
          top: 0,
          right: 0
        }}
      >
        <Button
          variant='light'
          onClick={() => fitBounds(mapRef.current)}
          className='float-right'
        >
          Reset
        </Button>
      </div>
      <Source id='blocks' type='geojson' data={data}>
        {layers.map((layer) => (
          <Layer key={layer.id} {...layer} />
        ))}
      </Source>
      <PreviewButton
        setMapStyle={setMapStyle}
        mapStyle={mapStyle}
        viewport={viewport}
      />
    </Map>
  );
};

BlocksMap.propTypes = {
  features: PropTypes.array.isRequired,
  selectedBlock: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default BlocksMap;

