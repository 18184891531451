import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { errorToast } from '../helpers/toasts/ToastUtils';
import { irrimaxURL, openIrrimaxWindow } from './helpers';

const SiteButton = ({
  site,
  summary,
  selectedSite,
  selectSite,
  soilMoisture,
  isBlueAlert
}) => {
  const siteName = `${site.name ?? '-'} `;
  return site.type === 'terraprobe' ? (
    <Button
      key={site.id}
      className='mx-2 my-2'
      variant={`${
        selectedSite && site.id === selectedSite.id ? '' : 'outline-'
      }${
        summary
          ? summary.alert_level === 3
            ? isBlueAlert
              ? 'primary'
              : 'danger'
            : summary.alert_level === 2
            ? 'warning'
            : summary.alert_level === 1
            ? 'success'
            : 'secondary'
          : 'secondary'
      }`}
      onClick={() => {
        if (site.application_rate === null) {
          errorToast(
            'Missing critical site information. Please contact support.'
          );
        }
        selectSite(site);
      }}
    >
      {`${siteName}${
        soilMoisture !== null ? ` ${Math.round(soilMoisture * 100)}%` : ''
      }`}
    </Button>
  ) : (
    <div>
      <Button
        key={site.id}
        className='mx-2 my-2'
        variant='outline-dark'
        onClick={() => openIrrimaxWindow(irrimaxURL(site))}
      >
        <img
          src='https://fruition-metwatch-assets.s3-ap-southeast-2.amazonaws.com/sentek-logo-16.png'
          alt='sentek-logo'
          style={{ paddingBottom: '2px', marginRight: '2px' }}
        />{' '}
        {siteName}
        <FontAwesomeIcon
          className='ml-1'
          icon={faExternalLinkAlt}
          size='sm'
          style={{ marginBottom: '1px', marginLeft: '2px' }}
        />
      </Button>
    </div>
  );
};

export default SiteButton;

