import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { gtagWrapper } from './analytics';
import Account from './components/account/Account';
import ChangePassword from './components/account/ChangePassword';
import UserManager from './components/admin/UserManager';
import BlockScanningReportsPage from './components/block_scanning/BlockScanningReportsPage';
import BlockScanningUploadPage from './components/block_scanning/upload/BlockScanningUploadPage';
import CropScanningReports from './components/crop_scanning/CropScanningReports';
import { GreenAtlasUploadPage } from './components/crop_scanning/upload/GreenAtlasUploadPage';
import Dashboard from './components/dashboard/Dashboard';
import ErrorBoundary from './components/helpers/ErrorBoundary';
import Route from './components/helpers/Route';
import { ToastContainer } from './components/helpers/toasts/ToastUtils';
import { IrrigationPage } from './components/irrigation/index';
import IrrigationSummary from './components/irrigation/reports/IrrigationSummary';
import ForgotPassword from './components/login/ForgotPassword';
import ResetPassword from './components/login/ResetPassword';
import {
  PropertiesPage,
  PropertyGroupsPage,
  PropertyKeyContactsPage
} from './components/properties/index';
import {
  HotTrapMapPage,
  MissingMonitoringPage,
  SeasonOverviewReportPage,
  AdminOverviewPage as TraplogAdminOverviewPage,
  TraplogPage,
  TrappingMonitoringReportPage,
  TrappingRunsPage
} from './components/traplog/index';
import apm, { setupApmUser } from './rum';
import { storesContext } from './stores/storesContext';

function App() {
  const userStore = useContext(storesContext);
  // Associate user ID with logs.
  useEffect(
    () => setupApmUser(apm, userStore.user ? userStore.user.id : undefined),
    [userStore.user]
  );

  useEffect(() => {
    if (userStore.loggedIn && userStore.selectedDatabase) {
      userStore.propertiesStore.setRequestWith(['irrigationMonitoringSites']);
      userStore.propertiesStore.loadProperties(
        userStore.selectedDatabase,
        userStore.bearerToken,
        userStore.refreshCallback
      );
    }
  }, [
    userStore.selectedDatabase,
    userStore.bearerToken,
    userStore.loggedIn,
    userStore.propertiesStore,
    userStore.refreshCallback
  ]);

  useEffect(() => {
    gtagWrapper('UA-112489177-15');
  }, []);

  return (
    <ErrorBoundary>
      <ToastContainer />
      <Switch>
        <Route path='/forgot-password/:token+' component={<ResetPassword />} />
        <Route path='/forgot-password' component={<ForgotPassword />} />

        {/* Management Routes */}
        <Route
          path='/management/users/:id'
          component={<UserManager />}
          requiresAuth
          requresAdmin
        />
        <Route
          path='/management/users'
          component={<UserManager />}
          requiresAuth
          requiresAdmin
        />
        <Route
          path='/properties/property-key-contacts/:id'
          component={<PropertyKeyContactsPage />}
          requiresAuth
          requiresAdmin
        />
        <Route
          path='/management/properties/:id'
          component={<PropertiesPage />}
          requiresAuth
        />
        <Route
          path='/management/properties'
          component={<PropertiesPage />}
          requiresAuth
        />
        <Route
          path='/management/property-groups/:id'
          component={<PropertyGroupsPage />}
          requiresAuth
        />
        <Route
          path='/management/property-groups'
          component={<PropertyGroupsPage />}
          requiresAuth
        />

        {/* Soil & Water Routes */}
        <Route
          path='/soil-water/monitoring'
          component={<IrrigationPage />}
          requiresAuth
          usePropertiesSelecter
        />
        <Route
          path='/soil-water/seasonal-summary-report'
          component={<IrrigationSummary />}
          requiresAuth
        />
        {/* <Route
          path='/soil-water/reports'
          component={<></>}
          requiresAuth
        /> */}

        {/* Pomology Routes */}
        {/* <Route
          path='/pomology/calculators'
          component={<></>}
          requiresAuth
        /> */}

        {/* Precision Hort Routes */}
        {/* <Route
          path='/precision-hort/calculators'
          component={<></>}
          requiresAuth
        /> */}
        <Route
          path='/precision-hort/reports'
          component={<CropScanningReports />}
          requiresAuth
          usePropertiesSelecter
        />
        <Route
          path='/precision-hort/block-scanning/upload'
          component={<BlockScanningUploadPage />}
          requiresAuth
          requiresAdmin
        />
        <Route
          path='/precision-hort/block-scanning-reports'
          component={<BlockScanningReportsPage />}
          requiresAuth
          usePropertiesSelecter
        />
        <Route
          path='/precision-hort/ga-viewer-upload'
          component={<GreenAtlasUploadPage />}
          requiresAuth
          requiresAdmin
          usePropertiesSelecter
        />

        {/* Trapping Routes */}
        <Route
          path='/pest-disease/trapping-overview'
          component={<TraplogPage />}
          requiresAuth
          usePropertiesSelecter
        />
        <Route
          path='/pest-disease/hot-trap-map'
          component={<HotTrapMapPage />}
          requiresAuth
          usePropertiesSelecter
        />
        <Route
          path='/pest-disease/seasonal-trapping-report'
          component={<SeasonOverviewReportPage />}
          requiresAuth
        />
        {/* <Route
          path='/pest-disease/field-reports'
          component={<></>}
          requiresAuth
        /> */}
        <Route
          path='/pest-disease/trapping-data-entry'
          component={<TraplogAdminOverviewPage />}
          requiresAuth
          requiresAdmin
        />
        <Route
          path='/pest-disease/trapping-runs/:id'
          component={<TrappingRunsPage />}
          requiresAuth
          requiresAdmin
        />
        <Route
          path='/pest-disease/trapping-runs'
          component={<TrappingRunsPage />}
          requiresAuth
          requiresAdmin
        />

        <Route
          path='/pest-disease/weekly-trapping-report'
          component={<TrappingMonitoringReportPage />}
          requiresAuth
          requiresAdmin
        />
        <Route
          path='/pest-disease/missing-trapping-report'
          component={<MissingMonitoringPage />}
          requiresAuth
          requiresAdmin
        />

        {/* Account Routes */}
        <Route
          path='/account'
          component={<Account />}
          requiresAuth
          usePropertiesSelecter
        />
        <Route
          path='/change-password'
          component={<ChangePassword />}
          requiresAuth
        />

        {/* Dashboard */}
        <Route component={<Dashboard />} requiresAuth />
      </Switch>
    </ErrorBoundary>
  );
}

export default observer(App);

