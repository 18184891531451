import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import Chart, { setupSynchronization } from './Chart.js';

const initStrategyOptions = () => ({
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    plotLines: []
  },
  yAxis: {
    title: {
      text: 'Soil Water Content (mm)'
    }
  },
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 1
        }
      }
    },
    column: {
      pointPadding: 0,
      groupPadding: 0
    }
  }
});

const initRainfallOptions = () => ({
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    plotLines: []
  },
  yAxis: {
    title: {
      text: 'Rain (mm)'
    },
    min: 0,
    max: 60
  },
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 1
        }
      }
    },
    column: {
      pointPadding: 0,
      groupPadding: 0
    }
  }
});

export default function SeasonalStrategyChart(props) {
  const [strategyOptions, setStrategyOptions] = useState(initStrategyOptions());
  const [rainfallOptions, setRainfallOptions] = useState(initRainfallOptions());

  useLayoutEffect(() => {
    setupSynchronization('strategy-graph');
  }, []);

  useEffect(() => {
    const probeReadingData = [];
    const rainfallData = [];
    const irrigationData = [];
    const strategyRangeData = [];
    const criticalDates = {};

    let start;
    let stop;
    let fullPoint;
    let refill;
    let minReading;
    let maxReading;

    props.readingData.forEach((reading) => {
      if (reading.type === 'Probe') {
        const date = moment(reading.date, 'DD-MM-YYYY');

        const rz1 = Math.round(reading['rz1']);
        if (minReading === undefined || rz1 < minReading) minReading = rz1;
        if (maxReading === undefined || rz1 > maxReading) maxReading = rz1;
        // Use fullPoint and refill to help determine max and min readings.
        if (fullPoint !== undefined && fullPoint < minReading) minReading = fullPoint;
        if (fullPoint !== undefined && fullPoint > maxReading) maxReading = fullPoint;
        if (refill !== undefined && refill < minReading) minReading = refill;
        if (refill !== undefined && refill > maxReading) maxReading = refill;

        probeReadingData.push({
          y: rz1,
          x: date.valueOf()
        });

        rainfallData.push({
          y: reading.rain,
          x: date.valueOf()
        });

        irrigationData.push({
          y: reading.irrigation_mms,
          x: date.valueOf()
        });
        // Set the fullPoint and refill values from siteSummary.
        // It's possible for 'props.readingData' to have no 'Full Point'
        // or 'Refill' reading so this handles that case.
        if (!fullPoint) fullPoint = props.siteSummary?.full_point;
        if (!refill) refill = props.siteSummary?.refill_point;
      } else if (reading.type === 'Full Point') {
        fullPoint = Math.round(reading.rz1);
      } else if (reading.type === 'Refill') {
        refill = Math.round(reading.rz1);
      }
    });

    const diff = fullPoint - refill;
    props.strategyData.forEach((strategy) => {
      criticalDates[strategy.critical_date_type] = strategy.critical_date;

      if (strategy.critical_date_type === 'Start')
        start = strategy.critical_date;
      if (strategy.critical_date_type === 'End') stop = strategy.critical_date;

      const strategyDate = moment(strategy.strategy_date, 'DD-MM-YYYY');
      const upper = fullPoint - (diff - diff * strategy.percentage);
      strategyRangeData.push({
        x: strategyDate.valueOf(),
        high: upper,
        low: upper - diff * strategy.strategy_percentage
      });
    });

    // Order all data by the x axis
    probeReadingData.sort((a, b) => a.x - b.x);
    rainfallData.sort((a, b) => a.x - b.x);
    irrigationData.sort((a, b) => a.x - b.x);
    strategyRangeData.sort((a, b) => a.x - b.x);

    // Clone the current options
    let newStrategyOptions = initStrategyOptions();
    let newRainfallOptions = initRainfallOptions();

    // Strategy graph series
    newStrategyOptions.series = [
      {
        name: 'Strategy',
        type: 'arearange',
        data: strategyRangeData,
        yAxis: 0,
        marker: {
          enabled: false
        },
        color: '#9dc8f1',
        fillOpacity: 0.5,
        tooltip: {
          valueSuffix: ' mm',
          valueDecimals: 1
        }
      },
      {
        name: 'Readings',
        type: 'line',
        data: probeReadingData,
        yAxis: 0,
        color: 'black',
        marker: {
          enabled: true,
          symbol: 'circle',
          fillColor: 'black'
        },
        tooltip: {
          valueSuffix: ' mm',
          valueDecimals: 1
        }
      }
    ];

    // Rainfall graph series
    newRainfallOptions.series = [
      {
        name: 'Rainfall',
        type: 'column',
        color: 'blue',
        data: rainfallData,
        yAxis: 0,
        tooltip: {
          valueSuffix: ' mm',
          valueDecimals: 1
        }
      },
      {
        name: 'Irrigation',
        type: 'column',
        color: 'red',
        data: irrigationData,
        yAxis: 0,
        tooltip: {
          valueSuffix: ' mm',
          valueDecimals: 1
        }
      }
    ];

    // Fullpoint/refill plot lines
    newStrategyOptions.yAxis.plotLines = [
      {
        value: fullPoint,
        color: 'green',
        width: 2,
        label: {
          text: 'Full Point'
        }
      },
      {
        value: refill,
        color: 'red',
        width: 2,
        label: {
          text: 'Refill Point'
        }
      }
    ];

    // Critical dates plot lines
    newStrategyOptions.xAxis.plotLines = [];
    for (const criticalDateType in criticalDates) {
      newStrategyOptions.xAxis.plotLines.push({
        value: moment(criticalDates[criticalDateType], 'DD-MM-YYYY').valueOf(),
        label: {
          text: criticalDateType
        }
      });
    }

    // Y Axis min/max values with 15% buffer
    const yAxisMin = minReading < refill ? minReading : refill;
    const yAxisMax = maxReading > fullPoint ? maxReading : fullPoint;
    newStrategyOptions.yAxis.min = yAxisMin - yAxisMin * 0.1;
    newStrategyOptions.yAxis.max = yAxisMax + yAxisMax * 0.1;

    // X Axis min/max values
    // add 2 days of padding either side
    if (start && stop) {
      const startPadded =
        moment(start, 'DD-MM-YYYY').valueOf() - 1000 * 60 * 60 * 24 * 2;
      const stopPadded =
        moment(stop, 'DD-MM-YYYY').valueOf() + 1000 * 60 * 60 * 24 * 2;
      newStrategyOptions.xAxis.min = startPadded;
      newStrategyOptions.xAxis.max = stopPadded;
      newRainfallOptions.xAxis.min = startPadded;
      newRainfallOptions.xAxis.max = stopPadded;
    }

    // Update the state
    setStrategyOptions(newStrategyOptions);
    setRainfallOptions(newRainfallOptions);
  }, [props.readingData, props.strategyData]);

  return (
    <div id='strategy-graph'>
      <Chart options={strategyOptions} loading={false} />
      <Chart options={rainfallOptions} loading={false} />
    </div>
  );
}

