import React from 'react';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import './footer.scss';

function Footer() {
  return (
    <footer className='w-100'>
      <Row className='no-gutters'>
        <Col>
          <Row className='no-gutters' style={{ minHeight: '350px' }}>
            <Col
              className='footer-left ellipse-1 ellipse-2 w-100 pt-4'
              sm={true}
            >
              <div className='ellipse-1' />
              <div className='ellipse-2' />
              <Row className='mt-3 mb-5 mx-0'>
                <Col xs={1} sm={1} md={2}></Col>
                <Col className='px-0'>
                  <img
                    className='w-75'
                    src='https://fruition-metwatch-assets.s3-ap-southeast-2.amazonaws.com/fruition-logo.png'
                    alt='Fruition Portal'
                  />
                </Col>
                <Col></Col>
              </Row>
              <Row className='mb-2 mx-0'>
                <Col xs={1} sm={1} md={2}></Col>
                <Col className='px-0'>
                  <Nav className='footer-nav'>
                    <Nav.Item>
                      <Nav.Link
                        className='first-link text pl-0'
                        href='https://www.fruitionhort.com/'
                      >
                        Home
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className='text'
                        href='https://www.fruitionhort.com/about'
                      >
                        About
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className='text'
                        href='https://www.fruitionhort.com/contact'
                      >
                        Contact
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className='text'
                        href='https://www.facebook.com/FruitionHort/'
                      >
                        Facebook
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className='text'
                        href='https://nz.linkedin.com/company/fruition-horticulture-hb-ltd'
                      >
                        LinkedIn
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <hr className='line' />
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className='mx-0'>
                <Col xs={1} sm={1} md={2}></Col>
                <Col className='px-0 text-white'>What We Offer</Col>
              </Row>
              <Row className='mt-3 mx-0'>
                <Col xs={1} sm={1} md={2}></Col>
                <Col className='px-0'>
                  <Nav className='footer-nav'>
                    <Nav.Item>
                      <Nav.Link
                        className='first-link text pl-0'
                        href='https://www.fruitionhort.com/soil-water'
                      >
                        Soil & Water
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className='text'
                        href='https://www.fruitionhort.com/pomology'
                      >
                        Pomology
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className='text'
                        href='https://www.fruitionhort.com/precision-hort'
                      >
                        Precision Hort
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className='text'
                        href='https://www.fruitionhort.com/pest-disease'
                      >
                        Pest & Disease
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <hr className='line' />
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className='bottom mx-0'>
                <Col xs={1} sm={1} md={2}></Col>
                <Col className='p-0'>
                  <p className='pl-0'>&#169; 2022 Fruition</p>
                </Col>
                <Col className='p-0' xs={5} sm={5} md={5}>
                  <p className='pr-0 float-right'>
                    Website By HortPlus and One Project Studio
                  </p>
                </Col>
                <Col md={1}></Col>
              </Row>
            </Col>
            <Col className='footer-right w-100' sm={true}>
              <Row className='bottom h-100 no-gutters'>
                <Col xs={1} sm={1} md={2}></Col>
                <Col>
                  <Row className='h-100 no-gutters'>
                    <Col className='my-auto'>
                      <h5 className='text-white font-weight-normal text mb-0'>
                        Talk To Our Team
                      </h5>
                    </Col>
                    <Col className='my-auto text-center'>
                      <Button
                        className='button'
                        href='https://www.fruition.net.nz/contact-2/'
                      >
                        CONTACT
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={1} sm={1} md={2}></Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;

