import { PropTypes } from 'prop-types';
import Select from 'react-select';
import { stages } from '../crop_scanning/CropScanningUtils';

export const defaultReportTypeOption = {
  label: 'All Reports',
  value: 'all'
};

export const getReportTypeName = (reportTypeOption) => {
  return reportTypeOption ? reportTypeOption.label : '';
};

const ReportTypeSelect = ({ reportType, onChange }) => {
  const extendedStages = [defaultReportTypeOption, ...stages];

  return (
    <Select value={reportType} onChange={onChange} options={extendedStages} />
  );
};

ReportTypeSelect.propTypes = {
  reportType: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default ReportTypeSelect;

