import isMobile from 'ismobilejs';
import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../header/Header';
import Navbar from '../navbar/Navbar';
require('./layout.scss');

const Layout = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ minHeight: '100vh' }}>
      <Header usePropertiesSelecter={props.usePropertiesSelecter} />
      {isMobile(window.navigator).any ? null : (
        <Container fluid className='p-0'>
          <Navbar />
        </Container>
      )}
      <Container
        id='page-content'
        className='p-3 mb-4'
        style={{ minHeight: '100vh' }}
      >
        {props.children}
      </Container>
    </div>
  );
});

Layout.propTypes = {
  usePropertiesSelecter: PropTypes.bool
};

export default Layout;

