import { observer } from 'mobx-react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import URI from 'urijs';
import { storesContext } from '../../stores/storesContext';
import {
  pdfTypes,
  reportTypes,
  scanTypes,
  stages
} from '../crop_scanning/CropScanningUtils';
import { errorToast } from '../helpers/toasts/ToastUtils';
import ReportTable from './ReportTable';

const ReportTables = ({
  blocks,
  selectedBlock,
  reportsState,
  reportTypeReports,
  selectedBlockReports,
  season,
  seasonStart
}) => {
  const userStore = useContext(storesContext);
  const propertiesStore = userStore.propertiesStore;
  const [, setReports] = reportsState;
  const [loadingReports, setLoadingReports] = useState(false);

  // Fetch reports for the selected property and season.
  useEffect(() => {
    let mounted = true;
    const fetchReports = async (propertyId) => {
      if (mounted) setLoadingReports(true);
      const url = new URI(
        `${process.env.REACT_APP_PROPERTIES_API_URL}api/fruition/crop-scanning/reports`
      );
      url.addQuery('property_id', propertyId);
      url.addQuery('season', season);
      url.addQuery('historic', 1);
      const response = await fetch(url.valueOf(), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userStore.bearerToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          SeasonalDatabase: userStore.selectedDatabase
        }
      });
      if (!response.ok) {
        errorToast('Error fetching reports', 'reports-fetch-error');
        return;
      }
      const data = await response.json();
      if (!('Objects' in data)) return;
      if (mounted) {
        const reports = data.Objects.map((report) => {
          const parts = report['Key'].split('_');
          const dt = moment(parts[7], 'YYYYMMDD');
          const blockCode = parts[2];
          const scanType = scanTypes.find((s) => s.value === parts[3]);
          const stage = stages.find((s) => s.value === parts[4]);
          const reportType = reportTypes.find((r) => r.value === parts[5]);
          const pdfType = pdfTypes.find((p) => p.value === parts[6]);
          return {
            key: report['Key'],
            dt: dt.valueOf(),
            blockCode,
            scanType: scanType?.label ?? null,
            stage: stage?.label ?? null,
            reportType: reportType?.label ?? null,
            pdfType: pdfType?.label ?? null
          };
        });
        const sortedReports = reports.sort((a, b) =>
          a.blockCode > b.blockCode ? 1 : b.blockCode > a.blockCode ? -1 : 0
        );
        setReports(sortedReports);
        setLoadingReports(false);
      }
    };
    const selectedProperty = propertiesStore.selectedProperty;
    if (!selectedProperty) return;
    fetchReports(selectedProperty.id);
    return () => {
      mounted = false;
    };
  }, [
    propertiesStore.selectedProperty,
    season,
    setReports,
    userStore.bearerToken,
    userStore.selectedDatabase
  ]);

  // Historic reports for the selected block.
  const selectedBlockHistoricReports = useMemo(
    () =>
      reportTypeReports.filter((report) => {
        if (!(selectedBlock in blocks)) return false;
        const partBlocks = blocks[selectedBlock];
        const isSelected = report.blockCode === selectedBlock;
        const isPartBlock = partBlocks.includes(report.blockCode);
        // Filter by season.
        const date = moment(report.dt);
        if (date.isAfter(seasonStart)) return false;
        return isSelected || isPartBlock;
      }),
    [blocks, reportTypeReports, seasonStart, selectedBlock]
  );
  // Filter reports checking that the report matches a block and it's part-blocks.
  // Also, filter by whether the report appears in 'selectedBlockReports'.
  const unmatchedReports = useMemo(
    () =>
      reportTypeReports.filter((report) => {
        if (!(selectedBlock in blocks)) return false;
        const isBlock = Object.keys(blocks).includes(report.blockCode);
        let isPartBlock = false;
        let isSelectedBlockReport = false;
        Object.keys(blocks).forEach((key) => {
          const partBlocks = blocks[key];
          isPartBlock = partBlocks.includes(report.blockCode);
          isSelectedBlockReport = selectedBlockReports.find((sbr) => {
            const sbrBlockCode = sbr.blockCode;
            return sbrBlockCode === key || sbrBlockCode === report.blockCode;
          });
        });
        if (isBlock || isPartBlock || isSelectedBlockReport) return false;
        return true;
      }),
    [blocks, reportTypeReports, selectedBlock, selectedBlockReports]
  );

  return (
    <>
      <Row className='mt-2'>
        <Col className='text-center'>
          <h4>Current Season Reports</h4>
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          {loadingReports ? (
            <Spinner className='my-4' animation='border' variant='primary' />
          ) : (
            <ReportTable reports={selectedBlockReports} />
          )}
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col className='text-center'>
          <h4>Historic Reports</h4>
        </Col>
      </Row>
      <Row className='my-2'>
        <Col>
          {loadingReports ? (
            <Spinner className='my-4' animation='border' variant='primary' />
          ) : (
            <ReportTable reports={selectedBlockHistoricReports} />
          )}
        </Col>
      </Row>
      <Row className='my-2'>
        <Col className='text-center'>
          <h4>Unmatched Reports</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          {loadingReports ? (
            <Spinner className='my-4' animation='border' variant='primary' />
          ) : (
            <ReportTable reports={unmatchedReports} />
          )}
        </Col>
      </Row>
    </>
  );
};

ReportTables.propTypes = {
  blocks: PropTypes.object.isRequired,
  selectedBlock: PropTypes.string,
  reportsState: PropTypes.array,
  reportTypeReports: PropTypes.array.isRequired,
  selectedBlockReports: PropTypes.array.isRequired,
  season: PropTypes.string.isRequired,
  seasonStart: PropTypes.object.isRequired,
  seasonStop: PropTypes.object.isRequired
};

export default observer(ReportTables);

