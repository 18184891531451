import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Alert, Col, Row, Spinner } from 'react-bootstrap';

const UploadStatus = ({ status, isProcessing }) => {
  const getStatusText = (status) => {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'PROCESSING':
        return 'Processing';
      case 'COMPLETE':
        return 'Complete';
      case 'ERROR':
        return 'Error';
      default:
        return '';
    }
  };

  return (
    <>
      {status ? (
        <Row className='mt-3'>
          <Col>
            <Row className='no-gutters'>
              <Col xs={0} sm={2} md={3} />
              <Col
                xs={12}
                sm={8}
                md={6}
                className='d-flex flex-column justify-content-center align-items-center w-100'
              >
                <span className='mb-2'>
                  <h5>Upload Status</h5>
                </span>
                <Alert
                  className='text-center w-100'
                  variant={
                    status === 'ERROR'
                      ? 'danger'
                      : status === 'COMPLETE'
                      ? 'success'
                      : 'info'
                  }
                >
                  <div>
                    <span className='mr-2 font-weight-bold'>
                      {getStatusText(status)}
                    </span>
                    <span>
                      {isProcessing ? (
                        <Spinner
                          variant='primary'
                          animation='border'
                          size='sm'
                        />
                      ) : status === 'COMPLETE' ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color='green'
                          size='lg'
                        />
                      ) : status === 'ERROR' ? (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          color='red'
                          size='lg'
                        />
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  {status === 'ERROR' ? (
                    <div className='mt-1'>
                      <div>There was a problem uploading your files.</div>
                      <div> Please contact support.</div>
                    </div>
                  ) : null}
                </Alert>
              </Col>
              <Col xs={0} sm={2} md={3} />
            </Row>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

UploadStatus.propTypes = {
  status: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired
};

export default UploadStatus;

