import { useContext, useState } from 'react';
import URI from 'urijs';
import { storesContext } from '../../../stores/storesContext';

/**
 * Custom hook to poll block upload transaction status.
 *
 * @returns {Object} An object containing the following properties:
 * - status: The current status of the transaction.
 * - resetStatus: A function to reset the status.
 * - startPolling: A function to start polling the transaction status.
 */
const useTransactionPolling = () => {
  const userStore = useContext(storesContext);
  const [status, setStatus] = useState(null);

  const pollStatus = async (transactionId) => {
    const url = new URI(
      `${process.env.REACT_APP_PROPERTIES_API_URL}api/admin/fruition/blocks/transaction/${transactionId}`
    );
    const response = await fetch(url.valueOf(), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userStore.bearerToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        SeasonalDatabase: userStore.selectedDatabase
      }
    });
    if (!response.ok) {
      throw response.statusText;
    }
    const data = await response.json();
    return data.status.status;
  };

  const startPolling = async (transactionId) => {
    if (!transactionId) return;

    // Initial status poll.
    const newStatus = await pollStatus(transactionId);
    setStatus(newStatus);

    // Poll status for an interval in a promise. Resolve when status is
    // in a 'finished' state. Reject when status poll fail limit is reached.
    let errorCount = 0;
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        try {
          const newStatus = await pollStatus(transactionId);
          if (newStatus === 'COMPLETE' || newStatus === 'ERROR') {
            clearInterval(interval);
            resolve(newStatus);
          }
          setStatus(newStatus);
        } catch (error) {
          console.error('Error polling transaction status: ', error);
          errorCount++;
          if (errorCount >= 3) {
            clearInterval(interval);
            reject('Transaction polling reached its failure limit.', error);
          }
        }
      }, 5000);
    });
  };

  const resetStatus = () => {
    setStatus(null);
  };

  return { status, resetStatus, startPolling };
};

export default useTransactionPolling;

