import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';

function LastWeekTable(props) {
  // Find all probe readings
  const probeReadings = props.readingData.filter(
    (reading) => reading.type === 'Probe'
  );

  // If there are no probe readings then we can't render a table
  if (probeReadings.length < 1) return null;

  const lastWeekReading = probeReadings[0];
  return (
    <Table bordered responsive>
      <tbody>
        <tr>
          <td>Applied Irrigation</td>
          <td>
            {lastWeekReading.irrigation_mms !== null
              ? parseFloat(lastWeekReading.irrigation_mms.toFixed(1)) + ' mm'
              : 'N/A'}
          </td>
          <td>{lastWeekReading.irrigation_litres} L/plant</td>
          <td>
            {props.site.application_rate && props.site.application_rate > 0
              ? parseFloat(
                  lastWeekReading.irrigation_mms / props.site.application_rate
                ).toFixed(1) + ' Hours'
              : 'N/A'}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

LastWeekTable.propTypes = {
  readingData: PropTypes.array.isRequired,
  site: PropTypes.object.isRequired
};

export default LastWeekTable;
