import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Chart from './Chart.js';

export default function WeeklySoilMoistureChart(props) {
  const [options, setOptions] = useState({
    chart: {
      inverted: true
    },
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: 'Volumetric Soil Water Content (%)'
      },
      opposite: true
    },
    xAxis: {
      title: {
        text: 'Depth (cm)'
      }
    },
    tooltip: {
      headerFormat:
        '<span style="font-size: 10px">Depth: {point.key}cm</span><br/>'
    }
  });

  /**
   * All probe readings in descending order by date.
   */
  const probeReadingsDescending = useMemo(
    () =>
      props.readingData
        .filter((reading) => reading.type === 'Probe')
        .sort(
          (a, b) =>
            moment(b.date, 'DD-MM-YYYY').valueOf() -
            moment(a.date, 'DD-MM-YYYY').valueOf()
        ),
    [props.readingData]
  );

  useEffect(() => {
    if (probeReadingsDescending.length > 0) {
      let currentWeek = probeReadingsDescending[0].date;
      let previousWeek =
        probeReadingsDescending.length > 1
          ? probeReadingsDescending[1].date
          : null;
      let currentWeekReading;
      let previousWeekReading;
      let series = [];

      props.readingData.forEach((reading) => {
        // Find the reading types that we want to display on the chart
        const name =
          reading.type === 'Probe' && reading.date === currentWeek
            ? 'Current Week'
            : reading.type === 'Probe' && reading.date === previousWeek
            ? 'Previous Week'
            : reading.type === 'Full Point'
            ? 'Full Point'
            : reading.type === 'Refill'
            ? 'Refill'
            : null;

        // Find the current/previous weeks reading
        if (name === 'Current Week') currentWeekReading = reading;
        else if (name === 'Previous Week') previousWeekReading = reading;

        // If we want to display this reading add a series
        if (name !== null) {
          let data = [];
          for (let i = 0; i < 10; i++) {
            if (
              reading[`depth${i}`] !== null &&
              reading[`depth${i}`] > 0 &&
              reading[`vsw${i}_perc`] !== null
            ) {
              data.push({
                y: reading[`vsw${i}_perc`],
                x: reading[`depth${i}`]
              });
            }
          }

          // Sort the data by the depth
          data.sort((a, b) => a.x - b.x);

          series.push({
            type: 'line',
            name: name,
            data: data,
            color:
              name === 'Current Week'
                ? 'blue'
                : name === 'Full Point'
                ? 'green'
                : name === 'Refill'
                ? 'red'
                : name === 'Previous Week'
                ? 'lightblue'
                : null,
            marker:
              name === 'Current Week'
                ? {
                    enabled: true,
                    symbol: 'circle',
                    fillColor: 'black'
                  }
                : {
                    enabled: false,
                    symbol: 'circle'
                  },
            zIndex:
              name === 'Current Week'
                ? 50
                : name === 'Previous Week'
                ? 40
                : name === 'Full Point'
                ? 30
                : name === 'Refill'
                ? 20
                : null,
            tooltip: {
              valueSuffix: '%',
              valueDecimals: 1
            }
          });
        }
      });

      // If we found both the current and previous week readings then display the previous week area series
      if (currentWeekReading && previousWeekReading) {
        let data = [];
        for (let i = 0; i < 10; i++) {
          if (
            previousWeekReading[`depth${i}`] &&
            previousWeekReading[`vsw${i}_perc`] &&
            currentWeekReading[`vsw${i}_perc`]
          ) {
            data.push({
              low: previousWeekReading[`vsw${i}_perc`],
              high: currentWeekReading[`vsw${i}_perc`],
              x: previousWeekReading[`depth${i}`]
            });
          }
        }

        series.push({
          type: 'arearange',
          name: 'Difference',
          lineColor: 'rgb(173, 216, 230)',
          fillColor: 'rgba(173, 216, 230, 0.5)',
          zIndex: 10,
          data: data,
          tooltip: {
            pointFormat: ''
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false
              }
            }
          }
        });
      }

      // Merge the options and update the state
      const newOptions = { ...options };

      // Update the bottom of root zone plot line
      if (currentWeekReading && currentWeekReading.rz1_bottom !== null) {
        newOptions.xAxis.softMax = currentWeekReading.rz1_bottom;
        newOptions.xAxis.plotLines = [
          {
            value: currentWeekReading.rz1_bottom,
            label: {
              text: 'Bottom of the root zone'
            },
            zIndex: 2
          }
        ];
      } else {
        newOptions.xAxis.softMax = null;
        newOptions.xAxis.plotLines = null;
      }

      // Order the series tool tips
      const newSeries = [];
      const fullPointSeries = series.find((s) => s.name === 'Full Point');
      if (fullPointSeries) newSeries.push(fullPointSeries);
      const currentWeekSeries = series.find((s) => s.name === 'Current Week');
      if (currentWeekSeries) newSeries.push(currentWeekSeries);
      const previousWeekSeries = series.find((s) => s.name === 'Previous Week');
      if (previousWeekSeries) newSeries.push(previousWeekSeries);
      const differenceSeries = series.find((s) => s.name === 'Difference');
      if (differenceSeries) newSeries.push(differenceSeries);
      const refillSeries = series.find((s) => s.name === 'Refill');
      if (refillSeries) newSeries.push(refillSeries);

      // Update the series
      newOptions.series = newSeries;

      // Set the new state
      setOptions(newOptions);
    }
  }, [props, probeReadingsDescending]);

  return <Chart options={options} loading={false} />;
}

