/**
 * Returns the soil moisture percentage value for a site.
 *
 * The calculation finds the percentage of summary.latest_reading_date_rz1
 * within the range of the refill point to the full point.
 *
 * @param {object} summary
 * @returns {(number|null)}
 */
export const CalculateSiteSoilMoisture = (summary) => {
  return summary
    ? (summary.latest_reading_date_rz1 - summary.refill_point) /
        (summary.full_point - summary.refill_point)
    : null;
};

/**
 * Returns a number between 1 and 3
 *  - 1 = Green (Site summary is looking good)
 *  - 2 = Orange (Site summary warning)
 *  - 3 = Red (Site needs action)
 *
 * @param {object} summary
 * @returns {number}
 */
export const CalculateSiteSummaryLevel = (summary) => {
  let outsiteLimits = false;
  let outsiteStrategy = false;
  let aboveLimits = false;

  if (
    summary.latest_reading_date_rz1 > summary.full_point ||
    summary.latest_reading_date_rz1 < summary.refill_point
  ) {
    if (summary.latest_reading_date_rz1 > summary.full_point) {
      aboveLimits = true;
    }
    outsiteLimits = true;
  }

  if (
    summary.latest_reading_date_rz1 > summary.strategy_max ||
    summary.latest_reading_date_rz1 < summary.strategy_min
  )
    outsiteStrategy = true;

  return outsiteStrategy && outsiteLimits
    ? aboveLimits
      ? 4
      : 3
    : !outsiteStrategy && outsiteLimits
    ? 2
    : outsiteStrategy && !outsiteLimits
    ? 2
    : 1;
};

export const SummaryBootstrapColorClass = (siteResults) => {
  const summaryLevel = siteResults.alert_level;
  const fullPoint = siteResults.full_point;
  const latestReading = siteResults.latest_reading.rz1;
  if (summaryLevel === 3 && latestReading > fullPoint) {
    return 'primary';
  }

  return summaryLevel === 3
    ? 'danger'
    : summaryLevel === 2
    ? 'warning'
    : 'success';
};

export const SummaryBootstrapColorClassLevel = (level) => {
  return level === 4
    ? 'primary'
    : level === 3
    ? 'danger'
    : level === 2
    ? 'warning'
    : 'success';
};

export const AlertLevelLabel = (alertLevel) =>
  alertLevel === 4
    ? 'Above Full Point'
    : alertLevel === 3
    ? 'Attention Required'
    : alertLevel === 2
    ? 'Outside Strategy'
    : 'Within Strategy';

export const formatSiteType = (type) => {
  if (type === 'irrimax') {
    return 'IrriMAX Continuous';
  } else if (type === 'terraprobe') {
    return 'Weekly';
  } else {
    return type;
  }
};

export const openIrrimaxWindow = (url) => {
  window.open(url, '_blank', 'width=800,height=600').focus();
};

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQBmtsgm8DGHW36Yj0RR+Ive
l9ZUXAqtFVBOkGMCfY9qhoJCgtIXeorCI2GKRbwXZtRmwWLiUakLQ3t+3ElewhsK
CPSFzAL7kWUkCAlSl71g/BVFdVBsbOerxATbtjnEkcR8BbxFuBe0kVxiEiLEFKxT
debpZMbde453mQArJj21t8Gm3GWJGoZjdHYYGmY/bTx/yBAL+7fvmq22Gr8HkW8E
7y/sWt0NDBbVvgdHNPlgZBaT6lg4TnDMzX15t1KthqXgzVTtTzL6h7BAiJpjF896
/slkFB9ch+76SVsZY5cdl1YmvDPX5zI2U/xqzDv9crOKKLOyvH2gXPs1s7LJDUop
AgMBAAE=
-----END PUBLIC KEY-----`;

async function importPublicKey(pem) {
  // Remove the "-----BEGIN PUBLIC KEY-----" and "-----END PUBLIC KEY-----"
  // from the PEM string
  const pemWithoutHeaders = pem.replace(/-----[A-Z ]+-----/g, '');

  // Convert the PEM string to a Uint8Array
  const pemAsUint8 = new Uint8Array(Buffer.from(pemWithoutHeaders, 'base64'));

  // Import the public key
  const key = await crypto.subtle.importKey(
    'spki', // the format of the key
    pemAsUint8, // the key itself
    {
      name: 'RSA-OAEP', // the algorithm to use
      hash: { name: 'SHA-256' } // the hash algorithm to use
    },
    true, // whether the key is extractable (i.e. can be used in exportKey)
    ['encrypt'] // the usages for the key
  );

  // Convert the key to a JWK format
  const jwk = await crypto.subtle.exportKey('jwk', key);

  return jwk;
}

async function encryptWithPublicKey(publicKey, plaintext) {
  // Import the public key
  const key = await crypto.subtle.importKey(
    'jwk', // the format of the key
    publicKey, // the key itself
    {
      name: 'RSA-OAEP', // the algorithm to use
      hash: { name: 'SHA-256' } // the hash algorithm to use
    },
    false, // whether the key is extractable (i.e. can be used in exportKey)
    ['encrypt'] // the usages for the key
  );

  // Encrypt the plaintext
  const ciphertext = await crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP'
    },
    key,
    new TextEncoder().encode(plaintext)
  );

  // Return the ciphertext as an ArrayBuffer
  return ciphertext;
}

export const irrimaxURL = (site) => {
  // NOTE: Irrimax login feature currently disabled.
  // const jwkPublicKey = await importPublicKey(publicKey);
  // const plaintext = '1673477089|175f0f4b-cede-4e84-8e2b-37beb797e608';
  // const ciphertext = await encryptWithPublicKey(jwkPublicKey, plaintext);
  // const decodedText = new TextDecoder().decode(ciphertext);

  const siteName = site.name;
  const baseURL = `https://www.irrimaxlive.com/#/:loggers/`;
  // Note: The site name should be the same as the logger ID.
  //const url = `${baseURL}${siteName}?fruition=${decodedText}`;
  const url = `${baseURL}${siteName}`;
  return url;
};

