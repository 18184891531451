import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'react-bootstrap';
import LoadingOverlay from '../LoadingOverlay';
import { validateEmailAddress } from '../helpers/Validation';

export const UserDetailsFormValidator = (values, props) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!validateEmailAddress(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

const UserDetailsForm = ({
  canEditEmail,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
  setFieldValue,
  isSubmitting
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId='name'>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type='string'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          placeholder='Name'
          isInvalid={errors.name}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.name}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='email'>
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          type='email'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          placeholder='Email Address'
          isInvalid={errors.email}
          disabled={!canEditEmail}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='send_reports'>
        <Form.Check
          type='checkbox'
          onChange={() => setFieldValue('send_reports', !values.send_reports)}
          defaultChecked={values.send_reports}
          label='Send Reports'
        />
      </Form.Group>
      {isSubmitting ? <LoadingOverlay /> : null}
    </Form>
  );
};

export default observer(UserDetailsForm);
