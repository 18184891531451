import { faArrowRight, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Container,
  Row,
  Spinner
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import IrrigationLegend from './IrrigationLegend';
import { withIrrigationSummaries } from './IrrigationProviders';
import {
  CalculateSiteSummaryLevel,
  SummaryBootstrapColorClassLevel
} from './helpers';
import SummaryMap from './maps/SummaryMap';

// Uses the withIrrigationSummaries HOC so you don't
// need to supply the propertySummaries
function IrrigationDashboard(props) {
  /**
   * Takes a propertySummary and if there are valid results the maximum level is found
   * @param {Object} propertySummary Individual object from prop array
   * @returns {Object} Used to decide the colour of the row, the max level is used as it represents the current highest risk.
   */
  const propertySummaries = useMemo(
    () =>
      props.propertySummaries
        .filter(
          (propertySummary) =>
            propertySummary.results && propertySummary.results.sites.length > 0
        )
        .map((propertySummary) => {
          var minMonitoringDate = null;
          var maxMonitoringDate = null;
          var maxLevel = null;

          propertySummary.results.sites.forEach((result) => {
            const level = CalculateSiteSummaryLevel(result);
            if (maxLevel === null) maxLevel = level;
            else if (level > maxLevel) maxLevel = level;

            // Find the range of monitoring dates
            const latestReadingDate = moment(result.latest_reading_date);
            if (minMonitoringDate === null && maxMonitoringDate === null) {
              minMonitoringDate = latestReadingDate;
              maxMonitoringDate = latestReadingDate;
            } else if (latestReadingDate < minMonitoringDate) {
              minMonitoringDate = latestReadingDate;
            } else if (latestReadingDate > maxMonitoringDate) {
              maxMonitoringDate = latestReadingDate;
            }
          });

          return {
            ...propertySummary,
            minMonitoringDate: minMonitoringDate,
            maxMonitoringDate: maxMonitoringDate,
            maxLevel: maxLevel
          };
        }),
    [props.propertySummaries]
  );
  const userStore = useContext(storesContext);
  const history = useHistory();

  /** Set by clicking and local storage, controls whether drop down table is displayed */
  const [showResults, setShowResults] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('showDashboardResults'))
      setShowResults(
        localStorage.getItem('showDashboardResults') === 'true' ? true : false
      );
  }, []);

  const handleClick = () => {
    localStorage.setItem('showDashboardResults', !showResults);
    setShowResults(
      localStorage.getItem('showDashboardResults') === 'true' ? true : false
    );
  };

  return props.loading ? (
    <div className='d-flex justify-content-center pt-5'>
      <Spinner animation='border' variant='primary' />
    </div>
  ) : (
    <Container className='p-0'>
      <SummaryMap
        propertySummaries={propertySummaries}
        height={window.innerHeight < 500 ? 250 : window.innerHeight / 2}
        width='100%'
      />
      <IrrigationLegend
        className='mt-2 text-center'
        propertySummaries={propertySummaries}
      />

      <Card className='mt-2'>
        <Card.Header onClick={handleClick} className='text-center'>
          {showResults ? (
            <Fragment>
              Irrigation Monitoring
              <br />
            </Fragment>
          ) : null}
          <small>
            <FontAwesomeIcon icon={faListAlt} /> {showResults ? 'Hide' : 'Show'}{' '}
            Results
          </small>
        </Card.Header>
        <Collapse in={showResults}>
          <div>
            {propertySummaries.map((propertySummary) => {
              return propertySummary.maxLevel &&
                propertySummary.minMonitoringDate &&
                propertySummary.maxMonitoringDate ? (
                <Row
                  key={propertySummary.property.id}
                  className='my-2 mx-1 p-0'
                  noGutters
                >
                  <Col xs={12} className='bg-light'>
                    <Row noGutters>
                      <Col
                        xs={1}
                        className={`bg-${SummaryBootstrapColorClassLevel(
                          propertySummary.maxLevel
                        )}`}
                      />
                      <Col xs={8} className='py-2 pl-3 pr-0'>
                        <b>
                          {propertySummary.property.identifier +
                            ' ' +
                            propertySummary.property.name}
                        </b>
                        <br />{' '}
                        {propertySummary.property.irrigation_monitoring_sites.map(
                          (irrigationMonitoringSite, i) =>
                            irrigationMonitoringSite.site_name +
                            (i <
                            propertySummary.property.irrigation_monitoring_sites
                              .length -
                              1
                              ? ', '
                              : '')
                        )}
                        <br /> Monitored:{' '}
                        {propertySummary.minMonitoringDate.format(
                          'Do MMM YYYY'
                        )}
                        {!propertySummary.minMonitoringDate.isSame(
                          propertySummary.maxMonitoringDate
                        )
                          ? ` - ${propertySummary.maxMonitoringDate.format(
                              'Do MMM YYYY'
                            )}`
                          : ''}
                      </Col>
                      <Col
                        xs={3}
                        className='d-flex align-items-center justify-content-end pr-3'
                      >
                        <Button
                          variant='outline-primary'
                          className='float-right'
                          onClick={() => {
                            history.push('/soil-water/monitoring');
                            userStore.propertiesStore.selectProperty(
                              propertySummary.property.id,
                              userStore.selectedDatabase,
                              userStore.bearerToken,
                              userStore.refreshCallback
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null;
            })}
          </div>
        </Collapse>
      </Card>
    </Container>
  );
}

IrrigationDashboard.propTypes = {
  propertySummaries: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

export default withIrrigationSummaries(observer(IrrigationDashboard));

