import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

function LastWeekRain(props) {
  // Find all probe readings
  const probeReadings = props.readingData.filter(
    (reading) => reading.type === 'Probe'
  );

  // If there are no probe readings then we can't render a table
  if (probeReadings.length < 1) return null;

  const lastWeekReading = probeReadings[0];

  return (
    <Table bordered responsive className='mb-0'>
      <tbody>
        <tr>
          <td>Rain</td>
          <td>
            {lastWeekReading.rain !== null
              ? parseFloat(lastWeekReading.rain.toFixed(1)) + ' mm'
              : 'N/A'}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

LastWeekRain.propTypes = {
  readingData: PropTypes.array.isRequired
};

export default LastWeekRain;
