import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from 'react-bootstrap';

function RecommendationTable(props) {
  // Find all probe readings
  const probeReadings = props.readingData.filter(
    (reading) => reading.type === 'Probe'
  );

  // If there are no probe readings then we can't render a table
  if (probeReadings.length < 1) return null;

  // Render the recommendation table for the latest probe reading
  const latestWeekReading = probeReadings[0];
  const startDay = moment(latestWeekReading.date, 'DD-MM-YYYY').day();

  const days = [];
  for (let i = startDay + 1; i < 7; i++) {
    days.push(i);
  }
  for (let i = 0; i < startDay + 1; i++) {
    days.push(i);
  }

  /**
   * @returns string
   */
  const terraprobeDayVar = (day) => {
    switch (day) {
      default:
        return '';
      case 0:
        return 'rec_sun';
      case 1:
        return 'rec_mon';
      case 2:
        return 'rec_tue';
      case 3:
        return 'rec_wed';
      case 4:
        return 'rec_thu';
      case 5:
        return 'rec_fri';
      case 6:
        return 'rec_sat';
    }
  };

  return (
    <Table responsive size='sm'>
      <thead>
        <tr>
          <th></th>
          {days.map((day) => (
            <th key={day}>{moment().day(day).format('ddd')}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>mm</td>
          {days.map((day) => (
            <td key={day}>
              {Math.round(
                latestWeekReading[terraprobeDayVar(day)] *
                  props.site.application_rate
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td>Hours</td>
          {days.map((day) => (
            <td key={day}>{latestWeekReading[terraprobeDayVar(day)]}</td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
}

RecommendationTable.propTypes = {
  readingData: PropTypes.array.isRequired,
  site: PropTypes.object.isRequired
};

export default RecommendationTable;
