import { GlobalPropertiesSelect } from '@hortplus/properties-react';
import isMobile from 'ismobilejs';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import URI from 'urijs';
import { storesContext } from '../../stores/storesContext';
import {
  GreenAtlasUrlButtons,
  useGreenAtlasUrls
} from '../crop_scanning/GreenAtlasUrls';
import PageTitle from '../helpers/PageTitle';
import { errorToast, warningToast } from '../helpers/toasts/ToastUtils';
import BlocksMap from './BlocksMap';
import ReportTables from './ReportTables';
import ReportTypeSelect, {
  defaultReportTypeOption,
  getReportTypeName
} from './ReportTypeSelect';

const BlockScanningReportsPage = () => {
  const userStore = useContext(storesContext);
  const propertiesStore = userStore.propertiesStore;
  const [reportType, setReportType] = useState(defaultReportTypeOption);
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState('');
  const reportsState = useState([]);
  const [loadingClusters, setLoadingClusters] = useState(false);

  const [reports] = reportsState;

  const selectedDatabase = userStore.selectedDatabase;
  const bearerToken = userStore.bearerToken;

  const { gaUrls, loadingGaUrls } = useGreenAtlasUrls(
    propertiesStore.selectedProperty?.identifier,
    selectedDatabase,
    bearerToken
  );

  // Create an object that maps block names to an array of
  // part block names sorted alphabetically.
  const blocksPartBlocksNames = useMemo(() => {
    const blocksPartBlocksNames = {};
    blocks.forEach((block) => {
      const partBlocks = [];
      const primaryBlockid = block.primary_block_id;
      const blockFeature = JSON.parse(block.primary_ga_block.geojson_feature);
      block.ga_blocks.forEach((partBlock) => {
        const partBlockId = partBlock.id;
        if (primaryBlockid === partBlockId) return;
        const partBlockFeature = JSON.parse(partBlock.geojson_feature);
        partBlocks.push(partBlockFeature.properties.details?.block || '');
      });
      blocksPartBlocksNames[blockFeature.properties.details?.block || ''] =
        partBlocks;
    });
    // Sort object by key values.
    const sortedObject = Object.fromEntries(
      Object.entries(blocksPartBlocksNames).sort(([a], [b]) =>
        a.localeCompare(b)
      )
    );
    return sortedObject;
  }, [blocks]);

  // Primary block names.
  const blockNames = useMemo(
    () => Object.keys(blocksPartBlocksNames),
    [blocksPartBlocksNames]
  );

  // Select the first block initially.
  useEffect(() => {
    if (blockNames.length > 0) {
      setSelectedBlock(blockNames[0]);
    }
  }, [blockNames]);

  // Fetch the selected propertie's block clusters.
  useEffect(() => {
    let mounted = true;
    const fetchBlockClusters = async (propertyId) => {
      if (mounted) setLoadingClusters(true);
      const url = new URI(
        `${process.env.REACT_APP_PROPERTIES_API_URL}api/fruition/blocks/property/${propertyId}`
      );
      const response = await fetch(url.valueOf(), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          SeasonalDatabase: selectedDatabase
        }
      });
      if (!response.ok) {
        errorToast('Error fetching blocks', 'blocks-fetch-error');
        return;
      }
      const data = await response.json();
      if (mounted) {
        setBlocks(data);
        if (data.length === 0) {
          warningToast('Selected property has no blocks.', 'no-blocks-warning');
        }
        setLoadingClusters(false);
      }
    };
    const selectedProperty = propertiesStore.selectedProperty;
    if (!selectedProperty) return;
    fetchBlockClusters(selectedProperty.id);
    return () => {
      mounted = false;
    };
  }, [propertiesStore.selectedProperty, bearerToken, selectedDatabase]);

  const season = useMemo(
    () =>
      userStore.selectedDatabase ? userStore.selectedDatabase.substr(9) : null,
    [userStore.selectedDatabase]
  );
  // Season start and stop copied from the Properties API.
  const seasonStart = useMemo(
    () => moment({ year: season - 1, month: 6, day: 1 }),
    [season]
  );
  const seasonStop = useMemo(
    () => moment({ year: season, month: 5, day: 31 }),
    [season]
  );
  // Filter reports by report type.
  const reportTypeReports = useMemo(
    () =>
      reports.filter(
        (report) =>
          reportType.value === 'all' ||
          report.stage === getReportTypeName(reportType)
      ),
    [reportType, reports]
  );

  /**
   * Filters reports by selected block and it's part blocks.
   * @param {string} blockName The selected block name.
   * @returns {Array} The selected block reports.
   */
  const selectedBlockReports = useCallback(
    (blockName) =>
      reportTypeReports.filter((report) => {
        if (!(blockName in blocksPartBlocksNames)) return false;
        const partBlocks = blocksPartBlocksNames[blockName];
        const isSelected = report.blockCode === blockName;
        const isPartBlock = partBlocks.includes(report.blockCode);
        // Filter by season.
        const date = moment(report.dt);
        if (date.isBefore(seasonStart) || date.isAfter(seasonStop))
          return false;
        return isSelected || isPartBlock;
      }),
    [blocksPartBlocksNames, reportTypeReports, seasonStart, seasonStop]
  );

  // Primary block GeoJson features.
  const blockFeatures = useMemo(
    () =>
      blocks.map((block) => {
        const feature = JSON.parse(block.primary_ga_block.geojson_feature);
        feature.properties.block_name = feature.properties.details?.block || '';
        feature.properties.has_reports =
          selectedBlockReports(feature.properties.block_name).length > 0;
        return feature;
      }),
    [blocks, selectedBlockReports]
  );

  return (
    <div id='page-container' className='mb-4'>
      <Row>
        <Col>
          <PageTitle title={'Block Scanning Reports'} />
        </Col>
      </Row>
      <Row>
        <Col>
          <GreenAtlasUrlButtons urls={gaUrls} isLoading={loadingGaUrls} />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          {isMobile(window.navigator).any ? (
            <Row>
              <Col
                xs={3}
                className='d-flex align-items-center justify-content-end pr-0 font-weight-bold'
              >
                Property:
              </Col>
              <Col xs={9}>
                <GlobalPropertiesSelect
                  selectedDatabase={selectedDatabase}
                  bearerToken={bearerToken}
                  refreshBearerToken={userStore.refreshBearerToken}
                  propertiesStore={propertiesStore}
                />
              </Col>
              <Col
                xs={0}
                className='d-flex align-items-center justify-content-end pl-0 font-weight-bold'
                style={{ visibility: 'hidden' }}
              >
                Property:
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col xs={0} lg={2} />
            <Col>
              <Row>
                <Col
                  xs={3}
                  sm={2}
                  className='d-flex align-items-center justify-content-end pr-0 font-weight-bold'
                >
                  Stage:
                </Col>
                <Col xs={9} sm={8}>
                  <ReportTypeSelect
                    reportType={reportType}
                    onChange={setReportType}
                  />
                </Col>
                <Col
                  xs={0}
                  sm={2}
                  className='d-flex align-items-center justify-content-end pl-0 font-weight-bold'
                  style={{ visibility: 'hidden' }}
                >
                  Stage:
                </Col>
              </Row>
            </Col>
            <Col xs={0} lg={2} />
          </Row>
        </Col>
      </Row>

      <Row className='mt-4' style={{ height: '400px' }}>
        <Col>
          <BlocksMap
            features={blockFeatures}
            selectedBlock={selectedBlock}
            onClick={(feature) => {
              if (!feature?.properties?.details) return;
              const details = JSON.parse(feature.properties.details);
              if (!details?.block) return;
              setSelectedBlock(details.block);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className='text-center'>
          {loadingClusters ? (
            <Spinner className='mt-2' animation='border' variant='success' />
          ) : (
            Object.keys(blocksPartBlocksNames).map((name, i) => {
              const variant =
                name === selectedBlock
                  ? selectedBlockReports(name).length > 0
                    ? 'success'
                    : 'secondary'
                  : selectedBlockReports(name).length > 0
                  ? 'outline-success'
                  : 'outline-secondary';
              return (
                <Button
                  key={name + i}
                  className='mt-2 mr-1 px-3'
                  variant={variant}
                  onClick={() => {
                    setSelectedBlock(name);
                  }}
                >
                  {name}
                </Button>
              );
            })
          )}
        </Col>
      </Row>
      {propertiesStore.selectedProperty && reportType ? (
        <Row className='mt-4 text-center'>
          <Col>
            <ReportTables
              blocks={blocksPartBlocksNames}
              selectedBlock={selectedBlock}
              reportsState={reportsState}
              reportTypeReports={reportTypeReports}
              selectedBlockReports={selectedBlockReports(selectedBlock)}
              season={season}
              seasonStart={seasonStart}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default observer(BlockScanningReportsPage);

