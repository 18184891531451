import { GlobalPropertiesSelect } from '@hortplus/properties-react';
import isMobile from 'ismobilejs';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { storesContext } from '../../stores/storesContext';
import { pdfTypes, reportTypes, scanTypes, stages } from './CropScanningUtils';

const AdvancedFilterModal = ({ formik, blocks, loadingBlocks }) => {
  const userStore = useContext(storesContext);
  const [show, setShow] = useState(false);

  // Returns whether at least one filter is selected.
  const hasFilter = useCallback(() => {
    const values = formik.values;
    let hasFilter = false;
    Object.keys(values).forEach((v) => {
      if (values[v]) hasFilter = true;
    });
    return hasFilter;
  }, [formik.values]);

  return (
    <>
      <Button
        variant={hasFilter() ? 'primary' : 'outline-primary'}
        onClick={() => setShow(true)}
      >
        Advanced Filter
      </Button>
      <Modal show={show} onHide={() => setShow(false)} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>Advanced Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            {isMobile(window.navigator).any ? (
              <Form.Group as={Row} controlId='block_code'>
                <Form.Label column sm={3}>
                  Property
                </Form.Label>
                <Col sm={10} md={8} lg={6}>
                  <GlobalPropertiesSelect
                    selectedDatabase={userStore.selectedDatabase}
                    bearerToken={userStore.bearerToken}
                    refreshBearerToken={userStore.refreshCallback}
                    propertiesStore={userStore.propertiesStore}
                  />
                </Col>
              </Form.Group>
            ) : null}
            <>
              <Form.Group as={Row} controlId='block_code'>
                <Form.Label column sm={3}>
                  Block Code
                </Form.Label>
                <Col sm={9}>
                  <Select
                    isClearable
                    value={formik.values.block_code}
                    onChange={(option) =>
                      formik.setFieldValue('block_code', option)
                    }
                    options={blocks.map((block) => {
                      return {
                        value: block,
                        label: block === '$$$$' ? 'Entire Orchard' : block
                      };
                    })}
                    isLoading={loadingBlocks}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='scan_type'>
                <Form.Label column sm={3}>
                  Scan Type
                </Form.Label>
                <Col sm={9}>
                  <Select
                    isClearable
                    value={formik.values.scan_type}
                    onChange={(option) =>
                      formik.setFieldValue('scan_type', option)
                    }
                    options={scanTypes}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='stage'>
                <Form.Label column sm={3}>
                  Stage
                </Form.Label>
                <Col sm={9}>
                  <Select
                    isClearable
                    value={formik.values.stage}
                    onChange={(option) =>
                      formik.setFieldValue('stage', option ? option : '')
                    }
                    options={stages}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='report_type'>
                <Form.Label column sm={3}>
                  Report Type
                </Form.Label>
                <Col sm={9}>
                  <Select
                    isClearable
                    value={formik.values.report_type}
                    onChange={(option) =>
                      formik.setFieldValue('report_type', option ? option : '')
                    }
                    options={reportTypes}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId='pdf_type'>
                <Form.Label column sm={3}>
                  PDF Type
                </Form.Label>
                <Col sm={9}>
                  <Select
                    isClearable
                    value={formik.values.pdf_type}
                    onChange={(option) =>
                      formik.setFieldValue('pdf_type', option ? option : '')
                    }
                    options={pdfTypes}
                  />
                </Col>
              </Form.Group>
              <Row>
                <Col className='text-center'>
                  <Button
                    type='submit'
                    variant='outline-primary'
                    className='mx-1'
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default observer(AdvancedFilterModal);

