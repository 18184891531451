import { faCog, faHome, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { storesContext } from '../../stores/storesContext';
import Divider from './Divider';
import './header.scss';

const MobileHeader = ({ hideMobileMenu }) => {
  const userStore = useContext(storesContext);
  const [open, setOpen] = useState(false);

  return (
    <header
      id='js-header'
      className={`header-mobile header ${
        !userStore.loggedIn || userStore.currentSeasonIsSelected
          ? 'bg-fruition'
          : 'bg-secondary'
      }`}
      style={{ height: 60 }}
    >
      <Container fluid className='header_inner'>
        <Row className='header_logo ml-1'>
          <Col className='p-0'>
            <Link className='d-block' to='/'>
              <img
                className='w-100'
                src='https://fruition-metwatch-assets.s3-ap-southeast-2.amazonaws.com/fruition-logo.png'
                alt='Fruition Portal'
              />
            </Link>
          </Col>
        </Row>
        <Row className='m-0'>
          <Col className='p-0'>
            {!hideMobileMenu && userStore.loggedIn ? (
              <>
                <Button
                  variant='link'
                  className='menu-toggle js-menu-toggle'
                  onClick={() => setOpen(true)}
                >
                  <span className='sr-only'>Open mobile menu</span>
                  <span aria-hidden='true'>
                    <svg
                      width='34'
                      height='25'
                      viewBox='0 0 34 25'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M0 1C0 0.447754 0.447754 0 1 0H33C33.5522 0 34 0.447754 34 1V4C34 4.55225 33.5522 5 33 5H1C0.447754 5 0 4.55225 0 4V1ZM0 11C0 10.4478 0.447754 10 1 10H33C33.5522 10 34 10.4478 34 11V14C34 14.5522 33.5522 15 33 15H1C0.447754 15 0 14.5522 0 14V11ZM1 20C0.447754 20 0 20.4478 0 21V24C0 24.5522 0.447754 25 1 25H33C33.5522 25 34 24.5522 34 24V21C34 20.4478 33.5522 20 33 20H1Z'
                        fill='white'
                      />
                    </svg>
                  </span>
                </Button>
                <nav
                  className='mobile-menu'
                  role='menuitem'
                  aria-expanded={open}
                >
                  <Row className='d-flex flex-column justify-content-between mx-0'>
                    <Col className='p-0 m-0'>
                      <div className='mobile-menu-header'>
                        <Link
                          className='ml-2'
                          to='/'
                          onClick={() => setOpen(false)}
                        >
                          <FontAwesomeIcon icon={faHome} /> Dashboard
                        </Link>
                        <Button
                          variant='link'
                          className='menu-close js-menu-toggle mr-2'
                          onClick={() => setOpen(false)}
                        >
                          <span className='sr-only'>Close mobile menu</span>
                          <span>
                            <svg
                              className='mb-2'
                              width='26'
                              height='26'
                              viewBox='0 0 26 26'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M3.48746 -9.84054e-07L13.0142 9.53333L22.5125 0.0577776L26 3.49556L16.5016 13L26 22.5044L22.5125 25.9422L13.0142 16.4667L3.48746 26L2.25308e-07 22.5622L9.55507 13L1.06126e-06 3.43778L3.48746 -9.84054e-07Z'
                                fill='white'
                              />
                            </svg>
                          </span>
                        </Button>
                      </div>

                      <Fragment>
                        {userStore.isAdmin && (
                          <ul className='mobile-menu-group'>
                            <li className='menu-item-title'>Management</li>
                            <li>
                              <Link
                                to='/management/users'
                                onClick={() => setOpen(false)}
                              >
                                Users
                              </Link>
                            </li>
                            <li>
                              <Link
                                to='/management/properties'
                                onClick={() => setOpen(false)}
                              >
                                Properties
                              </Link>
                            </li>
                            <li>
                              <Link
                                to='/management/property-groups'
                                onClick={() => setOpen(false)}
                              >
                                Property Groups
                              </Link>
                            </li>
                          </ul>
                        )}

                        <ul className='mobile-menu-group'>
                          <li className='menu-item-title'>Soil & Water</li>
                          <li>
                            <a
                              href='https://www.fruitionhort.com/soil-water'
                              target='new'
                            >
                              Information
                            </a>
                          </li>
                          <li>
                            <Link
                              to='/soil-water/monitoring'
                              onClick={() => setOpen(false)}
                            >
                              Monitoring
                            </Link>
                          </li>
                          <li>
                            <Link
                              to='/soil-water/seasonal-summary-report'
                              onClick={() => setOpen(false)}
                            >
                              Seasonal Summary Report
                            </Link>
                          </li>
                          {userStore.isAdmin && (
                            <>
                              <Divider />
                              <li>
                                <a
                                  href={`${process.env.REACT_APP_TERRAPROBE_API_URL}`}
                                  target='new'
                                >
                                  Terraprobe
                                </a>
                              </li>
                            </>
                          )}
                        </ul>

                        <ul className='mobile-menu-group'>
                          <li className='menu-item-title'>Pomology</li>
                          <li>
                            <a
                              href='https://www.fruitionhort.com/pomology'
                              target='new'
                            >
                              Information
                            </a>
                          </li>
                        </ul>
                        <ul className='mobile-menu-group'>
                          <li className='menu-item-title'>Precision Hort</li>
                          <li>
                            <a
                              href='https://www.fruitionhort.com/precision-hort'
                              target='new'
                            >
                              Information
                            </a>
                          </li>
                          <li>
                            <Link
                              to='/precision-hort/block-scanning-reports'
                              onClick={() => setOpen(false)}
                            >
                              Block Scanning Reports
                            </Link>
                          </li>
                          {userStore.isAdmin && (
                            <>
                              <Divider />
                              <li>
                                <Link
                                  to='/precision-hort/block-scanning/upload'
                                  onClick={() => setOpen(false)}
                                >
                                  Block Scanning Upload
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to='/precision-hort/ga-viewer-upload'
                                  onClick={() => setOpen(false)}
                                >
                                  Green Atlas Bundle Upload
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                        <ul className='mobile-menu-group'>
                          <li className='menu-item-title'>Pest & Disease</li>
                          <li>
                            <a
                              href='https://www.fruitionhort.com/pest-disease'
                              target='new'
                            >
                              Information
                            </a>
                          </li>
                          <li>
                            <Link
                              to='/pest-disease/trapping-overview'
                              onClick={() => setOpen(false)}
                            >
                              Trapping Overview
                            </Link>
                          </li>
                          <li>
                            <Link
                              to='/pest-disease/hot-trap-map'
                              onClick={() => setOpen(false)}
                            >
                              Hot Trap Map
                            </Link>
                          </li>
                          <li>
                            <Link
                              to='/pest-disease/seasonal-trapping-report'
                              onClick={() => setOpen(false)}
                            >
                              Season Overview
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              to='/pest-disease/field-reports'
                              onClick={() => setOpen(false)}
                            >
                              Field Reports
                            </Link>
                          </li> */}
                          {userStore.isAdmin && (
                            <>
                              <Divider />
                              <li>
                                <Link
                                  to='/pest-disease/trapping-data-entry'
                                  onClick={() => setOpen(false)}
                                >
                                  Trapping Data Entry
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to='/pest-disease/trapping-runs'
                                  onClick={() => setOpen(false)}
                                >
                                  Trapping Runs
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to='/pest-disease/weekly-trapping-report'
                                  onClick={() => setOpen(false)}
                                >
                                  Weekly Trapping Report
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to='/pest-disease/missing-trapping-report'
                                  onClick={() => setOpen(false)}
                                >
                                  Missing Trapping Report
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </Fragment>
                    </Col>
                  </Row>
                  <Row className='d-flex flex-column justify-content-between mx-0'>
                    <Col className='mobile-menu-tools p-3'>
                      <Link
                        className='text-center p-2'
                        to='/account#mobile-menu-toggle'
                        onClick={() => setOpen(false)}
                      >
                        <FontAwesomeIcon icon={faCog} />
                        <br />
                        Account
                      </Link>
                      <Button
                        variant='link'
                        className='text-center text-light'
                        onClick={() => {
                          userStore.logout();
                          setOpen(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        <br />
                        Logout
                      </Button>
                    </Col>
                  </Row>
                </nav>
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default observer(MobileHeader);

