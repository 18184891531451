import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, FormControl } from 'react-bootstrap';

function TablePagination({
  // Variables
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,

  // Methods
  gotoPage,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  setPageSize
}) {
  return (
    <div className='d-flex align-items-center justify-content-between my-3'>
      <div>
        <Button
          variant='link'
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>{' '}
        <Button
          variant='link'
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>{' '}
        <Button
          variant='link'
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>{' '}
        <Button
          variant='link'
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Button>
      </div>
      {pageOptions.length > 0 ? (
        <div>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
        </div>
      ) : null}
      <div>
        <FormControl
          disabled={pageCount === 0}
          as='select'
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </FormControl>
      </div>
    </div>
  );
}

export default TablePagination;

