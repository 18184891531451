import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';

const IrrigationLegend = ({ propertySummaries, ...otherProps }) => {
  const [success, setSuccess] = useState();
  const [warning, setWarning] = useState();
  const [danger, setDanger] = useState();
  const [secondary, setSecondary] = useState();
  const [primary, setPrimary] = useState();

  useEffect(() => {
    if (propertySummaries) {
      let successCount = 0;
      let warningCount = 0;
      let dangerCount = 0;
      let secondaryCount = 0;
      let primaryCount = 0;
      propertySummaries.forEach((propertySummary) => {
        switch (propertySummary.maxLevel) {
          case 1:
            successCount += 1;
            break;
          case 2:
            warningCount += 1;
            break;
          case 3:
            dangerCount += 1;
            break;
          case 4:
            primaryCount += 1;
            break;
          default:
            secondaryCount += 1;
            break;
        }
      });

      setSuccess(successCount);
      setWarning(warningCount);
      setDanger(dangerCount);
      setSecondary(secondaryCount);
      setPrimary(primaryCount);
    }
  }, [propertySummaries]);

  return (
    <div {...otherProps}>
      <Badge pill variant='success' className='mx-1'>
        Within Strategy {success ? <>({success})</> : ''}
      </Badge>
      <Badge pill variant='warning' className='mx-1'>
        Outside Strategy {warning ? <>({warning})</> : ''}
      </Badge>
      <Badge pill variant='danger' className='mx-1'>
        Attention Required {danger ? <>({danger})</> : ''}
      </Badge>
      <Badge pill variant='primary' className='mx-1'>
        Above Full Point {primary ? <>({primary})</> : ''}
      </Badge>
      <Badge pill variant='secondary' className='mx-1'>
        No Data {secondary ? <>({secondary})</> : ''}
      </Badge>
      <Badge pill variant='light' className='mx-1'>
        <img
          className='mr-1'
          src='https://fruition-metwatch-assets.s3-ap-southeast-2.amazonaws.com/sentek-logo-16.png'
          alt='sentek-logo'
        />{' '}
        IrriMAX Continuous
      </Badge>
    </div>
  );
};

export default IrrigationLegend;

